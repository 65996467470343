import { toast, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Subject } from "api/resources/global/types"
import clsx from "clsx"
import { getNavigationPortal } from "features/SignupOnboarding/Navigation/utils"
import { FormPageProps } from "features/SignupOnboarding/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { getAuthInfo } from "utils/helpers"

import AdditionIcon from "../../assets/subjectIcons/add_option_icon.svg"
import BiologyIcon from "../../assets/subjectIcons/biology_icon.svg"
import BusinessStudiesIcon from "../../assets/subjectIcons/business_studies_icon.svg"
import ChemistryIcon from "../../assets/subjectIcons/chemistry_icon.svg"
import EconomicsIcon from "../../assets/subjectIcons/economics_icon.svg"
import EnglishIcon from "../../assets/subjectIcons/english_icon.svg"
import MathematicsIcon from "../../assets/subjectIcons/mathematics_icon.svg"
import PhysicsIcon from "../../assets/subjectIcons/physics_icon.svg"
import ScienceIcon from "../../assets/subjectIcons/science_icon.svg"
import SocialStudiesIcon from "../../assets/subjectIcons/social_studies_icon.svg"
import CreatableChip from "./CreateableChip"

const defaultIcon = SocialStudiesIcon

const getIconForSubject = (subject: string) => {
  switch (subject.toLowerCase()) {
    case "mathematics":
      return MathematicsIcon
    case "science":
      return ScienceIcon
    case "physics":
      return PhysicsIcon
    case "chemistry":
      return ChemistryIcon
    case "biology":
      return BiologyIcon
    case "social studies":
      return SocialStudiesIcon
    case "economics":
      return EconomicsIcon
    case "business studies":
      return BusinessStudiesIcon
    case "english":
      return EnglishIcon
    default:
      return null
  }
}

const SubjectTheyTeach = ({
  data,
  updateData,
  onNext,
  ...props
}: FormPageProps) => {
  const isPrimaryMode = data.hasExperienceInTeaching || data.isTeachingInSchool

  const form = useForm<{ subjects: Subject[] }>({
    defaultValues: {
      subjects: isPrimaryMode ? data.subjects.slice(0, 1) : data.subjects,
    },
  })

  const [subjectOptions, setSubjectOptions] = useState<Subject[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const subjects = await api.global.listSubjects({
          params: { page: "-1" },
        })

        const options = subjects.filter(subject =>
          getIconForSubject(subject.name.toLowerCase())
        )
        setSubjectOptions(options)

        const selectedSubjects = form.getValues("subjects")
        const additionalOptions = selectedSubjects.filter(
          subject =>
            !options.find(
              x => x.name.toLowerCase() === subject.name.toLowerCase()
            )
        )
        setSubjectOptions(prev => [...prev, ...additionalOptions])
      } catch (e) {
        console.log("Error loading subjects list")
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selection = form.watch("subjects")

  const handleSubjectChipClick = async (subject: Subject) => {
    let newSelection: Subject[] = [subject]

    if (!isPrimaryMode) {
      newSelection = selection.find(x => x.uuid === subject.uuid)
        ? selection.filter(x => x.uuid !== subject.uuid)
        : [...selection, subject]
    }

    form.setValue("subjects", newSelection)
    updateData({ subjects: newSelection })
  }

  const handleAddNewSubject = async (name: string) => {
    const existingSubject = subjectOptions.find(
      subject => name.toLowerCase() === subject.name.toLowerCase()
    )

    if (existingSubject) {
      handleSubjectChipClick(existingSubject)
      return
    }

    try {
      const authInfo = getAuthInfo()

      const result = await api.profile.subjects.createNew({
        data: {
          new: [
            {
              subject: name,
              user_uuid: authInfo?.user.uuid,
            },
          ],
        },
      })

      if (result.length > 0) {
        const [subject] = result
        handleSubjectChipClick(subject)
        setSubjectOptions(prev => [...prev, subject])
      }
    } catch (error) {
      console.error("Error adding new subject", error)
    }
  }

  const updateBackend = async (subjects: Subject[]) => {
    const promises = subjects.map(subject => {
      return api.profile.subjects.create({
        data: { subjectId: subject.uuid, isPrimary: isPrimaryMode },
      })
    })

    await Promise.allSettled(promises)
  }

  const handleSubmit = async () => {
    if (selection.length === 0) {
      toast.error("Please select a subject")
      return
    }

    await updateBackend(selection)
    await onNext()
  }

  const isDisabled = selection == null

  return (
    <div>
      <Typography variant="title2" color="onSurface.900">
        {isPrimaryMode
          ? "What is your main/primary subject?"
          : "What subjects would you like to teach?"}
      </Typography>

      <Controller
        name="subjects"
        control={form.control}
        render={({ field }) => {
          return (
            <div className="mt-4 flex max-w-3xl flex-wrap gap-1.5">
              {subjectOptions.map((subject, index) => {
                const isSelected = field.value
                  .map(x => x.uuid)
                  .includes(subject.uuid)

                return (
                  <button
                    key={index}
                    onClick={() => {
                      if (isPrimaryMode) {
                        field.onChange([subject])
                      } else {
                        field.value.find(x => x.uuid === subject.uuid)
                          ? field.value.filter(x => x.uuid !== subject.uuid)
                          : [...field.value, subject]
                      }
                      handleSubjectChipClick(subject)
                    }}
                    className={clsx(
                      "flex cursor-pointer items-center gap-1 rounded-xl border-2 py-2 pe-3 ps-2 text-onSurface-600",
                      "ring-interactive-500 hover:bg-onSurface-50 focus:outline-none focus:ring-2 active:bg-onSurface-100",
                      {
                        "border-primary-500 font-bold": isSelected,
                        "border-onSurface-300 bg-transparent": !isSelected,
                      }
                    )}
                  >
                    <img
                      src={getIconForSubject(subject.name) ?? defaultIcon}
                      alt={subject.name}
                      className="size-3"
                    />
                    <div>
                      <Typography variant="subtitle2">
                        {subject.name}
                      </Typography>
                    </div>
                  </button>
                )
              })}

              <CreatableChip
                className="capitalize"
                icon={AdditionIcon}
                key="Add Another Subject"
                selected={false}
                editable
                onBlur={val => handleAddNewSubject(val)}
                onClick={undefined}
              >
                <Typography variant="subtitle2">Other</Typography>
              </CreatableChip>
            </div>
          )
        }}
      />

      {getNavigationPortal({
        disableNext: isDisabled,
        onNext: handleSubmit,
        ...props,
      })}
    </div>
  )
}

export default SubjectTheyTeach
