import {
  Button,
  CreatableSelect,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  InputLabel,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Skill } from "api/resources/profile/types"
import { APIError } from "api/utils"
import ProfileContext from "features/Profile/context"
import { InfoCircle } from "iconoir-react"
import { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"

type Props = {
  onAdd: (data: Skill) => void
  handleBack?: () => void
}

type ExtendedDialogProps = DialogProps & {
  onRequestClose?: () => void
}

const AddDialog = ({
  handleBack,
  onAdd,
  onRequestClose,
}: Props & Pick<ExtendedDialogProps, "open" | "onRequestClose">) => {
  const {
    skills: { data: skills },
  } = useContext(ProfileContext)

  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<{
    skills: Array<{ label: string; value: string; __isNew__?: boolean }>
  }>({
    defaultValues: {
      skills: [],
    },
  })

  const watchSkills = watch("skills")

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async ({ skills }) => {
    if (skills.length >= 20) {
      toast.error("You can add a maximum of 20 skills.")
      return
    }

    for (const skill of skills) {
      const apiData = {
        skill_name: skill.value,
      }

      try {
        const res = await api.profile.skills.create({ data: apiData })
        onAdd(res)
      } catch (e) {
        if (e instanceof APIError) {
          if (typeof e.errors?.fieldErrors?.skill === "string") {
            setError("skills", {
              message: e.errors?.fieldErrors?.skill,
            })
          }
        }
      }
    }

    if (handleBack) handleBack()
  })

  const suggestions = useQuery({
    queryFn: () => api.profile.skills.listSuggestions(),
    queryKey: queries.profile.skillsSuggestions().queryKey,
    select: data =>
      // We don't want to show skills which are already in user's profile
      data.filter(
        suggestion => !skills.map(y => y.skillName).includes(suggestion)
      ),
  })

  const filteredSuggestions = suggestions.data?.filter(
    suggestion => !watchSkills.map(x => x.value).includes(suggestion)
  )

  const handleSkillSuggestionClick = (skill: string) => {
    const values = getValues("skills")
    setValue("skills", [
      ...(values || []),
      { label: skill, value: skill, __isNew__: true },
    ])
  }
  const alreadyAddedSkills = skills?.length || 0

  const hasReachedMaxLimit =
    alreadyAddedSkills >= 20 ||
    (watchSkills.length || 0) + alreadyAddedSkills > 20

  return (
    <>
      <DialogContent>
        <DialogHeader className="px-2.25 py-2 font-semibold">
          <DialogTitle onBack={handleBack} className="text-strong">
            Add New Skill
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="px-3 py-2.5">
          {hasReachedMaxLimit && (
            <div className="mb-2 flex items-center gap-1 rounded-xl border border-critical-200 bg-critical-50 p-2 text-critical-800">
              <InfoCircle className="shrink-0" />
              <Typography variant="smallBody">
                You can only add up to 20 skills in your profile. Remove some
                existing skills from your profile to add more.
              </Typography>
            </div>
          )}
          <form onSubmit={onSubmit} className="flex flex-col">
            <div>
              <Controller
                control={control}
                name="skills"
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <div className="flex items-center justify-between ">
                      <InputLabel label="Skill" required />
                      {watchSkills?.length > 0 && (
                        <Button
                          variant="text"
                          onClick={() => {
                            setValue("skills", [])
                          }}
                        >
                          Clear
                        </Button>
                      )}
                    </div>
                    <CreatableSelect
                      isMulti
                      errors={errors.skills?.message}
                      options={[]}
                      creatableMessage="You can add skills by typing"
                      placeholder="Ex: Rubric Creation"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  </>
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
            </div>

            {filteredSuggestions && filteredSuggestions.length > 0 && (
              <div className="relative my-3 rounded-xl border border-onSurface-200 bg-surface-50 p-2">
                <Typography variant="title4">
                  Suggested skills based on your profile
                </Typography>
                <Typography variant="smallBody" className="mt-0.25">
                  Select them to add them to your profile
                </Typography>

                <div className="mt-2 flex flex-wrap gap-1">
                  {filteredSuggestions.map(skill => (
                    <button
                      className="cursor-pointer rounded-full border border-muted bg-white px-1 py-0.25 text-sm text-onSurface-800"
                      key={skill}
                      onClick={() => {
                        handleSkillSuggestionClick(skill)
                      }}
                    >
                      {skill}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant={"outlined"}
            size="sm"
            className="border-onSurface-500 text-onSurface-500"
            onClick={onRequestClose}
          >
            Close
          </Button>
          <Button
            onClick={onSubmit}
            size="sm"
            disabled={hasReachedMaxLimit || isSubmitting}
            loading={isSubmitting}
            // endAdornment={isSubmitting ? "." : ""}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </>
  )
}

export default AddDialog
