import { IconButton, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { ITOCertificate } from "api/resources/profile/types"
import { ReactComponent as Ito } from "assets/MyCertificates/ItoWhite.svg"
import clsx from "clsx"
import { Download, ShareAndroid } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { downloadFile, getPlatformURL } from "utils/helpers"

import styles from "./ItoCard.module.css"

type Props = {
  data: ITOCertificate
  setCertificateUrl: (value: string) => void
}

const svgProps = { width: 20, height: 20 }

const itoDate = {
  2022: "15th October 2022",
  2023: "7th October 2023",
}

function getCertificateName(slug: string): string {
  switch (slug) {
    case "international-teacher-olympiad-2023-participation":
    case "international-teacher-olympiad-2022-participation":
      return "Certificate of Participation"
    case "international-teacher-olympiad-2022-merit-1-percentile":
    case "international-teacher-olympiad-2023-merit-1-percentile":
      return "Certificate of Merit Top 1 Percentile"
    case "international-teacher-olympiad-2022-merit-10-percentile":
    case "international-teacher-olympiad-2023-merit-10-percentile":
      return "Certificate of Merit Top 10 Percentile"
    case "international-teacher-olympiad-2022-merit-30-percentile":
    case "international-teacher-olympiad-2023-merit-30-percentile":
      return "Certificate of Merit Top 30 Percentile"
    case "international-teacher-olympiad-2022-excellence":
    case "international-teacher-olympiad-2023-excellence":
      return "Certificate of Excellence"
    default:
      throw new Error("Invalid certificate type")
  }
}

const ItoCard = ({ data, setCertificateUrl }: Props) => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const certificateUrl = getPlatformURL(
    "suraasa",
    `/international-teachers-olympiad/verify/${data.serialNumber}`
  )

  const handleShare = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    setCertificateUrl(certificateUrl)
  }

  const handleDownload = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    downloadFile(data.file, `${getCertificateName(data.item.slug)}.pdf`)
  }

  return (
    <button
      onClick={() => {
        window.open(certificateUrl, "_blank")
      }}
      className={clsx(
        "relative flex h-[240px] w-full flex-col justify-between rounded-lg border-[5px] border-surface-500 p-2 shadow-[0_8px_25px_0_rgba(0,0,0,0.15)] sm:h-[278px] sm:px-3 sm:py-4  md:h-[292px]",
        styles.container
      )}
    >
      <div className="z-10">
        <span
          className={clsx("mb-0.5  block text-onSurface-600", styles.dateText)}
        >
          {itoDate[data.item.olympiad.year]}
        </span>
        <span className={clsx(styles.title, "text-onSurface-900")}>
          {getCertificateName(data.item.slug)}
        </span>
      </div>
      {!isXsDown && (
        <div className="z-10 flex">
          <IconButton onClick={handleDownload} color="black" className="me-2">
            <Download {...svgProps} />
          </IconButton>
          <IconButton id="share" onClick={handleShare} color="black">
            <ShareAndroid {...svgProps} />
          </IconButton>
        </div>
      )}
      <Ito className={clsx("absolute bottom-0 right-0", styles.logo)} />
    </button>
  )
}

export default ItoCard
