import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Cloud, GoogleDocs, Link, Xmark } from "iconoir-react"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  previewText: {
    position: "relative",
    borderRadius: "4px",
    background: theme.colors.onSurface[200],
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    "& p": {
      padding: theme.spacing(0, 1),
      width: "100%",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  linkPreviewText: {
    position: "absolute",
    bottom: 0,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
    background: theme.colors.onSurface[50],
    "& p": {
      width: "calc(100% - 8px - 24px)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}))

type Props = {
  onCancel?: () => void
  data: File | string
  name?: string
}

const PreviewFile = ({ data, onCancel, name, ...props }: Props) => {
  const classes = useStyles()

  const [imageError, toggleImageError] = useState(false)

  if (typeof data !== "string") {
    if (["image/jpeg", "image/png"].includes(data.type)) {
      return (
        <div className="relative h-15 w-[180px] bg-onSurface-50" {...props}>
          {onCancel && (
            <IconButton
              className="absolute right-0 top-0 z-1 !size-2.5 -translate-y-1/2 translate-x-1/2 rounded-[50%] p-0.25"
              color="critical"
              size="xs"
              variant="filled"
              onClick={onCancel}
            >
              <Xmark />
            </IconButton>
          )}
          {imageError ? (
            <div className="flex h-4/5 items-center justify-center">
              <Cloud height="24" width="24" />
            </div>
          ) : (
            <img
              alt="certificate"
              className="size-full rounded object-cover"
              src={URL.createObjectURL(data)}
              onError={() => toggleImageError(true)}
            />
          )}
        </div>
      )
    }
    return (
      <div className="relative h-15 w-[180px] bg-onSurface-50" {...props}>
        {onCancel && (
          <IconButton
            className="absolute right-0 top-0 z-1 !size-2.5 -translate-y-1/2 translate-x-1/2 rounded-[50%] p-0.25"
            color="critical"
            size="xs"
            variant="filled"
            onClick={onCancel}
          >
            <Xmark />
          </IconButton>
        )}
        <div className={classes.previewText}>
          <GoogleDocs height="24" width="24" />
          <Typography variant="strongSmallBody">{data.name}</Typography>
        </div>
      </div>
    )
  }

  return (
    <div className="relative h-15 w-[180px] bg-onSurface-50" {...props}>
      {onCancel && (
        <IconButton
          className="absolute right-0 top-0 z-1 !size-2.5 -translate-y-1/2 translate-x-1/2 rounded-[50%] p-0.25"
          color="critical"
          size="xs"
          variant="filled"
          onClick={onCancel}
        >
          <Xmark />
        </IconButton>
      )}
      {imageError ? (
        <div className="flex h-4/5 items-center justify-center">
          <Cloud height="24" width="24" />
        </div>
      ) : (
        <img
          alt="link"
          className="size-full rounded object-cover"
          src={data}
          onError={() => toggleImageError(true)}
        />
      )}
      <div className={classes.linkPreviewText}>
        <Link height="24" width="24" />
        <Typography className="block" variant="strongSmallBody">
          <a href={data} rel="noreferrer" target="_blank">
            {name || data}
          </a>
        </Typography>
      </div>
    </div>
  )
}

export default PreviewFile
