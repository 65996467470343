import React from "react"
import { createUseStyles } from "react-jss"

type Props = {
  backgroundImg?: string
  children: JSX.Element | React.ReactNode
  disabled?: boolean
}

const useStyles = createUseStyles({
  wrapper: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    filter: "blur(20px)",
    position: "absolute",
    zIndex: 1,
    width: "100%",
  },
  content: {
    position: "absolute",
    zIndex: 2,
    backgroundColor: "rgba(0,0,0, 0.6)",
    top: 62,
    left: 0,
    right: 0,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
  },
})

const OverlayWrapper = ({
  backgroundImg,
  children,
  disabled = false,
}: Props) => {
  const classes = useStyles()

  if (disabled) return <>{children}</>

  return (
    <>
      <div
        className={classes.wrapper}
        style={{ backgroundImage: backgroundImg }}
      />
      <div className={classes.content}>{children}</div>
    </>
  )
}

export default OverlayWrapper
