import { Button, Typography } from "@suraasa/placebo-ui"
import { SkillEvidence } from "api/resources/profile/types"
import clsx from "clsx"
import { getSkillProfileRoute } from "features/SkillProfile/utils"
import { Link, useParams } from "react-router-dom"

const ItemRow = ({
  className,
  data,
}: {
  className?: string
  data: SkillEvidence
}) => {
  const { username } = useParams()

  return (
    <div
      className={clsx(
        className,
        "flex flex-wrap items-center justify-between gap-1"
      )}
    >
      <Typography variant="strong" className="text-onSurface-800">
        {data.name}
      </Typography>
      <Link to={getSkillProfileRoute({ username, courseId: data.slug })}>
        <Button variant="text">View Evidences</Button>
      </Link>
    </div>
  )
}

export default ItemRow
