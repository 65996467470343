import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  RadioGroup,
  RadioGroupItem,
  Sheet,
  SheetBody,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Button,
  IconButton,
  Select,
  TextField,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Country, State } from "api/resources/global/types"
import { CoverStyle, Gender, Profile } from "api/resources/profile/types"
import { PhoneNumber } from "api/resources/users/types"
import clsx from "clsx"
// import SlidingSheet from "components/SlidingSheet"
// import { GlobalContext } from "components/GlobalState"
import { format, sub } from "date-fns"
import ProfileContext from "features/Profile/context"
import { Edit, WarningCircle, Xmark } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import routes from "routes"
import { buildUserName, handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"
import { trackingService } from "utils/tracking"

// import ViewProfilePicture from "../ViewProfilePicture"

const AVATAR_SIZE = 142

type PersonalDetailsType = {
  firstName: string
  lastName: string | null
  phoneNumber: PhoneNumber
} & Pick<
  Profile,
  | "gender"
  | "picture"
  | "dateOfBirth"
  | "nationality"
  | "country"
  | "state"
  | "lookingForJobs"
>

const useStyles = createUseStyles(theme => ({
  title: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      /** Edit profile button */
      "& button": {
        position: "absolute",
        right: theme.spacing(12 / 8),
        top: theme.spacing(12 / 8),
      },
    },
  },

  position: {
    marginBottom: theme.spacing(1 / 2),
  },

  cover: () => ({
    height: 184,
    minWidth: "100%",
    backgroundColor: theme.colors.onSurface[300],
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.spacing(1, 1, 0, 0),

    display: "flex",
    flexWrap: "wrap",
    alignItems: "end",
    paddingLeft: `calc(${AVATAR_SIZE}px + 24px + 16px)`,
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down("xs")]: {
      height: 108,
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      borderRadius: theme.spacing(0),
      /** Hide Video Portfolio and CV buttons from cover */
      "& button:nth-child(1),button:nth-child(2)": {
        display: "none",
      },
    },
  }),

  displayPicture: {
    background: "white",
    transform: "translateY(-60%)",
    left: theme.spacing(3),
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    position: "absolute",
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.colors.common.white[500]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(calc(-60% - 40px))",
      height: 91,
      width: 91,
      left: theme.spacing(2),
    },
  },

  content: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    paddingLeft: `calc(${AVATAR_SIZE}px + 24px + 16px)`,
    minHeight: "calc(284.1px - 175px)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },
  imgContainer: {
    borderRadius: "50%",
    overflow: "hidden",
    height: theme.spacing(12),
    width: theme.spacing(12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  displayPictureMobile: {
    width: "100%",
    objectFit: "cover",
    height: "100%",
  },
  imgOverlay: {
    position: "absolute",
    height: theme.spacing(12),
    borderRadius: "100px",
    width: theme.spacing(12),
    backgroundColor: theme.colors.onSurface[800],
    opacity: 0.5,
  },
  cameraIcon: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  phoneNumber: {
    "& span": {
      zIndex: "0 !important",
    },
  },
}))

type Props = {
  openSheet: boolean
  setOpenSheet: React.Dispatch<React.SetStateAction<boolean>>
  source?: string
}

const genderList: {
  label: string
  value: Gender
}[] = [
  { label: "Male", value: Gender.MALE },
  { label: "Female", value: Gender.FEMALE },
  { label: "Prefer not to say", value: Gender.PREFER_NOT_TO_SAY },
]

const getImageUrl = (imageName?: CoverStyle, isSmDown?: boolean) => {
  if (!imageName) {
    return undefined
  }

  let baseUrl = "https://assets.suraasa.com/lms/profile-assets/cover-images/"

  if (isSmDown) {
    baseUrl += "mobile/"
  }

  return `${baseUrl}${imageName}.webp`
}

const validateImageUpload = (file: File) => {
  if (!file.type.includes("image")) {
    toast.error("Only images are allowed")
    return
  }
  if (file.size / 1024 / 1024 > 5) {
    toast.error("Only images under 5 MB are allowed")
    return
  }

  const acceptedImageTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
  ]

  // TODO(profile): use FileUpload component here
  if (!acceptedImageTypes.includes(file.type)) {
    toast.error(
      "Invalid Image Type. Only jpg, jpeg, png & webp format are allowed"
    )
    return
  }

  return file
}

const CardItems = ({
  title,
  isRequired,
  rightPanel,
  bottomBorder,
}: {
  title: string
  isRequired?: boolean
  rightPanel?: JSX.Element
  bottomBorder?: boolean
}) => {
  return (
    <div
      className={clsx("mb-3 grid grid-cols-12", {
        "pb-3.5 border-b border-onSurface-200": bottomBorder,
      })}
    >
      <div
        className={clsx(
          "col-span-12 mb-3 w-[140px] sm:col-span-2 sm:col-start-1 sm:mb-0",
          {
            flex: isRequired,
          }
        )}
      >
        <Typography variant="strong" className="text-onSurface-500">
          {title}
        </Typography>
        {isRequired && <span className="text-critical-500">*</span>}
      </div>
      <div className="col-span-12 grow sm:col-span-9 sm:col-start-4">
        {rightPanel}
      </div>
    </div>
  )
}

const PersonalDetailsSheet = ({ openSheet, setOpenSheet, source }: Props) => {
  const classes = useStyles()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { profile, updateProfile } = useContext(ProfileContext)

  const { user, picture, coverStyle } = profile
  const fullName = buildUserName(user)
  const [loading, setLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)
  const [statesLoading, setStatesLoading] = useState(false)

  const { countries } = useResource(["countries"])

  const [states, setStates] = useState<State[]>([])

  const {
    register,
    handleSubmit,
    setError,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<Omit<PersonalDetailsType, "picture">>()

  useEffect(() => {
    if (profile.user.firstName) {
      reset({
        dateOfBirth: profile.dateOfBirth,
        country: profile.country,
        nationality: profile.nationality,
        state: profile.state,
        firstName: profile.user.firstName,
        lastName: profile.user.lastName,
        gender: profile.gender,
        lookingForJobs: profile.lookingForJobs,
      })
      setSelectedCountry(profile.country)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const onClose = () => {
    clearErrors()
    setOpenSheet(false)
  }

  const onSubmit = handleSubmit(async formData => {
    clearErrors()
    setLoading(true)

    const userProfile = {
      user: {
        firstName: formData.firstName,
        lastName: formData.lastName || null,
      },
      dateOfBirth: formData.dateOfBirth,
      gender: formData.gender,
      countryId: formData.country?.uuid,
      nationalityId: formData.nationality?.uuid,
      stateId: formData.state?.uuid,
      lookingForJobs: formData.lookingForJobs,
    }
    const res = await api.profile.update({
      data: userProfile,
    })
    if (res.isSuccessful) {
      toast.success("Details updated successfully.")

      trackingService.trackEvent("profile_personal_details_updated", {
        source,
      })

      updateProfile()
      setLoading(false)
      onClose()
    } else {
      handleErrors(res, { setter: setError })
      setLoading(false)
    }
  })

  const uploadPicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = (e.target.files as FileList)[0]

    if (fileUploaded && validateImageUpload(fileUploaded)) {
      const files = new FormData()
      files.append("picture", fileUploaded)

      const res = await api.profile.update({
        data: files,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (res.isSuccessful) {
        updateProfile()

        setLoading(false)
      } else {
        setLoading(false)
        if (res.errors.message) toast.error(res.errors.message)
      }
    }
  }

  const handlePictureRemove = async () => {
    const res = await api.profile.update({
      data: { picture: null },
    })
    if (res.isSuccessful) {
      updateProfile()
      setLoading(false)
    } else {
      setLoading(false)
      if (res.errors.message) toast.error(res.errors.message)
    }
  }

  useEffect(() => {
    async function getData() {
      if (selectedCountry) {
        setStatesLoading(true)
        const statesList = await api.global.listStates({
          urlParams: { countryId: selectedCountry.uuid },
          params: { page: "-1" },
        })
        if (statesList.isSuccessful) {
          setStates(statesList.data)
          setStatesLoading(false)
        }
      }
    }

    getData()
  }, [selectedCountry])

  return (
    <>
      <Sheet open={openSheet} onOpenChange={setOpenSheet}>
        <SheetContent side="bottom" height={90}>
          <SheetHeader
            hideClose
            className="flex items-center justify-between border-b border-onSurface-200"
          >
            <div className="flex items-center">
              <SheetClose>
                <IconButton color="black" className="me-1">
                  <Xmark />
                </IconButton>
              </SheetClose>
              <SheetTitle asChild>
                <Typography variant="title2" className="text-onSurface-800">
                  Edit Profile
                </Typography>
              </SheetTitle>
            </div>
            <Button
              type="submit"
              form="edit-profile-form"
              size="sm"
              loading={loading}
            >
              Save Changes
            </Button>
          </SheetHeader>
          <SheetBody className="!p-0">
            <input
              accept=".jpg, .jpeg, .png ,.webp"
              ref={hiddenFileInput}
              style={{ display: "none" }}
              type="file"
              onChange={uploadPicture}
            />
            {/* {viewProfilePicture && picture && (
              <ViewProfilePicture
                image={picture}
                onBack={() => setViewProfilePicture(false)}
                onClickChange={() => hiddenFileInput.current?.click()}
              />
            )} */}
            <div>
              <div>
                {/* <div className={classes.cover} /> */}
                <div
                  style={{
                    backgroundImage: `url(${getImageUrl(
                      coverStyle,
                      isXsDown
                    )})`,
                  }}
                  className={clsx("relative h-[107px] bg-cover text-black")}
                />
                <div className={classes.content}>
                  <Avatar
                    className="!absolute left-2 size-15 translate-y-[calc(-40%_-_40px)] shadow-[0px_6px_15px_rgba(0,0,0,0.05)] sm:left-3 sm:-translate-y-1/2"
                    src={profile.picture}
                    name={fullName}
                  />

                  <div style={{ width: "100%" }}>
                    <div className={classes.title}>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            nudge="right"
                            startAdornment={<Edit />}
                            variant="text"
                          >
                            Edit Profile Photo
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            {/* <DropdownMenuItem
                              className={clsx("!block sm:!hidden", {
                                "!hidden": !picture,
                              })}
                              onClick={() => {
                                setViewProfilePicture(true)
                              }}
                            >
                              View Profile Picture
                            </DropdownMenuItem> */}
                            <DropdownMenuItem
                              onClick={() => hiddenFileInput.current?.click()}
                            >
                              Upload New Image
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              color="critical"
                              disabled={!picture}
                              onClick={handlePictureRemove}
                            >
                              Remove
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              </div>
              <form
                id="edit-profile-form"
                className="px-2 sm:px-4"
                onSubmit={onSubmit}
              >
                <CardItems
                  isRequired
                  bottomBorder
                  title="Name"
                  rightPanel={
                    <div className="mb-1 flex flex-col items-start gap-x-3.5 gap-y-2 sm:mb-3 sm:flex-row">
                      <TextField
                        className="me-2"
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName?.message}
                        label="First Name"
                        placeholder="Enter First Name"
                        fullWidth
                        {...register("firstName", {
                          required: { value: true, message: "Required" },
                        })}
                      />
                      <TextField
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName?.message}
                        label="Last Name"
                        placeholder="Enter Last Name"
                        fullWidth
                        {...register("lastName")}
                      />
                    </div>
                  }
                />
                <CardItems
                  isRequired
                  bottomBorder
                  title="Date of Birth"
                  rightPanel={
                    <TextField
                      // endAdornment={<Calendar />}
                      error={Boolean(errors.dateOfBirth)}
                      helperText={errors.dateOfBirth?.message}
                      max={format(
                        sub(new Date(), {
                          years: 13,
                        }),
                        "yyyy-MM-dd"
                      )}
                      min={format(
                        sub(new Date(), {
                          years: 100,
                        }),
                        "yyyy-MM-dd"
                      )}
                      placeholder="DD / MM / YYYY"
                      type="date"
                      {...register("dateOfBirth", {
                        required: { value: true, message: "Required" },
                      })}
                    />
                  }
                />
                {/* <CardItems
                  isRequired
                  bottomBorder
                  title="Date of Birth shad"
                  rightPanel={
                    <Popover>
                      <PopoverTrigger asChild>
                        <ButtonShad
                          variant={"outline"}
                          className={clsx(
                            "w-[280px] justify-start text-left font-normal",
                            !date && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="w-2 h-2 mr-2" />
                          {date ? (
                            format(date, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </ButtonShad>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0">
                        <Calendar
                          mode="single"
                          selected={date}
                          onSelect={setDate}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  }
                /> */}
                <CardItems
                  isRequired
                  bottomBorder
                  title="Gender"
                  rightPanel={
                    <div>
                      <Controller
                        control={control}
                        name="gender"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            ref={ref}
                            className="mb-1"
                            error={Boolean(errors.gender)}
                            getOptionLabel={item => item.label}
                            getOptionValue={item => item.value.toString()}
                            helperText={errors.gender?.message}
                            inputLabelProps={{ required: true }}
                            options={genderList}
                            placeholder="Select Gender"
                            value={
                              value
                                ? genderList.find(item => item.value === value)
                                : null
                            }
                            onBlur={onBlur}
                            onChange={val => {
                              onChange(val?.value)
                            }}
                          />
                        )}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                      />
                    </div>
                  }
                />
                <CardItems
                  bottomBorder
                  title="Phone Number"
                  rightPanel={
                    <div>
                      <Typography variant="strongSmallBody">
                        We&apos;ve moved phone number to settings. Click{" "}
                        <Link
                          to={`${routes.accountDetails}?highlight-element-id=phone-number`}
                          className="text-interactive-500"
                        >
                          here
                        </Link>{" "}
                        to update your phone number.
                      </Typography>
                    </div>
                  }
                />
                <CardItems
                  isRequired
                  bottomBorder
                  title="Nationality"
                  rightPanel={
                    <div>
                      <Controller
                        control={control}
                        name="nationality"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            ref={ref}
                            className="mb-1"
                            error={Boolean(errors.nationality)}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ uuid }) => uuid}
                            helperText={errors.nationality?.message}
                            inputLabelProps={{ required: true }}
                            options={countries}
                            placeholder="Select Nationality"
                            value={
                              value
                                ? countries.find(
                                    item => item.uuid === value.uuid
                                  )
                                : null
                            }
                            onBlur={onBlur}
                            onChange={val => {
                              if (val) onChange(val)
                            }}
                          />
                        )}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                      />
                    </div>
                  }
                />
                <CardItems
                  isRequired
                  bottomBorder
                  title="Current Location"
                  rightPanel={
                    <div>
                      <div className="mb-1 flex flex-col items-start gap-x-3.5 gap-y-2 sm:mb-3 sm:flex-row">
                        <Controller
                          control={control}
                          name="country"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Select
                              ref={ref}
                              error={Boolean(errors.country)}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ uuid }) => uuid}
                              helperText={errors.country?.message}
                              inputLabelProps={{ required: true }}
                              options={countries}
                              placeholder="Country"
                              value={
                                value
                                  ? countries.find(
                                      item => item.uuid === value.uuid
                                    )
                                  : null
                              }
                              fullWidth
                              onBlur={onBlur}
                              onChange={val => {
                                if (val) {
                                  setSelectedCountry(val)
                                  onChange(val)
                                }
                              }}
                            />
                          )}
                          rules={{
                            required: { value: true, message: "Required" },
                          }}
                        />

                        <Controller
                          control={control}
                          name="state"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Select
                              ref={ref}
                              error={Boolean(errors.state)}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ uuid }) => uuid}
                              helperText={errors.state?.message}
                              inputLabelProps={{ required: true }}
                              isLoading={statesLoading}
                              options={states}
                              placeholder="State"
                              value={
                                value
                                  ? states.find(
                                      item => item.uuid === value.uuid
                                    )
                                  : null
                              }
                              fullWidth
                              onBlur={onBlur}
                              onChange={val => {
                                if (val) onChange(val)
                              }}
                            />
                          )}
                          rules={{
                            required: { value: true, message: "Required" },
                          }}
                        />
                      </div>
                    </div>
                  }
                />
                <CardItems
                  isRequired
                  title="Are you looking for jobs?"
                  rightPanel={
                    <div>
                      <Controller
                        rules={{
                          validate: v => {
                            if (v === null) return "Required"
                          },
                        }}
                        control={control}
                        name="lookingForJobs"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            onValueChange={value =>
                              onChange(value === "yes" ? true : false)
                            }
                            value={
                              value === true
                                ? "yes"
                                : value === false
                                ? "no"
                                : ""
                            }
                          >
                            <div className="flex items-center gap-2 space-x-1">
                              <RadioGroupItem
                                containerClass="flex space-x-1 items-center"
                                value="yes"
                                id="yes"
                                label="Yes"
                              />
                              <RadioGroupItem
                                containerClass="flex space-x-1 items-center"
                                value="no"
                                id="no"
                                label="No"
                              />
                            </div>
                          </RadioGroup>
                        )}
                      />

                      {errors.lookingForJobs && (
                        <div className="mt-1 flex text-critical-500">
                          <WarningCircle
                            width={18}
                            height={18}
                            className="me-0.5 shrink-0"
                          />
                          <Typography
                            className="grow text-critical-500"
                            variant="smallBody"
                          >
                            {errors.lookingForJobs?.message}
                          </Typography>
                        </div>
                      )}
                      <Typography
                        variant="smallBody"
                        className="mt-1 text-onSurface-500"
                      >
                        When you choose Yes, your profile will be visible to
                        schools hiring on the Suraasa Platform.
                      </Typography>
                    </div>
                  }
                />
              </form>
            </div>
          </SheetBody>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default PersonalDetailsSheet
