import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Divider,
  Select,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Certification,
  Evidence,
  modeOfLearningChoices,
} from "api/resources/profile/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import React, { useContext, useEffect, useState } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"
import { trackingService } from "utils/tracking"

import RemoveDialog from "@/common/RemoveDialog"

import ProfileContext from "../../../context"
import UploadEvidenceSection from "../UploadEvidenceSection"
type FormData = Omit<Certification, "isVerified" | "id" | "evidences">

type Props = {
  id: Certification["id"] | null
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  source?: string
}

const initialValues: FormData = {
  name: "",
  organisationName: "",
  willExpire: false,
  completionDate: "",
  expirationDate: "",
  modeOfLearning: null,
}

const EditDialog = ({ source, id, open, setOpen }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ defaultValues: initialValues })

  const {
    workExperiences,
    academics: { qualifications, certifications },
  } = useContext(ProfileContext)

  const [loading, toggleLoading] = useState(false)

  const isEditable = Boolean(id)

  /**
   * These evidences are uploaded by the user while they are using the application
   * They get converted into `evidenceFiles` when user hits submit.
   */
  const newEvidences = useArray<File | string>([])

  /**
   * These evidences come from the API
   */
  const evidencesFiles = useArray<Evidence>([])
  const evidencesFilesToBeDeleted = useArray<Evidence["id"]>([])

  const resetForm = () => {
    newEvidences.clear()
    evidencesFiles.clear()
    evidencesFilesToBeDeleted.clear()
    reset(initialValues)
  }

  const uploadEvidence = async (data: Certification) => {
    const evidences = new FormData()

    newEvidences.array.forEach(item => {
      if (typeof item === "string") {
        evidences.append("url[]", item)
      } else {
        evidences.append("file[]", item)
      }
    })

    const res = await api.profile.certification.evidence.create({
      data: evidences,
      urlParams: {
        id: data.id,
      },
    })
    return res
  }

  const deleteEvidence = async (evidenceId: Certification["id"]) =>
    api.profile.certification.evidence.delete({
      data: {
        evidences: evidencesFilesToBeDeleted.array,
      },
      urlParams: {
        id: evidenceId,
      },
    })

  const showHideSkillsAlert = ![
    workExperiences.data.length,
    qualifications.data.length,
    certifications.data.length,
  ].some(length => length > 1)

  const handleRemove = async () => {
    if (!id) return

    const res = await api.profile.certification.delete({ urlParams: { id } })

    if (res.isSuccessful) {
      certifications.refetch()
      handleClose()
    } else if (res.errors.message) {
      toast.error(res.errors.message)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      toggleLoading(true)
      const res = await api.profile.certification.retrieve({
        urlParams: { id: id || "" },
      })

      if (res.isSuccessful) {
        const { evidences, ...data } = res.data
        evidencesFiles.set(evidences)
        reset({ ...data, willExpire: !data.willExpire })
      }
      toggleLoading(false)
    }

    if (isEditable) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditable, reset, toggleLoading])

  const onSubmit = handleSubmit(async formData => {
    const apiData = {
      ...formData,
      willExpire: !formData.willExpire,
      modeOfLearning: modeOfLearningChoices.find(
        ({ value }) => value === formData.modeOfLearning
      )?.value,
      expirationDate: !formData.willExpire ? formData.expirationDate : null,
    }

    if (id) {
      if (evidencesFilesToBeDeleted.array.length > 0) {
        const res = await deleteEvidence(id)
        if (!res.isSuccessful) {
          if (res.errors.message) {
            toast.error(res.errors.message)
          }
          return
        }
      }

      const res = await api.profile.certification.update({
        data: apiData,
        urlParams: { id },
      })

      if (res.isSuccessful) {
        if (newEvidences.array.length > 0) {
          const evidenceRes = await uploadEvidence(res.data)
          if (evidenceRes.isSuccessful) {
            certifications.refetch()
            return handleClose()
          }
          if (evidenceRes.errors.message)
            return toast.error(evidenceRes.errors.message)
        } else {
          certifications.refetch()
          return handleClose()
        }
      } else {
        handleErrors(res, { setter: setError })
      }
    } else {
      const res = await api.profile.certification.create({
        data: apiData,
      })
      trackingService.trackEvent("profile_academic_certification_added", {
        source,
      })

      if (res.isSuccessful) {
        if (newEvidences.array.length > 0) {
          const evidenceRes = await uploadEvidence(res.data)
          if (evidenceRes.isSuccessful) {
            certifications.refetch()
            return handleClose()
          }
          if (evidenceRes.errors.message)
            return toast.error(evidenceRes.errors.message)
        } else {
          certifications.refetch()
          return handleClose()
        }
      } else {
        handleErrors(res, { setter: setError })
      }
    }
  })

  const handleClose = () => {
    resetForm()
    setOpen(false)
  }

  const Section1 = () => (
    <>
      <TextField
        autoFocus
        errors={errors.name?.message}
        required
        label="certification Name"
        placeholder="Ex: B. Ed."
        {...register("name", {
          required: { value: true, message: "Required" },
        })}
      />

      <TextField
        errors={errors.organisationName?.message}
        required
        label="institute Name"
        placeholder="Ex: Harvard University"
        {...register("organisationName", {
          required: { value: true, message: "Required" },
        })}
      />
    </>
  )

  const Section2 = () => {
    const willExpire = useWatch({ name: "willExpire", control })

    return (
      <>
        <Controller
          name="willExpire"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex items-center space-x-1">
              <Checkbox
                checked={value}
                onCheckedChange={onChange}
                containerClass="flex items-center"
              />
              <Typography variant="smallBody">
                This certificate does not expire
              </Typography>
            </div>
          )}
        />
        <div className="flex flex-col gap-3 sm:flex-row">
          <TextField
            errors={errors.completionDate?.message}
            required
            label="Completion Date"
            placeholder="Ex: Jan 2021"
            type="date"
            {...register("completionDate", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            disabled={willExpire}
            errors={errors.expirationDate?.message}
            required={!willExpire}
            label="Expiration Date (if any)"
            placeholder="Ex: May 2021"
            type="date"
            {...register("expirationDate", {
              required: { value: !willExpire, message: "Required" },
            })}
          />
        </div>
        <Controller
          control={control}
          name="modeOfLearning"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              errors={errors.modeOfLearning?.message}
              required
              label="Mode of learning"
              options={modeOfLearningChoices}
              placeholder="Select Learning Mode"
              value={
                value && modeOfLearningChoices.find(c => c.value === value)
              }
              onBlur={onBlur}
              onChange={newValue => onChange(newValue?.value)}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </>
    )
  }

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="strong">
                {isEditable ? "Edit" : "Add New"} Certification
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div>
              {loading && <LoadingOverlay />}
              <form className="mb-3 flex flex-col gap-3" onSubmit={onSubmit}>
                <Section1 />
                <div>
                  <Divider className="my-1 bg-onSurface-200" weight="medium" />
                </div>
                <Section2 />
                <div>
                  <Divider className="my-1 bg-onSurface-200" weight="medium" />
                </div>
                <UploadEvidenceSection
                  buttonLabel="Add Certificate"
                  evidenceFiles={evidencesFiles}
                  handleEvidenceFilesToBeDeleted={
                    evidencesFilesToBeDeleted.push
                  }
                  inputLabel="Certifications (upload upto 3)"
                  limit={3}
                  maxSize={5}
                  newEvidences={newEvidences}
                />
              </form>
            </div>
          </DialogBody>
          <DialogFooter
            className={clsx({
              "!justify-between": isEditable,
            })}
          >
            {isEditable && (
              <RemoveDialog
                trigger={
                  <Button
                    variant="text"
                    color="critical"
                    loading={isSubmitting}
                  >
                    Remove
                  </Button>
                }
                title="Remove Certification?"
                onRemove={handleRemove}
              >
                <Typography variant="smallBody">
                  Are you sure you want to remove{" "}
                  <b>
                    {certifications.data.find(item => item.id === id)?.name}
                  </b>{" "}
                  from your profile?
                </Typography>
              </RemoveDialog>
            )}

            <div>
              <Button
                variant="outlined"
                onClick={handleClose}
                className="mr-1.25 border-muted text-muted"
                size="sm"
                color="black"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="sm"
                loading={isSubmitting}
                className="py-1.25"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditDialog
