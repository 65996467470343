import { Button, IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import { EmploymentType, WorkExperienceType } from "api/resources/profile/types"
import { SourceType } from "api/resources/references/types"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns"
import ProfileContext from "features/Profile/context"
import { Edit } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { Mail } from "lucide-react"
import { useContext, useState } from "react"

import ReferenceEditDialog from "../References/EditDialog"
import { PublicReferenceCard, ReferenceCard } from "../References/Utils"

export const getEmploymentTypeDisplay = (value: EmploymentType | null) => {
  if (!value) return null
  return capitalize(value.replace("_", " "))
}

const WorkExperienceItem = ({
  onEdit,
  data,
  isPublic,
}: {
  onEdit?: () => void
  data: WorkExperienceType
  isPublic?: boolean
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  const { referenceAnalytics } = useContext(ProfileContext)

  const referenceCount = referenceAnalytics.data?.workExperience[data.id] || 0

  return (
    <>
      {openDialog && (
        <ReferenceEditDialog
          currentWorkExperience={data}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}

      <div className="group relative" key={data.id}>
        {!isPublic && onEdit && (
          <IconButton
            className="absolute right-0"
            color="black"
            onClick={() => onEdit()}
          >
            <Edit className="!size-3" />
          </IconButton>
        )}
        <div>
          <span className="mb-0.5 flex items-center gap-1">
            <Typography variant="strongSmallBody">{data.title}</Typography>
            {data.curriculum && (
              <Tag
                size="sm"
                className="rounded-full border-muted bg-white text-smallBody text-onSurface-800"
              >
                {data.curriculum.name}
              </Tag>
            )}
          </span>

          <Typography
            className="mb-0.25 text-onSurface-600"
            variant="smallBody"
          >
            {[
              getEmploymentTypeDisplay(data.employmentType),
              data.teachingLevel?.name,
            ]
              .filter(Boolean)
              .join(", ")}
          </Typography>

          <Typography
            className="mb-0.25 text-onSurface-600"
            variant="smallBody"
          >
            {[
              data.organisationName,
              [data.state?.name, data.country?.name].filter(Boolean).join(", "),
            ]
              .filter(Boolean)
              .join(" • ")}
          </Typography>

          {data.startDate && (
            <Typography className="text-onSurface-600" variant="smallBody">
              {format(new Date(data.startDate), "MMMM yyyy")} -{" "}
              {data.currentlyWorking
                ? "Present"
                : data.endDate && format(new Date(data.endDate), "MMMM yyyy")}
            </Typography>
          )}

          {data.description && (
            <TruncatedText
              className="mt-1"
              maxLength={300}
              style={{
                whiteSpace: "pre-wrap",
              }}
              variant="smallBody"
            >
              {data.description}
            </TruncatedText>
          )}

          {isPublic && referenceCount > 0 && (
            <PublicReferenceCard
              count={referenceCount}
              referenceType={SourceType.UserWorkExperience}
            />
          )}
          {!isPublic &&
            data.references?.map((ref, ind) => (
              <ReferenceCard key={ind} data={ref} />
            ))}

          {!isPublic && (
            <Button
              className="mt-2"
              onClick={() => setOpenDialog(true)}
              variant="text"
              startAdornment={<Mail />}
            >
              Add Reference
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default WorkExperienceItem
