import api from "api"
import { AuthData } from "api/resources/users/types"
import FullPageLoading from "components/FullPageLoading"
import { usePostHog } from "posthog-js/react"
import { useEffect, useLayoutEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { getAuthInfo } from "utils/helpers"
import { trackingService } from "utils/tracking/"
import { GA } from "utils/tracking/ga"

const GlobalAuthObserver = () => {
  const [authIsValid, setAuthIsValid] = useState<boolean>(false)
  const auth = getAuthInfo()

  const userId = auth?.user.uuid

  const posthog = usePostHog()

  useEffect(() => {
    GA.init()
  }, [])

  useEffect(() => {
    const utmParams = trackingService.getUTMParams(false)

    try {
      sessionStorage.setItem(
        BROWSER_STORAGE_KEYS.sessionUtmParams,
        JSON.stringify(utmParams, null, 2)
      )
    } catch (e) {
      /* empty */
    }
  }, [])

  const location = useLocation() // new

  useEffect(() => {
    posthog.capture("$pageview")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useLayoutEffect(() => {
    const checkAuth = async (auth: AuthData) => {
      try {
        const res = await api.users.verifyAccessToken({
          data: {
            token: auth.accessToken,
          },
          headers: {
            Authorization: null,
          },
        })

        if (res.verified) {
          setAuthIsValid(res.verified)
        }
      } catch (e) {
        console.log("> Access token is invalid", e)
      }
    }

    if (auth) {
      checkAuth(auth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <>
      {auth && !authIsValid && <FullPageLoading />}
      {(!auth || authIsValid) && <Outlet />}
    </>
  )
}

export default GlobalAuthObserver
