import { Button, Tooltip, Typography } from "@suraasa/placebo-ui"
import { CircularProgress, Menu, MenuItem } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { UserPlannerItemData } from "api/resources/assignments/types"
import ViewPreviousSubmissionDialog from "features/AssignmentResult/ViewPreviousSubmissionDialog"
import { NavArrowDown } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { formatDate } from "utils/helpers"

type Props = {
  currentSubmissionId?: string
  userPlannerItemData: UserPlannerItemData
  onLoadSubmission?: (latestSubmissionUUID?: string | null) => void
}

const useHelperTooltip = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const hasSeenTooltip = localStorage.getItem(
      "hasSeenPreviousSubmissionsTooltip"
    )
    if (!hasSeenTooltip) {
      setShow(true)
    }
  }, [])

  const closeTooltip = () => {
    localStorage.setItem("hasSeenPreviousSubmissionsTooltip", "true")
    setShow(false)
  }

  return { show, closeTooltip }
}

const PreviousSubmissionsDropdown = ({
  currentSubmissionId,
  userPlannerItemData,
  onLoadSubmission,
}: Props) => {
  const tooltipController = useHelperTooltip()

  const { data, isSuccess, isLoading, isError } = useQuery({
    queryFn: () =>
      api.assignments.previousSubmissionsList({
        urlParams: {
          assignmentUUID: userPlannerItemData.learningContent.uuid,
          userPlannerItemId: userPlannerItemData.id,
        },
      }),
    queryKey: queries.assignments.submissionsListByAssignmentId(
      userPlannerItemData.learningContent.id.toString()
    ).queryKey,
    select: data =>
      data?.sort((a, b) =>
        new Date(a.dateCreated) < new Date(b.dateCreated) ? 1 : -1
      ),
  })

  const [viewPreviousSubmissionIndex, setViewPreviousSubmissionIndex] =
    useState<string | null>(null)

  useEffect(() => {
    if (!onLoadSubmission) return

    const submissionId = isSuccess && data?.length > 0 ? data[0].uuid : null
    onLoadSubmission(submissionId)
  }, [isSuccess, data, onLoadSubmission])

  const getMenuItems = () => {
    if (!data) return null
    if (data.length === 0) {
      return <MenuItem>No previous submissions yet</MenuItem>
    }

    return data.map((x, i) => {
      const isCurrent = currentSubmissionId === x.uuid
      return (
        <MenuItem
          disabled={isCurrent}
          key={x.uuid}
          onClick={() => {
            tooltipController.closeTooltip()
            setViewPreviousSubmissionIndex(x.uuid)
          }}
        >
          <p className="w-full">
            Submission {data.length - i} {isCurrent && "(current)"}
          </p>
          {x.dateCreated && (
            <Typography
              className="w-full text-xs text-onSurface-500"
              variant="smallBody"
            >
              ({formatDate(x.dateCreated)})
            </Typography>
          )}
        </MenuItem>
      )
    })
  }

  return (
    <>
      <ViewPreviousSubmissionDialog
        open={!!viewPreviousSubmissionIndex}
        onClose={() => setViewPreviousSubmissionIndex(null)}
        title={`Previous Submission`}
        submissionId={viewPreviousSubmissionIndex}
      />

      <Menu
        menuButton={
          <Button variant="text" color="null">
            <p className="flex items-center text-sm font-semibold">
              <span className="me-0.25">
                View Previous Submissions & Reviews
              </span>
              {isLoading ? (
                <span className="ms-1">
                  <CircularProgress size="xs" />
                </span>
              ) : (
                <NavArrowDown />
              )}
            </p>
          </Button>
        }
      >
        {isError && <MenuItem>Error!</MenuItem>}
        {isSuccess && getMenuItems()}
      </Menu>
    </>
  )
}

export default PreviousSubmissionsDropdown
