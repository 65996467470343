import { Typography } from "@suraasa/placebo-ui"
import { Container } from "@suraasa/placebo-ui-legacy"
import React from "react"

const StickySubmittedBar = ({ color }: { color: string }) => {
  return (
    <div
      className="w-full py-2 shadow-[0px_6px_15px_rgba(0,0,0,0.05)]"
      style={{
        background: color,
      }}
    >
      <Container>
        <Typography className="text-white">
          Your video has been uploaded. We’ll let you know when the results are
          out. Stay tuned!
        </Typography>
      </Container>
    </div>
  )
}

export default StickySubmittedBar
