import {
  Button,
  RadioGroup,
  RadioGroupItem,
  Sheet,
  SheetBody,
  SheetContent,
  Typography,
} from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { isNumberArray } from "features/AItools/helper"
import { Page, Xmark } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { formatDate } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

const SelectLessonPlanSheet = ({
  open = false,
  onOpenChange = () => {},
  onSelect,
}: React.PropsWithChildren<{
  open?: boolean
  onOpenChange?: (open: boolean) => void
  id?: string
  onSelect: (item: ListPromptHistory, lessonPlanResponse: number) => void
}>) => {
  const theme = useTheme<Theme>()

  const [selected, setSelected] = useState<ListPromptHistory | null>(null)
  const [lessonPlanResponse, setLessonPlanResponse] = useState<number>(-1)

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const { isFetching, isSuccess, hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery({
      queryKey: queries.aiTools.listHistory(ToolType.lessonPlan).queryKey,
      queryFn: x =>
        api.aiTools.listHistory({
          type: ToolType.lessonPlan,
          params: { page: x.pageParam || 1 },
        }),
      onError: err => {
        if (err instanceof APIError) {
          console.log(err.statusCode)
          console.log(err.is500)
          console.log(err.__errors)
        }
      },

      getNextPageParam: lastPage => {
        return lastPage.nextPage ?? undefined
      },
      enabled: open,
    })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const allData = data?.pages.flatMap(page => page.data)

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isSmDown ? "bottom" : "right"}
        className="bg-white p-0"
        height={95}
      >
        <SheetBody className="relative h-full rounded-3xl px-2 py-1.5">
          <Button
            variant="text"
            startAdornment={<Xmark />}
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
          <Typography variant="title3" className="my-3">
            Select a Lesson Plan
          </Typography>

          {isLoading && (
            <div className="flex flex-col items-center justify-center p-5">
              <LoadingOverlay />
            </div>
          )}

          {isSuccess && allData && allData.length > 0 && (
            <div className="flex grow flex-col gap-2">
              {allData.map((item, i) => {
                const isLastItem = i === allData.length - 1

                return (
                  <div key={item.promptDataId} ref={isLastItem ? ref : null}>
                    <Item
                      isSelected={selected?.promptDataId === item.promptDataId}
                      lessonPlanResponseId={lessonPlanResponse}
                      item={item}
                      onClick={historyItem => {
                        setSelected(historyItem)
                      }}
                      onClickLessonPlan={lessonPlanResponseId => {
                        setLessonPlanResponse(lessonPlanResponseId)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          )}

          <Button
            className="sticky bottom-1 mt-3 w-full"
            variant="filled"
            disabled={!selected}
            onClick={() => {
              selected && onSelect(selected, lessonPlanResponse)
              onOpenChange(false)
            }}
          >
            Done
          </Button>
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default SelectLessonPlanSheet

const Item = ({
  item,
  isSelected,
  onClick,
  onClickLessonPlan,
  lessonPlanResponseId,
}: {
  onClick: (item: ListPromptHistory | null) => void
  onClickLessonPlan: (lessonPlanResponse: number) => void
  item: ListPromptHistory
  isSelected?: boolean
  lessonPlanResponseId: number
}) => {
  if (item.toolType === ToolType.lessonPlan) {
    return (
      <button
        onClick={() => {
          onClick(item)
          if (item.responseIds && !isNumberArray(item.responseIds)) {
            onClickLessonPlan(item.responseIds[0].responseIds[0])
          }
        }}
        className={clsx(
          "duration flex w-full items-center rounded-xl border border-solid border-surface-100 px-2 py-2.25 text-start transition-colors",
          {
            "bg-onSurface-900 text-white": isSelected,
          }
        )}
      >
        <div
          className={clsx(
            "mr-1.5 flex size-6 shrink-0 items-center justify-center rounded-xl border-[0.75px] border-solid border-decorative-one-100 bg-decorative-one-50"
          )}
        >
          <Page className="size-3 text-decorative-one-100" />
        </div>
        <div>
          <Typography
            variant="title4"
            className={clsx("mb-0.5", {
              "text-white": isSelected,
            })}
          >
            {item.title}
          </Typography>
          <Typography
            variant="smallBody"
            className={clsx({
              "text-onSurface-300": isSelected,
            })}
          >
            {formatDate(item.dateCreated)}
          </Typography>
        </div>
      </button>
    )
  }
  return (
    <div className="rounded-xl border border-solid border-surface-100">
      <button
        onClick={() => {
          onClick(item)
        }}
        className={clsx(
          "duration flex w-full items-center rounded-xl px-2 py-2.25 text-start transition-colors",
          {
            "bg-onSurface-900 text-white": isSelected,
          }
        )}
      >
        <div
          className={clsx(
            "mr-1.5 flex size-6 shrink-0 items-center justify-center rounded-xl border-[0.75px] border-solid border-decorative-one-100 bg-decorative-one-50"
          )}
        >
          <Page className="size-3 text-decorative-one-100" />
        </div>
        <div>
          <Typography
            variant="title4"
            className={clsx("mb-0.5", {
              "text-white": isSelected,
            })}
          >
            {item.title}
          </Typography>
          <Typography
            variant="smallBody"
            className={clsx({
              "text-onSurface-300": isSelected,
            })}
          >
            {formatDate(item.dateCreated)}
          </Typography>
        </div>
      </button>
      <div
        className={clsx(
          "grid grid-rows-[0fr] transition-[grid-template-rows] duration-500",
          {
            "grid-rows-[1fr]": isSelected,
          }
        )}
      >
        <div className="overflow-hidden">
          <RadioGroup
            onValueChange={option => {
              onClickLessonPlan(parseInt(option))
            }}
            value={lessonPlanResponseId.toString()}
            className="flex flex-col gap-1 pb-2 pl-2 pt-1"
          >
            {item.responseIds &&
              !isNumberArray(item.responseIds) &&
              item.responseIds?.map((i, index) => {
                return (
                  <RadioGroupItem
                    key={i.id}
                    value={i.responseIds[0].toString()}
                    containerClass="flex items-center gap-1"
                    label={`Lesson Plan ${index + 1}`}
                  />
                )
              })}
          </RadioGroup>
        </div>
      </div>
    </div>
  )
}
