import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Award, Evidence, EvidenceType } from "api/resources/profile/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import RemoveDialog from "@/common/RemoveDialog"

import ProfileContext from "../../context"
import UploadEvidenceSection from "../Academics/UploadEvidenceSection"
type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id?: string
  open: boolean
}

const AwardDialog = ({ id, open, setOpen }: Props) => {
  const {
    achievements: { awards },
  } = useContext(ProfileContext)

  /**
   * These evidences are uploaded by the user while they are using the application
   * They get converted into `evidenceFiles` when user hits submit.
   */
  const newEvidences = useArray<File | string>([])
  /**
   * These evidences come from the API
   */
  const evidencesFiles = useArray<Evidence>([])

  const evidencesFilesToBeDeleted = useArray<Evidence["id"]>([])

  const [data, setData] = useState<Award>()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Award>()

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.awards.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)

        if (res.data.certificate) {
          evidencesFiles.set([
            {
              id: "1",
              evidenceType: EvidenceType.File,
              file: res.data.certificate,
              fileName: "Certificate",
            },
          ])
        }

        reset(res.data)
      }
    }
    if (id) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset])

  const onSubmit = handleSubmit(
    async ({ title, issuer, issuedOn, description }) => {
      const apiData = new FormData()
      apiData.append("title", title)
      apiData.append("issuer", issuer)
      apiData.append("issuedOn", issuedOn || "")
      apiData.append("description", description || "")

      if (
        newEvidences.array.length === 0 &&
        evidencesFilesToBeDeleted.array.length !== 0
      ) {
        apiData.append("certificate", "")
      }

      newEvidences.array.forEach(item => {
        apiData.append("certificate", item)
      })

      if (id) {
        const res = await api.profile.awards.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          awards.refetch()

          setOpen(false)
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.awards.create({ data: apiData })
        if (res.isSuccessful) {
          awards.refetch()

          setOpen(false)
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    if (id) {
      const res = await api.profile.awards.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        awards.refetch()
        setOpen(false)
      }
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="strong">
                {id ? "Edit" : "Add New"} Award
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            {id && !data && <LoadingOverlay />}
            <form onSubmit={onSubmit}>
              <TextField
                className="mb-1"
                autoFocus
                errors={errors.title?.message}
                label="Title"
                required
                placeholder="Ex: Best Teacher Award"
                {...register("title", {
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />

              <TextField
                className="mb-1"
                errors={errors.issuer?.message}
                required
                label="Issuer"
                placeholder="Ex: Teachers Association of India"
                {...register("issuer", {
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />

              <TextField
                className="mb-3"
                errors={errors.issuedOn?.message}
                label="Issue Date"
                placeholder="Ex: 21 Jan 2021"
                type="date"
                {...register("issuedOn")}
              />
              <TextArea
                errors={errors.description?.message}
                label="Description"
                className="mb-2"
                placeholder="Talk about your role and experience etc..."
                rows={5}
                {...register("description", {
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />

              <UploadEvidenceSection
                buttonLabel="Add Certificate"
                evidenceFiles={evidencesFiles}
                handleEvidenceFilesToBeDeleted={evidencesFilesToBeDeleted.push}
                inputLabel="Certificate"
                limit={1}
                newEvidences={newEvidences}
              />
            </form>
          </DialogBody>

          <DialogFooter
            className={clsx("flex ", {
              "!justify-between": id,
            })}
          >
            {id && (
              <RemoveDialog
                trigger={
                  <Button
                    variant="text"
                    color="critical"
                    loading={isSubmitting}
                  >
                    Remove
                  </Button>
                }
                title="Remove Award?"
                onRemove={handleRemove}
              >
                <Typography variant="smallBody">
                  Are you sure you want to remove&nbsp;
                  <Typography className="inline" variant="strongSmallBody">
                    <strong>{data?.title}</strong>
                  </Typography>
                  &nbsp;from your profile?
                </Typography>
              </RemoveDialog>
            )}

            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpen(false)
                }}
                className="mr-1.25 border-muted text-muted"
                size="sm"
                color="black"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="sm"
                loading={isSubmitting}
                className="py-1.25"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AwardDialog
