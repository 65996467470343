import { LessonPlanResponse } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"

import MarkdownRenderer from "../../../MarkdownRenderer"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Content = ({ ...props }: LessonPlanResponse) => {
  return (
    <Paper>
      <MarkdownRenderer>dummy</MarkdownRenderer>
    </Paper>
  )
}

export default Content
