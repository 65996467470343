import { Elements } from "@stripe/react-stripe-js"
import type { StripeElementsOptions } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Tooltip,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { CheckoutOrder } from "api/resources/orders/types"
import { paymentMethod } from "api/resources/payment/types"
import { APIError } from "api/utils"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Card from "components/Card"
import LoadingOverlay from "components/LoadingOverlay"
import TalkToMentorBanner from "components/TalkToMentorBanner"
import ImageBackgroundCard from "features/LearningItems/ImageBackgroundCard"
import { context } from "global/Context/context"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { getPlatformURL, handleErrors } from "utils/helpers"
import useFormatPrice from "utils/hooks/useFormatPrice"

import AddAddress from "./AddAddress"
import { openRazorpayCheckoutForm } from "./helpers"
import useScript from "./hooks/useScript"
import Polling from "./Polling"
import StripeCheckoutForm from "./StripeCheckoutForm"

const Payment = () => {
  const { authInfo } = useContext(context)

  const [canProceed, setCanProceed] = useState(false)
  const [clientSecret, setClientSecret] = useState("")
  const [paymentMethodData, setPaymentMethodData] =
    useState<CheckoutOrder | null>(null)

  const [intermediateLoading, setIntermediateLoading] = useState(false)
  const [polling, setPolling] = useState(false)
  const [stripeFormOpen, setStripeFormOpen] = useState(false)
  const [juspayPaymentURL, setJuspayPaymentURL] = useState<string | null>(null)
  const [paymentPlanId, setPaymentPlanId] = useState("")
  const options: StripeElementsOptions = {
    clientSecret,
  }

  const [stripePromise, setStripePromise] = useState<any>()

  useEffect(() => {
    setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PAYMENT_KEY))
  }, [])

  const formatPrice = useFormatPrice()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ promocode: string }>()

  const onSubmit = handleSubmit(async promocode => {
    applyPromocode(promocode.promocode)
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    data: cart,
    isLoading,
    isError,
    refetch: fetchCart,
  } = useQuery({
    enabled: false,
    queryFn: () => api.payment.getCart(),
    queryKey: queries.payment.cart().queryKey,
  })

  const rzpScriptStatus = useScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  )

  useEffect(() => {
    const processPromo = async () => {
      const productId = searchParams.get("product_id")
      const centreId = searchParams.get("centre_id")
      const promo = searchParams.get("promo")

      if (!productId || !centreId) return
      setIntermediateLoading(true)

      try {
        await api.payment.addItemToCart({
          data: {
            productId: productId,
            centreId: centreId,
          },
        })
      } catch (e) {
        if (e instanceof APIError) {
          const msg = e.message || ""
          if (msg.toLowerCase().includes("already enrolled in this item")) {
            toast.error("You are already enrolled in this programme")
            navigate(routes.learning)
          }
        }
      }
      setIntermediateLoading(false)

      if (promo) {
        applyPromocode(promo)
      }
    }

    processPromo().then(() => fetchCart())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applyPromocode = async (promocode: string | null) => {
    if (!promocode) return
    try {
      await api.payment.updateCart({ data: { promocode } })
      fetchCart()
      toast.success("Promocode applied!")
    } catch (e) {
      if (e instanceof APIError) handleErrors(e, { setter: setError })
    }
  }

  const removePromocode = async () => {
    try {
      await api.payment.updateCart({
        data: {
          promocode: null,
        },
      })
      fetchCart()
      reset()
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
    }
  }

  const successCallback = ({ isFree } = { isFree: false }) => {
    let redirectUrl = routes.orders

    if (isFree) {
      redirectUrl = routes.learning
      navigate({
        pathname: redirectUrl,
        search: window.location.search,
      })
      return
    }
    const searchParams = new URLSearchParams(window.location.search)

    try {
      const itemId = cart?.cartItems[0].productId
      if (itemId) searchParams.append("item_id", `${itemId}`)
    } catch (e) {
      console.error("> Error while generating redirect url to orders page", e)
    }
    setPolling(true)
  }

  const fetchPaymentMethod = async (data: CheckoutOrder, fallback = false) => {
    setPaymentMethodData(data)
    try {
      const res = await api.payment.listPaymentMethod({
        params: {
          callback_url: `${window.location.origin}${routes.orders}`,
          fallback,
        },
        urlParams: {
          paymentPlanId: data.paymentPlans[0].id,
        },
      })
      const paymentMethodData = res[0]
      // If order is free then direct redirect to orders page
      if (paymentMethodData.paymentGatewayOrder?.isFreeOrder) {
        successCallback({ isFree: true })
        return
      }

      setPaymentPlanId(data.paymentPlans[0].id)
      if (paymentMethodData.paymentMethodId === paymentMethod.JUSPAY) {
        const { paymentLink } = paymentMethodData.paymentMethodDetails
        if (paymentLink) {
          setJuspayPaymentURL(paymentLink)
        }
      }

      // Payment gateway specific code
      if (paymentMethodData.paymentMethodId === paymentMethod.RAZORPAY) {
        if (rzpScriptStatus !== "ready") {
          fetchPaymentMethod(data, true)
          return
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { user } = authInfo!

        try {
          openRazorpayCheckoutForm(
            paymentMethodData.paymentMethodDetails.orderId,
            paymentMethodData.paymentMethodDetails.amount,
            data.id,
            {
              fullName: `${user.firstName} ${user.lastName}`,
              email: user.email,
            },
            () => {
              // GA.trackEvent(GA_EVENTS.purchase, {
              //   payment_gateway: "Razorpay",
              //   currency: state.cart.currency?.code,
              //   value: state.cart.cart_total,
              //   coupon: state.cart.promocode?.code,
              //   items: state.cart.cartItems.map(item => ({
              //     item_id: item.product_id,
              //     item_name: item.product_name,
              //   })),
              // })
              successCallback()
            }
          )
        } catch (e) {
          console.error(e)
          console.warn("Using fallback payment system")
          fetchPaymentMethod(data, true)
          return
        }
      }

      if (
        paymentMethodData.paymentMethodId ===
        paymentMethod.RAZORPAY_PAYMENT_LINK
      ) {
        const { paymentLinkUrl } = paymentMethodData.paymentMethodDetails

        if (paymentLinkUrl) {
          window.location.href = paymentLinkUrl
        }
      }

      if (
        paymentMethodData.paymentMethodId === paymentMethod.STRIPE &&
        paymentMethodData.paymentMethodDetails.clientSecret
      ) {
        sessionStorage.setItem(
          "stripeClientSecret",
          paymentMethodData.paymentMethodDetails.clientSecret
        )
        setClientSecret(paymentMethodData.paymentMethodDetails.clientSecret)
        setStripeFormOpen(true)
      }
      setIntermediateLoading(false)
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
    }
  }

  const openCheckout = async () => {
    if (paymentMethodData) {
      fetchPaymentMethod(paymentMethodData)
      return
    }

    if (!cart) throw Error("Cart not found")

    setIntermediateLoading(true)
    try {
      const res = await api.orders.checkout({
        data: {
          cartId: cart.uuid,
        },
      })
      if (res.finalAmount === 0) {
        navigate({
          pathname: routes.orders,
          search: window.location.search,
        })
      } else {
        fetchPaymentMethod(res)
      }
    } catch (e) {
      if (e instanceof APIError) handleErrors(e)
      setIntermediateLoading(false)
    }
  }

  if (juspayPaymentURL) {
    return (
      <Dialog open={Boolean(juspayPaymentURL)} fullScreen>
        <DialogContent className="flex items-center justify-center">
          <iframe
            allow="payment *;"
            className="h-screen w-full"
            src={juspayPaymentURL}
            title="Juspay"
          />
        </DialogContent>
      </Dialog>
    )
  }

  if (polling) {
    return (
      <Polling
        paymentPlanId={paymentPlanId}
        itemId={cart?.cartItems[0].productId}
      />
    )
  }

  if (isError || cart?.cartItems.length === 0) {
    return (
      <Container>
        <div className="my-3 flex flex-col justify-center">
          <Typography variant="title2" className="mb-3 text-center">
            Seems like your cart is empty!
          </Typography>

          <TalkToMentorBanner />
        </div>
      </Container>
    )
  }

  return (
    <>
      {intermediateLoading && <LoadingOverlay />}
      <Container>
        <Typography variant="title2" className="my-3">
          Payment Details
        </Typography>

        {isLoading && (
          <div className="flex items-center justify-center pt-5">
            <CircularProgress />
          </div>
        )}
        {cart && (
          <>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <StripeCheckoutForm
                  itemId={cart.cartItems[0].productId}
                  itemName={cart.cartItems[0].productName}
                  amount={cart.cartTotal}
                  currency={cart.currency}
                  promocode={cart.promocode?.code}
                  open={stripeFormOpen}
                  handleClose={() => setStripeFormOpen(false)}
                />
              </Elements>
            )}

            <div className="mb-2 flex w-full flex-col md:flex-row">
              <div className="md:me-3 md:w-1/2">
                <ImageBackgroundCard
                  background={cart.cartItems[0].image}
                  className="rounded-2xl"
                >
                  <Card padding={2}>
                    <div className="flex grow flex-col sm:flex-row sm:items-center">
                      <img
                        alt="item"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = CourseFallback
                        }}
                        src={cart.cartItems[0].image || CourseFallback}
                        className="mb-2 h-[153px] shrink-0 rounded-xl border border-surface-100 object-cover sm:mb-0 sm:me-2 sm:h-[86px]"
                      />
                      <div>
                        <Typography
                          variant="strong"
                          className="text-surface-500"
                        >
                          {cart.cartItems[0].productName}
                        </Typography>
                        <Typography
                          variant="title4"
                          className="mt-1 text-surface-500"
                        >
                          {cart.currency.symbol}
                          {formatPrice(cart.cartTotal)}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </ImageBackgroundCard>

                <AddAddress
                  onAdd={() => {
                    fetchCart()
                    setCanProceed(true)
                  }}
                  onChangeClicked={() => {
                    setCanProceed(false)
                  }}
                />
              </div>
              <div className="mb-10 mt-3 flex flex-col md:mt-0 md:w-1/2">
                {cart.promocode !== null ? (
                  <div className="flex items-center justify-between rounded-2xl border border-surface-200 bg-[#F7F7F7] px-2 py-1.25">
                    <Typography variant="strong">
                      {cart.promocode.code}
                    </Typography>
                    <Button
                      variant="text"
                      onClick={removePromocode}
                      color="critical"
                    >
                      Remove
                    </Button>
                  </div>
                ) : (
                  <Tooltip
                    disabled={canProceed}
                    title="Please add a billing address first"
                  >
                    <form onSubmit={onSubmit}>
                      <div className="flex items-center justify-between rounded-2xl border border-surface-200 bg-[#F7F7F7] px-2 py-1.25">
                        <TextField
                          error={Boolean(errors.promocode)}
                          helperText={errors.promocode?.message}
                          className="me-2"
                          placeholder="Promocode"
                          fullWidth
                          {...register("promocode")}
                          disabled={!canProceed}
                        />
                        <Button
                          variant="text"
                          type="submit"
                          disabled={!canProceed}
                        >
                          Apply
                        </Button>
                      </div>
                    </form>
                  </Tooltip>
                )}
                <div className="mt-2 rounded-2xl border border-surface-200 bg-[#F7F7F7] px-2 py-1.25">
                  {[
                    { name: "Item", amount: cart.itemTotal },
                    { name: "Discount", amount: cart.promocodeDiscount },
                    { name: "Taxes", amount: cart.totalTaxCharged },
                  ].map((item, idx) => (
                    <div
                      key={idx}
                      className="mb-2 flex items-center justify-between"
                    >
                      <Typography variant="strong">{item.name}</Typography>
                      <Typography
                        variant="strong"
                        className={clsx({
                          "text-primary-500": item.name === "Discount",
                          "text-onSurface-900": item.name !== "Discount",
                        })}
                      >
                        {item.name === "Discount" && "-"}
                        {cart.currency.symbol}
                        {formatPrice(item.amount)}
                      </Typography>
                    </div>
                  ))}
                  <Divider weight="light" color="onSurface.200" />
                  <span className="mt-1 flex items-center justify-between">
                    <span className="flex items-center">
                      <Typography variant="strong" className="me-0.5">
                        Total
                      </Typography>
                    </span>
                    <Typography variant="title2">
                      {cart.currency.symbol}
                      {formatPrice(cart.cartTotal)}
                    </Typography>
                  </span>
                </div>
                <Tooltip
                  title="Please add a billing address first"
                  disabled={canProceed}
                >
                  <div>
                    <Button
                      color="primary"
                      onClick={() => openCheckout()}
                      variant="filled"
                      fullWidth
                      className="mt-2"
                      disabled={!canProceed}
                    >
                      {cart.cartTotal === 0
                        ? "Claim"
                        : `Pay ${cart.currency.symbol}${formatPrice(
                            cart.cartTotal
                          )}`}
                    </Button>
                  </div>
                </Tooltip>
                <Typography
                  variant="smallBody"
                  className="mt-1 w-4/5 self-center text-center text-secondary-500"
                >
                  By confirming this purchase, I agree to the
                  <a
                    className="text-interactive-500"
                    href={getPlatformURL("suraasa", "/terms-of-use/")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    terms of use
                  </a>{" "}
                  and
                  <a
                    className="text-interactive-500"
                    href={getPlatformURL("suraasa", "/privacy-policy/")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    privacy policy.
                  </a>
                </Typography>
              </div>
            </div>
            {/* <BillingAddress
              billingAddress={cart.billingAddress}
              onSave={() => fetchCart()}
              open={selectAddress}
              handleClose={() => {
                setSelectAddress(false)
              }}
            /> */}
          </>
        )}
      </Container>
    </>
  )
}

export default Payment
