import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Award,
  Project,
  Publication,
  TestScore,
} from "api/resources/profile/types"
import clsx from "clsx"
import { format } from "date-fns"
import AwardDialog from "features/Profile/components/Achievements/AwardDialog"
import ProjectDialog from "features/Profile/components/Achievements/ProjectDialog"
import PublicationDialog from "features/Profile/components/Achievements/PublicationDialog"
import TestScoreDialog from "features/Profile/components/Achievements/TestScoreDialog"
import Section from "features/Profile/components/Section"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Edit, Plus } from "iconoir-react"
import React, { useContext, useMemo, useState } from "react"
import { pluralize } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import ProfileContext from "../../context"
import AwardsItem from "./AwardsItem"

const DIALOG_TYPE = {
  AWARD: "AWARD",
  TEST_SCORE: "TEST_SCORE",
  PROJECT: "PROJECT",
  PUBLICATION: "PUBLICATION",
} as const

type DialogType = keyof typeof DIALOG_TYPE

type AccordionName = "publications" | "awards" | "testScores" | "projects"

type ToggleDialogConditional<T extends DialogType> = T extends "AWARD"
  ? Award
  : T extends "TEST_SCORE"
  ? TestScore
  : T extends "PROJECT"
  ? Project
  : Publication

const Achievements = ({ className }: PropsWithClassName) => {
  const [dialogType, setDialogType] = useState<DialogType | null>(null)
  const [accordionOpen, setAccordionOpen] = useState<AccordionName[]>([])
  const [dialogData, setDialogData] = useState<
    Award | Publication | TestScore | Project
  >()

  const {
    achievements: {
      awards: { data: awards },
      projects: { data: projects },
      publications: { data: publications },
      testScores: { data: testScores },
    },
    isPublic,
  } = useContext(ProfileContext)

  const toggleDialog = <
    T extends DialogType,
    K extends ToggleDialogConditional<T>
  >(
    type: T,
    data?: K
  ) => {
    setDialogType(type)
    setDialogData(data)
  }

  const mountDialog = useMemo(() => {
    switch (dialogType) {
      case "AWARD":
        return (
          <AwardDialog
            setOpen={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.AWARD}
          />
        )
      case "PROJECT":
        return (
          <ProjectDialog
            setOpen={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.PROJECT}
          />
        )
      case "PUBLICATION":
        return (
          <PublicationDialog
            setOpen={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.PUBLICATION}
          />
        )
      case "TEST_SCORE":
        return (
          <TestScoreDialog
            handleClose={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.TEST_SCORE}
          />
        )
      default:
    }
  }, [dialogType, dialogData])

  const toggleAccordion = (item: AccordionName[]) => {
    setAccordionOpen(item)
  }

  return (
    <Section className={className}>
      <SectionHeading
        heading={
          <SectionTitle
            actionIcon={
              isPublic ? undefined : (
                <div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <IconButton>
                        <Plus className="!size-3" />
                      </IconButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={() => toggleDialog("PUBLICATION")}
                      >
                        Publication
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => toggleDialog("AWARD")}>
                        Award & Honour
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => toggleDialog("TEST_SCORE")}
                      >
                        Test Score
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => toggleDialog("PROJECT")}>
                        Project
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )
            }
            title="Achievements"
          />
        }
        xPadding={2}
      />

      <Accordion type="multiple" onValueChange={toggleAccordion}>
        {publications.length > 0 && (
          <AccordionItem value="publications" className="border-b px-1 py-2">
            <AccordionTrigger>
              <div>
                <SectionTitle
                  itemCount={publications.length}
                  title={pluralize("Publication", publications.length, {
                    skipCount: true,
                  })}
                  typographyProps={{ className: "text-onSurface-700" }}
                />
                <div
                  className={clsx(
                    "ml-2 overflow-hidden text-start transition-all duration-200 ease-out",
                    {
                      "max-h-20 opacity-100":
                        !accordionOpen.includes("publications"),
                      "max-h-0 opacity-0":
                        accordionOpen.includes("publications"),
                    }
                  )}
                  style={{
                    transitionProperty: "max-height, opacity, transform",
                  }}
                >
                  {publications.map((publication, index) => (
                    <Typography
                      className="inline"
                      key={index}
                      variant="smallBody"
                    >
                      {publication.title}
                      {index < publications.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent
              className="overflow-hidden transition-all duration-500 ease-in"
              style={{
                transform: accordionOpen.includes("publications")
                  ? "translateY(0)"
                  : "translateY(10px)",
              }}
            >
              {publications.map((publication, index) => (
                <React.Fragment key={index}>
                  <div className="relative ml-2 pr-3">
                    {!isPublic && (
                      <IconButton
                        className="absolute right-0"
                        color="black"
                        onClick={() => toggleDialog("PUBLICATION", publication)}
                      >
                        <Edit className="!size-3" />
                      </IconButton>
                    )}

                    <Typography className="mb-0.5" variant="strongSmallBody">
                      {publication.title}
                    </Typography>

                    <Typography
                      className="mb-0.25 text-onSurface-600"
                      variant="smallBody"
                    >
                      {publication.publisher}
                    </Typography>

                    {publication.publishedOn && (
                      <Typography
                        className="mb-1 text-onSurface-600"
                        variant="smallBody"
                      >
                        {format(new Date(publication.publishedOn), "MMMM yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {publication.description}
                    </Typography>

                    {publication.url && (
                      <a
                        href={publication.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-interactive-500 hover:underline"
                      >
                        View Publication
                      </a>
                    )}
                  </div>

                  {index < publications.length - 1 && (
                    <div className="my-2">
                      <Divider className="bg-onSurface-200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {awards.length > 0 && (
          <AccordionItem value="awards" className="border-b px-1 py-2">
            <AccordionTrigger>
              <div>
                <SectionTitle
                  itemCount={awards.length}
                  title={`${pluralize("Award", awards.length, {
                    skipCount: true,
                  })} and ${pluralize("Honor", awards.length, {
                    skipCount: true,
                  })}`}
                  typographyProps={{ className: "text-onSurface-700" }}
                />
                <div
                  className={clsx(
                    "ml-2 overflow-hidden text-start transition-all duration-200 ease-out",
                    {
                      "max-h-20 opacity-100": !accordionOpen.includes("awards"),
                      "max-h-0 opacity-0": accordionOpen.includes("awards"),
                    }
                  )}
                  style={{
                    transitionProperty: "max-height, opacity, transform",
                  }}
                >
                  {awards.map((award, index) => (
                    <Typography
                      className="inline"
                      key={award.id}
                      variant="smallBody"
                    >
                      {award.title}
                      {index < awards.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent
              className="overflow-hidden transition-all duration-500 ease-in"
              style={{
                transform: accordionOpen.includes("awards")
                  ? "translateY(0)"
                  : "translateY(10px)",
              }}
            >
              {awards.map((award, index) => (
                <React.Fragment key={award.id}>
                  <AwardsItem
                    data={award}
                    isPublic={isPublic}
                    onEdit={() => toggleDialog("AWARD", award)}
                  />
                  {index < awards.length - 1 && (
                    <div className="my-2">
                      <Divider className="bg-onSurface-200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {testScores.length > 0 && (
          <AccordionItem value="testScores" className="border-b px-1 py-2">
            <AccordionTrigger>
              <div>
                <SectionTitle
                  itemCount={testScores.length}
                  title={pluralize("Test Score", testScores.length, {
                    skipCount: true,
                  })}
                  typographyProps={{ className: "text-onSurface-700" }}
                />
                <div
                  className={clsx(
                    "ml-2 overflow-hidden text-start transition-all duration-200 ease-out",
                    {
                      "max-h-20 opacity-100":
                        !accordionOpen.includes("testScores"),
                      "max-h-0 opacity-0": accordionOpen.includes("testScores"),
                    }
                  )}
                  style={{
                    transitionProperty: "max-height, opacity, transform",
                  }}
                >
                  {testScores.map((score, index) => (
                    <Typography
                      className="inline"
                      key={index}
                      variant="smallBody"
                    >
                      {score.name}
                      {index < testScores.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent
              className="overflow-hidden transition-all duration-500 ease-in"
              style={{
                transform: accordionOpen.includes("testScores")
                  ? "translateY(0)"
                  : "translateY(10px)",
              }}
            >
              {testScores.map((score, index) => (
                <React.Fragment key={index}>
                  <div className="relative ml-2 pr-3">
                    {!isPublic && (
                      <IconButton
                        className="absolute right-0"
                        color="black"
                        onClick={() => toggleDialog("TEST_SCORE", score)}
                      >
                        <Edit className="!size-3" />
                      </IconButton>
                    )}

                    <Typography variant="strongSmallBody">
                      {score.name}
                    </Typography>

                    {score.score && (
                      <Typography
                        className="mb-0.25 text-onSurface-600"
                        variant="smallBody"
                      >
                        Score: {score.score}
                      </Typography>
                    )}

                    {score.testDate && (
                      <Typography
                        className="mb-1 text-onSurface-600"
                        variant="smallBody"
                      >
                        {format(new Date(score.testDate), "MMMM d, yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {score.description}
                    </Typography>

                    {(score.evidenceUrl || score.evidenceDocument) && (
                      <a
                        href={
                          (score.evidenceUrl as string) ??
                          (score.evidenceDocument as string)
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="text-interactive-500 hover:underline"
                      >
                        View Result
                      </a>
                    )}
                  </div>

                  {index < testScores.length - 1 && (
                    <div className="my-2">
                      <Divider className="bg-onSurface-200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
        {projects.length > 0 && (
          <AccordionItem value="projects" className="border-b px-1 py-2">
            <AccordionTrigger>
              <div>
                <SectionTitle
                  itemCount={projects.length}
                  title={pluralize("Project", projects.length, {
                    skipCount: true,
                  })}
                  typographyProps={{ className: "text-onSurface-700" }}
                />
                <div
                  className={clsx(
                    "ml-2 overflow-hidden text-start transition-all duration-200 ease-out",
                    {
                      "max-h-20 opacity-100":
                        !accordionOpen.includes("projects"),
                      "max-h-0 opacity-0": accordionOpen.includes("projects"),
                    }
                  )}
                  style={{
                    transitionProperty: "max-height, opacity, transform",
                  }}
                >
                  {projects.map((project, index) => (
                    <Typography
                      className="inline"
                      key={index}
                      variant="smallBody"
                    >
                      {project.title}
                      {index < projects.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent
              className="overflow-hidden transition-all duration-500 ease-in"
              style={{
                transform: accordionOpen.includes("projects")
                  ? "translateY(0)"
                  : "translateY(10px)",
              }}
            >
              {projects.map((project, index) => (
                <React.Fragment key={index}>
                  <div className="relative ml-2 pr-3">
                    {!isPublic && (
                      <IconButton
                        className="absolute right-0"
                        color="black"
                        onClick={() => toggleDialog("PROJECT", project)}
                      >
                        <Edit className="!size-3" />
                      </IconButton>
                    )}

                    <Typography variant="strongSmallBody">
                      {project.title}
                    </Typography>

                    {project.startDate && (
                      <Typography
                        className="mb-1 text-onSurface-600"
                        variant="smallBody"
                      >
                        {format(new Date(project.startDate), "MMMM yyyy")} -{" "}
                        {project.endDate
                          ? format(new Date(project.endDate), "MMMM yyyy")
                          : "Ongoing"}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {project.description}
                    </Typography>

                    {project.url && (
                      <a
                        href={project.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-interactive-500 hover:underline"
                      >
                        View Project
                      </a>
                    )}
                  </div>

                  {index < projects.length - 1 && (
                    <div className="my-2">
                      <Divider className="bg-onSurface-200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionContent>
          </AccordionItem>
        )}
      </Accordion>

      {publications.length === 0 &&
        awards.length === 0 &&
        testScores.length === 0 &&
        projects.length === 0 && (
          <Typography className="p-2 text-onSurface-400" variant="smallBody">
            {isPublic
              ? "Looks like there's nothing here."
              : "You haven’t added any Achievements. Click on + icon to add one."}
          </Typography>
        )}

      {mountDialog}
    </Section>
  )
}

export default Achievements
