import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"

import Heading from "../../Heading"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import NarrationOutput from "../GenerationOutput/NarrationOutput"
import BasicForm from "./BasicForm"
import { useNarration } from "./useNarration"
// import SelectLessonPlanSheet from "../helpers/SelectLessonPlanSheet"
// import NoLessonPlannerDialog from "../SubjectiveAssessmentGenerator/NoLessonPlannerDialog"

const toolType = ToolType.narration

const NarrationGenerator = ({
  toggleHistorySideBar,
}: {
  toggleHistorySideBar: (state: boolean) => void
}) => {
  const {
    promptDataId,
    pageLoading,
    currentResponseId,
    setCurrentResponseId,
    onBack,
    ...narrationContext
  } = useNarration()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={toolType}
          title={narrationContext.overviewData?.title}
          toggleHistorySideBar={toggleHistorySideBar}
          hideExportOutputButton={
            narrationContext.stream.isError ||
            narrationContext.isLoading ||
            currentResponseId === undefined
          }
        />
        <NarrationOutput
          {...narrationContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(toolType)} Generator`}
        subtitle="A tool that helps you enhance delivery and make your sessions more engaging. It converts your lesson plan into a narrative format."
        toggleHistorySideBar={toggleHistorySideBar}
      />
      <BasicForm {...narrationContext} />
    </>
  )
}

export default NarrationGenerator
