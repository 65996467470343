import { Typography } from "@suraasa/placebo-ui"
import {
  CircularProgress,
  Container,
  Tooltip,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ReattemptType } from "api/resources/assessments/types"
import {
  REVIEW_STATUS,
  SUBMISSION_STATUS,
  SubmissionResultEnum,
} from "api/resources/assignments/types"
import Breadcrumbs from "components/Breadcrumbs"
import Instructions from "features/Assignments/Instructions"
import PreviousSubmissionsDropdown from "features/Assignments/PreviousSubmissionsDropdown"
import { buildAssignmentTitle } from "features/Assignments/utils"
import VerificationProcess from "features/Assignments/VerificationProcess"
import { InfoCircle } from "iconoir-react"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import routes from "routes"
import { MAXIMUM_SCORE } from "utils/constants"

import MarkingCriteriaSheet from "../Assignments/Instructions/MarkingCriteriaSheet"
import ProfessorReviewCard from "./ProfessorReviewCard"
import ReattemptBanner, { ReattemptReason } from "./ReattemptBanner"
import ReattemptDialog from "./ReattemptDialog"
import Submission from "./Submission"

const AssignmentResult = () => {
  const [openReattemptDialog, setOpenReattemptDialog] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [latestSubmissionUUID, setLatestSubmissionUUID] = useState<
    string | null | undefined
  >()

  const { assignmentId, learningItemType, slug, submissionId } =
    useParams() as {
      submissionId: string
      assignmentId: string
      learningItemType: string
      slug: string
    }

  const retrievePlannerItemData = useQuery({
    queryKey: ["retrievePlannerItemData", assignmentId],
    queryFn: () =>
      api.assignments.retrieveUserPlannerItemId({
        urlParams: {
          learningItemType: learningItemType,
          learningItemSlug: slug,
          learningContentType: "assignment",
          learningContentId: assignmentId,
        },
      }),
  })

  const assignmentResponse = useQuery({
    queryKey: queries.assignments.retrieve({
      assignmentUUID: retrievePlannerItemData.data?.learningContent.uuid,
      userPlannerItemId: retrievePlannerItemData.data?.id,
    }).queryKey,
    queryFn: () =>
      api.assignments.retrieve({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          assignmentUUID: retrievePlannerItemData.data!.learningContent.uuid,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userPlannerItemId: retrievePlannerItemData.data!.id,
        },
        params: {
          fields: ["faqs", "extension_request", "reattempt_request"],
        },
      }),

    enabled: Boolean(retrievePlannerItemData.data?.learningContent),
  })

  const submissionResponse = useQuery({
    queryFn: () =>
      api.assignments.retrieveSubmission({
        urlParams: { submissionId: submissionId },
        params: { fields: "iqa_review" },
      }),
    queryKey: queries.assignments.submissionsByUUID(submissionId).queryKey,
  })

  const [showMarkingCriteria, setShowMarkingCriteria] = useState(false)
  const [showVerificationProcess, setShowVerificationProcess] = useState(false)

  const onCloseReattemptDialog = () => {
    setOpenReattemptDialog(false)
  }

  const forceReattempt =
    submissionResponse.data?.status === SUBMISSION_STATUS.rejected ||
    submissionResponse.data?.result === SubmissionResultEnum.FAILED ||
    submissionResponse.data?.review?.status === REVIEW_STATUS.rejected ||
    submissionResponse.data?.review?.status === REVIEW_STATUS.plagiarised

  const reviewData =
    submissionResponse.data?.review || submissionResponse.data?.iqaReview

  const showReattemptBanner =
    forceReattempt ||
    (reviewData &&
      reviewData.status !== REVIEW_STATUS.underReview &&
      reviewData.status !== REVIEW_STATUS.notReviewed &&
      reviewData.marks !== MAXIMUM_SCORE.assignment)

  const navigate = useNavigate()

  if (assignmentResponse.isSuccess && retrievePlannerItemData.data)
    return (
      <div>
        <ReattemptDialog
          open={openReattemptDialog}
          onClose={onCloseReattemptDialog}
          type="assignment"
          title={buildAssignmentTitle(assignmentResponse.data)}
          onSubmit={() => {
            // If reattempt type is automatic, navigate to assignment page
            if (
              assignmentResponse.data.reattemptType === ReattemptType.AUTOMATIC
            ) {
              navigate(
                routes.assignment
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .replace(":slug", slug!)
                  .replace(":learningItemType", "course")
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .replace(":assignmentId", assignmentId!),
                { replace: true }
              )
            } else assignmentResponse.refetch()
          }}
          learningContentId={assignmentId}
          userPlannerItemId={retrievePlannerItemData.data.id}
        />

        {showReattemptBanner && (
          <ReattemptBanner
            onClick={() => {
              setOpenReattemptDialog(true)
            }}
            reason={
              forceReattempt
                ? ReattemptReason.failed
                : ReattemptReason.scoreImprovement
            }
            userReattemptCounter={assignmentResponse.data.userReattemptCounter}
            reattemptRequest={assignmentResponse.data.reattemptRequest}
          />
        )}

        <Container>
          <Breadcrumbs className="my-3" skipLastItem={false} />

          <MarkingCriteriaSheet
            assignmentId={assignmentId}
            open={showMarkingCriteria}
            onClose={() => setShowMarkingCriteria(false)}
          />
          <div className="mb-2 flex flex-wrap items-center justify-between gap-2.5">
            <Typography variant="title3">
              {buildAssignmentTitle(assignmentResponse.data)}
            </Typography>
            <div>
              <PreviousSubmissionsDropdown
                userPlannerItemData={retrievePlannerItemData.data}
                currentSubmissionId={submissionId}
                onLoadSubmission={uuid => {
                  setLatestSubmissionUUID(uuid)
                }}
              />
            </div>
          </div>
          {submissionResponse.isSuccess && (
            <ProfessorReviewCard
              result={submissionResponse.data.result}
              review={submissionResponse.data.review}
              status={submissionResponse.data.status}
              iqaReview={submissionResponse.data.iqaReview}
            />
          )}
          {submissionResponse.isSuccess && (
            <div className="my-2 flex flex-col gap-2 sm:mt-1.5">
              {submissionResponse.data.iqaReview && (
                <div className="flex flex-wrap justify-between gap-2">
                  <div className="flex items-center gap-0.5">
                    <Typography
                      className="text-onSurface-600"
                      variant="smallBody"
                    >
                      Result updated after Quality Assurance
                    </Typography>
                    {!showVerificationProcess && (
                      <Tooltip
                        maxWidth="300px"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore placebo-fix
                        title={
                          <div className="text-start">
                            We perform quality assurance checks to ensure
                            authenticity, validity, and fairness in evaluation.{" "}
                            <button
                              className="underline underline-offset-4"
                              onClick={() => setShowVerificationProcess(true)}
                            >
                              Learn More
                            </button>
                            .
                          </div>
                        }
                      >
                        <InfoCircle
                          className="text-onSurface-600"
                          fontSize={15}
                        />
                      </Tooltip>
                    )}
                  </div>
                  {/* <Button variant="text" nudge="left" className="!-ml-1">
                  Compare with Previous Review
                </Button> */}
                </div>
              )}
            </div>
          )}
          <div className="mb-3">
            <Typography variant="title3" className="mb-2">
              Your Submission
            </Typography>
            {submissionResponse.isSuccess && (
              <Submission
                references={submissionResponse.data.references}
                citations={submissionResponse.data.citations}
                content={submissionResponse.data.submission}
                attachments={submissionResponse.data.attachments}
              />
            )}
            {submissionResponse.isLoading && (
              <div className="flex justify-center p-3">
                <CircularProgress />
              </div>
            )}
            {submissionResponse.isError && (
              <Submission
                content={"Some error occurred while loading submission"}
                attachments={[]}
              />
            )}
          </div>
          <Instructions {...assignmentResponse.data} />
        </Container>
        <VerificationProcess
          open={showVerificationProcess}
          onClose={() => setShowVerificationProcess(false)}
        />
      </div>
    )

  return (
    <Container>
      <div className="flex items-center justify-center p-4">
        <CircularProgress />
      </div>
    </Container>
  )
}

export default AssignmentResult
