import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { AssignmentList } from "api/resources/assignments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import { getRestriction } from "features/AccessManagement/CheckRestrictions"
import ReattemptCount from "features/ReattemptCount"
import { useMemo } from "react"
import { PropsWithClassName } from "utils/types"

import { useCourseDetails } from ".."
import GroupedAssignmentCard from "./GroupedAssignments"
import TutorialVideo from "./TutorialVideo"

const groupAssignmentsByName = (assignmentList: AssignmentList[]) => {
  const groupByName = Object.groupBy(
    assignmentList,
    item => item.groupName ?? item.shortTitle
  )
  const allGroups = Object.entries(groupByName).map(([groupName, items]) => ({
    groupName,
    assignments: items || [],
  }))
  return allGroups
}

const CourseAssignments = ({ className }: PropsWithClassName) => {
  // const [openReattemptDialog, setOpenReattemptDialog] = useState<{
  //   title: string
  //   userReattemptCounter?: UserReattemptCounterType | null
  // } | null>(null)
  const { course } = useCourseDetails()

  const { data, isLoading, isError } = useQuery({
    queryKey: queries.assignments.list("course", course.id).queryKey,
    queryFn: () =>
      api.assignments.list({
        urlParams: {
          learningItemType: "course",
          learningItemId: course.id,
        },
      }),
  })

  const groupedAssignments = useMemo(
    () => groupAssignmentsByName(data || []),
    [data]
  )

  const isRestrictedInAnyWay = data?.some(x => getRestriction(x))

  const reAttemptRequestsConfig = data?.find(
    item => item.reattemptType === "on_request"
  )

  return (
    <>
      <div className={clsx(className, "pb-2")}>
        <Typography className="mb-2" variant="title3">
          Skill Evidences (Assignments)
        </Typography>

        {!isRestrictedInAnyWay && (
          <TutorialVideo courseSlug={course.slug} className="mb-2" />
        )}
        {(isError || isLoading) && (
          <Fallback
            data={{
              image: HandHoldingPen,
              title: "Something went wrong!",
              description: "Please contact care@suraasa.com",
            }}
            className="my-9 sm:my-20 md:my-18"
            hasError={isError}
            isLoading={isLoading}
          />
        )}

        <ReattemptCount
          userReattemptCounter={reAttemptRequestsConfig?.userReattemptCounter}
          className="mb-2"
        />

        {groupedAssignments.length > 0 ? (
          groupedAssignments.map((item, index) => (
            <div key={index}>
              <GroupedAssignmentCard
                assignments={item.assignments}
                courseId={course.id}
                groupName={item.groupName}
              />
            </div>
          ))
        ) : (
          <Fallback
            data={{
              image: HandHoldingPen,
              title: "No skill evidences (assignments) found!",
              description:
                "Make progress in your courses to unlock skill evidences (assignments)!",
            }}
            className="my-9 sm:my-20 md:my-18"
          />
        )}
      </div>
    </>
  )
}

export default CourseAssignments
