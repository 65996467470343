import { getAuthInfo } from "utils/helpers"

const MEASUREMENT_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID

export interface CustomWindow extends Window {
  gtag?: (...args: any[]) => void
}
declare let window: CustomWindow

export const GA_EVENTS = {
  // ITO
  ito_assessment_started: "ito_assessment_started",
  ito_assessment_submitted: "ito_assessment_submitted",
  ito_assessment_violation_triggered: "ito_assessment_violation_triggered",
} as const

export const GA_INTENT = {}

const gtag = (...params: any) => {
  if (typeof window !== "undefined" && window.gtag) {
    // if (MEASUREMENT_ID) console.info("> Logging event to GA", params)
    return window.gtag(...params)
  }
}

export const GA = {
  trackEvent(name: ValueOf<typeof GA_EVENTS>, payload?: object) {
    gtag("event", name, { ...payload, user_id: getAuthInfo()?.user.uuid })
  },
  setUserId(userId: string) {
    gtag("config", MEASUREMENT_ID, { user_id: userId })
  },
  init() {
    if (!MEASUREMENT_ID) return

    const gtagScript = document.createElement("script")
    gtagScript.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`
    )
    gtagScript.setAttribute("async", "true")
    document.head.appendChild(gtagScript)

    const script2 = document.createElement("script")
    script2.innerHTML = `
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "${MEASUREMENT_ID}")
    `
    document.head.appendChild(script2)
  },
  trackElement(trackingId: string, meta: TrackingMeta) {
    return {
      "data-trackingid": trackingId,
      ...(meta?.source && { "data-source": meta.source }),
      ...(meta?.purpose && { "data-purpose": meta.purpose }),
      ...(meta?.feature && { "data-feature": meta.feature }),
    }
  },
}

const TRACKING_DATA = {
  source: {
    assessment: "assessment",
    ito_dashboard: "ito_dashboard",
    dashboard: "dashboard",
    masterclass_side_popup: "masterclass_side_popup",
    masterclass_1_day_popup: "masterclass_1_day_popup",
  },
  purpose: {
    attemptAssessment: "attempt_assessment",
    join_masterclass: "join_masterclass",
    register_for_masterclass: "register_for_masterclass",
  },
  feature: {
    ito: "ito",
    masterclass: "masterclass",
  },
} as const

type ValueOf<T> = T[keyof T]

type TrackingMeta = {
  source?: ValueOf<(typeof TRACKING_DATA)["source"]>
  purpose: ValueOf<(typeof TRACKING_DATA)["purpose"]>
  feature: ValueOf<(typeof TRACKING_DATA)["feature"]>
}
