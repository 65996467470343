import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import NotificationBell from "assets/Fallback/notification-bell.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import React from "react"

import TabBar from "../../components/TabBar"
import { useNotifications } from "./hooks/useNotifications"
import styles from "./Notifications.module.css"
import NotificationsList from "./NotificationsList"

const Notifications = () => {
  const {
    tabs,
    notifications,
    activeTab,
    changeTab,
    markAllAsRead,
    pageOverview,
    getDescription,
    markAllAsReadStatus,
  } = useNotifications()

  const { isLoading: pageLoading, isError: pageError } = pageOverview
  const { isLoading: isBtnLoading } = markAllAsReadStatus
  const {
    data: notificationList,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
  } = notifications

  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  if (pageLoading || pageError) {
    return (
      <Fallback
        data={{
          image: NotificationBell,
          title: "No Notifications!",
        }}
        isLoading={pageLoading}
        hasError={pageError}
      />
    )
  }

  return (
    <div>
      <div className="bg-white">
        <Container
          fullWidth={xsDown}
          className={clsx({
            [styles.topBar]: mdUp,
          })}
        >
          <div className="px-2 sm:px-0">
            <div className="my-2 flex items-end justify-between">
              <Typography variant="title2">Notifications</Typography>
              <Button
                variant="text"
                color="black"
                loading={isBtnLoading}
                onClick={markAllAsRead}
              >
                Mark all as read
              </Button>
            </div>
          </div>
          <div
            className={clsx({
              [styles.notificationTabBar]: xsDown,
            })}
          >
            <TabBar
              className="pt-1"
              activeTab={activeTab}
              tabs={tabs}
              onChange={changeTab}
            />
          </div>
        </Container>
      </div>
      <Divider className={clsx(styles.divider)} />
      <Container fullWidth={xsDown} className="pb-8 md:pb-2">
        <div className="flex flex-col">
          {(isError ||
            notificationList?.pages[0].data.notifications.length == 0) && (
            <Fallback
              data={{
                image: NotificationBell,
                title: "No Notifications!",
                description: getDescription(),
              }}
              className="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 px-2 text-center sm:w-fit sm:px-0"
              hasError={isError}
            />
          )}

          {notificationList && (
            <NotificationsList
              data={notificationList}
              loading={isFetching}
              hasNextPage={hasNextPage}
              actionFunc={fetchNextPage}
              activeTabName={activeTab.name}
            />
          )}

          {isFetching && (
            <div className="text-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Notifications
