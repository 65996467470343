import { toast } from "@suraasa/placebo-ui"
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  HandoutForm,
  HandoutGenerateResponse,
  HandoutPromptData,
  PreferredPromptData,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import { getToolName, isNumberArray } from "features/AItools/helper"
import {
  StreamFetchProps,
  StreamQuery,
  StreamResponseEnum,
  useStreamQuery,
} from "features/AItools/hooks/useStreamQuery"
import { useEffect, useRef, useState } from "react"
import { flushSync } from "react-dom"
import { UseFormSetError } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { mapErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

export type HandoutReturnType = {
  currentResponseId: number | null
  setCurrentResponseId: (id: number | null) => void
  promptDataId: string | number | null
  setPromptDataId: React.Dispatch<React.SetStateAction<string | number | null>>
  responseIds: number[] | null
  setResponseIds: React.Dispatch<React.SetStateAction<number[] | null>>
  overviewData: HandoutPromptData | undefined

  userVote: UseMutationResult<
    never,
    unknown,
    {
      reaction: boolean | undefined | null
      id?: number | null
    },
    unknown
  >
  output: string | undefined
  isLoading: boolean
  stream: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }

  regenerateStream: StreamQuery
  generateTool: UseMutationResult<
    APIResponse<HandoutGenerateResponse>,
    any,
    {
      data: HandoutForm
      setError: UseFormSetError<HandoutForm>
    },
    unknown
  >
  regenerateResponse: UseMutationResult<
    APIResponse<{
      id: number
    }>,
    unknown,
    {
      reason: string
      id?: number | null
      onSuccess?: (id: number) => void
    },
    unknown
  >

  isPositiveResponse: boolean | null | undefined
  pageLoading: boolean
  resetState: () => void
  onBack: () => void
  handleCancelApi: () => void
  finalizedOutputs: {
    id: number
    output: string
    isPositiveResponse: boolean | null | undefined
  }[]
  formData: HandoutForm | null
  preferredPromptData: PreferredPromptData | null
}

export const useHandout = (): HandoutReturnType => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [overviewData, setOverviewData] = useState<
    HandoutPromptData | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)
  const [isPositiveResponse, setIsPositiveResponse] = useState<
    boolean | null | undefined
  >()
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const [promptDataId, setPromptDataId] = useState<string | number | null>(id)
  const [responseIds, setResponseIds] = useState<number[] | null>(null)

  const [output, setOutput] = useState<string>("")
  const [pageLoading, setPageLoading] = useState(false)
  const [formData, setFormData] = useState<HandoutForm | null>(null)
  const currentValue = useRef("")
  const finalizedOutputs = useRef<
    {
      id: number
      output: string
      isPositiveResponse: boolean | null | undefined
    }[]
  >([])

  const resetState = () => {
    setOutput("")
    setPromptDataId(null)
    setCurrentResponseId(null)
    setIsPositiveResponse(undefined)
    setOverviewData(undefined)
    setResponseIds(null)
  }

  const handleCancelApi = () => {
    if (streamOutput.isLoading) streamOutput.handleCancel()
    if (regenerateStream.isLoading) regenerateStream.handleCancel()
  }
  const onBack = () => {
    navigate(routes.aiTools.handout, { replace: true })
    resetState()
    handleCancelApi()
  }

  // This flow is for when user clicks on history item
  // If that is the case then we just fetch the overview and the latest generated output
  useEffect(() => {
    if (id) {
      setPageLoading(true)
      api.aiTools.handout
        .getPromptData({
          urlParams: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: id!,
          },
        })
        .then(async data => {
          setPromptDataId(id)
          const { responseIds } = data

          if (responseIds && !isNumberArray(responseIds)) {
            const [latestHandout] = responseIds
            const [latestResponseId] = latestHandout.responseIds
            setCurrentResponseId(latestResponseId)
            setResponseIds(latestHandout.responseIds)
            await getContent(latestResponseId)
          }

          setOverviewData(data)
          setPageLoading(false)
        })
        .catch(error => {
          console.error("Error fetching prompt data:", error)
          setPageLoading(false)
        })
    }
  }, [id])

  const overviewAPI = async (id: string | number | null) => {
    if (!id) {
      throw new Error("Prompt id missing from overview api")
    }
    const res = await api.aiTools.handout.getPromptData({
      urlParams: {
        id: id,
      },
    })
    if (res instanceof APIError) {
      toast.error("Prompt failed to generate")
      return
    }

    const { responseIds } = res

    if (responseIds && !isNumberArray(responseIds)) {
      const [latestHandout] = responseIds
      const [latestResponseId] = latestHandout.responseIds

      setCurrentResponseId(latestResponseId)
      setResponseIds(latestHandout.responseIds)
    }

    setOverviewData(res)
  }

  const generateTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: HandoutForm
      setError: UseFormSetError<HandoutForm>
    }) => {
      setFormData(data)
      return api.aiTools.handout.generate({ data })
    },
    onSuccess: async res => {
      const { responseId, id } = res

      flushSync(() => {
        setCurrentResponseId(responseId)
        setPromptDataId(id)
        setOverviewData({
          id: res.id,
          bloomTaxonomyLevel: res.bloomTaxonomyLevel,
          subject: res.subject,
          grade: res.grade,
          duration: res.duration,
          topic: res.topic,
          country: res.country,
          curriculum: res.curriculum,
          generatedWithLessonPlan: false,
          lessonPlanPromptId: null,
          lessonPlanResponseIds: null,
          groupId: null,
          title: `New ${getToolName(ToolType.handout)}`,
          responseIds: [
            {
              id: res.responseId,
              lessonPlanResponseId: undefined,
              responseIds: [res.responseId],
            },
          ],
        })
      })

      await streamOutput.refetchAsync({
        currentResponseId: responseId,
      })

      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.handout).queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["bloomTaxonomyLevel"],
          ["country"],
          ["curriculum"],
          ["subject"],
          ["grade"],
          ["topic"],
          ["duration"],
          ["lessonPlanObjectives"],
          ["title"],
        ])
      }
    },
  })

  const streamOutput = useStreamQuery({
    responseType: StreamResponseEnum.generate,
    toolType: ToolType.handout,
    onError: () => {
      toast.error("Generation Failed")
    },
    onSuccess(props) {
      if (props?.currentResponseId) {
        finalizedOutputs.current.push({
          id: props.currentResponseId,
          output: currentValue.current,
          isPositiveResponse: null,
        })
      }
      currentValue.current = ""
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
      currentValue.current += chunks
    },
  })

  const regenerateStream = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: ToolType.handout,
    onSuccess: props => {
      toast.success("Re-generated Successfully")
      if (props?.currentResponseId) {
        finalizedOutputs.current.push({
          id: props.currentResponseId,
          output: currentValue.current,
          isPositiveResponse: null,
        })
      }
      currentValue.current = ""
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
      currentValue.current += chunks
    },
  })

  const userVote = useMutation({
    mutationFn: async ({
      reaction,
      id,
    }: {
      reaction: boolean | undefined | null
      id?: number | null
    }) => {
      const eventName = reaction
        ? "ai_tool_output_upvoted"
        : "ai_tool_output_downvoted"
      const toolType = ToolType.handout

      trackingService.trackEvent(eventName, {
        tool_type: toolType,
      })

      return api.aiTools.handout.updateData({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { id: id || currentResponseId!, type: toolType },
        data: {
          isPositiveResponse: reaction,
        },
      })
    },
  })

  const getContent = async (id: number) => {
    setIsLoading(true)
    const res = await api.aiTools.handout.retrieveContent({
      urlParams: {
        id: id,
      },
    })
    if (res instanceof APIError) {
      toast.error(res.message || "Something went wrong")
      setIsLoading(false)
      return
    }

    const findCurrentIndex = finalizedOutputs.current.findIndex(
      item => item.id === res.id
    )
    if (findCurrentIndex === -1) {
      finalizedOutputs.current.push({
        id,
        output: res.output,
        isPositiveResponse: res.isPositiveResponse,
      })
    } else {
      finalizedOutputs.current[findCurrentIndex].output = res.output
      finalizedOutputs.current[findCurrentIndex].isPositiveResponse =
        res.isPositiveResponse
    }

    setIsPositiveResponse(res.isPositiveResponse)
    setOutput(res.output)
    setIsLoading(false)
  }

  const regenerateResponse = useMutation({
    mutationFn: ({
      reason,
      id,
    }: {
      reason: string
      id?: number | null
      onSuccess?: (id: number) => void
    }) =>
      api.aiTools.handout.regenerateResponse({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: id || currentResponseId!,
        },
        data: {
          regenerateInstruction: reason,
        },
      }),
    onSuccess: async (res, { reason, onSuccess: onRegenSuccess }) => {
      flushSync(() => {
        setCurrentResponseId(res.id)
        setOutput("")
        if (onRegenSuccess) onRegenSuccess(res.id)
      })

      await regenerateStream.refetchAsync({
        currentResponseId: res.id,
        regenReason: reason,
      })
      await overviewAPI(id || promptDataId)
    },
    onError: err => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      } else {
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      }
    },
  })

  const preferredPromptData = useQuery({
    queryFn: () => api.aiTools.retrievePreferredPromptData(),
    queryKey: ["preferredPromptData"],
  })

  return {
    promptDataId,
    generateTool,
    setPromptDataId,
    currentResponseId,
    setCurrentResponseId: id => {
      setCurrentResponseId(id)
      if (id) {
        getContent(id)
      }
    },
    responseIds,
    setResponseIds,
    stream: {
      ...streamOutput,
      refetchAsync: ({ currentResponseId: id }) =>
        streamOutput.refetchAsync({
          currentResponseId: id,
        }),
    },

    regenerateResponse,
    overviewData,

    userVote,
    regenerateStream,
    output,
    isLoading:
      isLoading || regenerateStream.isLoading || streamOutput.isLoading,
    isPositiveResponse,
    finalizedOutputs: finalizedOutputs.current,
    pageLoading,
    resetState,
    onBack,
    handleCancelApi,
    formData,
    preferredPromptData: preferredPromptData.data ?? null,
  }
}
