import React from "react"
import {
  browserName,
  BrowserTypes as DefaultBrowsers,
  osName,
  OsTypes as OSes,
} from "react-device-detect"

const UpdateBrowserTypes = {
  SamsungInternet: "Samsung Internet",
}

type Browsers = {
  SamsungInternet: string
} & typeof UpdateBrowserTypes

const Browsers = { ...DefaultBrowsers, ...UpdateBrowserTypes }

type BrowserTypes = keyof typeof Browsers
type OsTypes = keyof typeof OSes

// We need to make some of them mandatory so we can use a default
type Data<T> = {
  [K in BrowserTypes]?: {
    [K in OsTypes]?: T
  }
}

type Props<T> = {
  data: Data<T>
  render: (asset: T) => JSX.Element
}

function getKeyByValue(object: Record<string, any>, value: any) {
  return Object.keys(object).find(key => object[key] === value)
}

function BrowserSpecificAsset<T>({ data, render }: Props<T>) {
  const getAsset = () => {
    if (data["Chrome"]?.["Windows"] === undefined) {
      throw new Error("Specifying a Chrome Windows key is mandatory")
    }

    const browserList = Object.keys(data).map(
      key => Browsers[key as BrowserTypes]
    )

    if (browserList.includes(browserName)) {
      const osList = Object.keys(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data[getKeyByValue(Browsers, browserName) as BrowserTypes]!
      ).map(key => OSes[key as OsTypes])

      if (osList.includes(osName)) {
        const asset =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data[getKeyByValue(Browsers, browserName) as BrowserTypes]![
            getKeyByValue(OSes, osName) as OsTypes
          ]
        if (!asset) return data["Chrome"]["Windows"]

        return asset
      }
    }

    return data["Chrome"]["Windows"]
  }

  return <>{render(getAsset())}</>
}

export default BrowserSpecificAsset
