import { useLayoutEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useSuraasaPlusContext } from "../context/SuraasaPlusContext"

export const usePlusFeature = ({
  defaultValue = false,
  ensurePlusAccess = true,
}) => {
  const [enablePlus, setEnablePlus] = useState(defaultValue)

  const { ensurePlusAccess: ensureAccess, isActive } = useSuraasaPlusContext()

  const [searchParams] = useSearchParams()

  useLayoutEffect(() => {
    if (isActive) {
      setEnablePlus(true)
    }
  }, [isActive])

  useLayoutEffect(() => {
    if (
      searchParams.get("model") === "pro" ||
      searchParams.get("model") === "plus"
    ) {
      if (ensurePlusAccess) {
        ensureAccess(async () => {
          setEnablePlus(true)
        })
      } else {
        setEnablePlus(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ensurePlusAccess])

  return [enablePlus, setEnablePlus] as const
}
