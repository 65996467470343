import { Typography } from "@suraasa/placebo-ui"
import { Theme } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { CheckCircle, XmarkCircle } from "iconoir-react"
import { createUseStyles } from "react-jss"

import FaceDetectionSample from "./assets/face-detection-feature-sample.png"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: "white",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },
  subContainer: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  videoContainer: {
    maxWidth: 194,
    maxHeight: 129,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none",
      maxHeight: "none",
    },
  },
  divider: {
    width: 2,
    height: "auto",
    backgroundColor: "#E2E8F0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 2,
      backgroundColor: "#E2E8F0",
    },
  },
  violationWrapper: {
    backgroundColor: theme.colors.warning[50],
    border: `1px solid ${theme.colors.warning[100]}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 4,
  },
}))

const violations = [
  "Turning off your camera",
  "Facing away from the camera",
  "Switching browser tab",
  "Switching browser window",
]
const ProctoringDuringAssessment = ({
  isOlympiad,
  hideHeading = false,
}: {
  maxWarningCount: number
  isOlympiad?: boolean
  hideHeading?: boolean
}) => {
  const classes = useStyles()

  return (
    <div>
      {!hideHeading && (
        <Typography className="mb-2" variant="title2">
          Proctoring During the {isOlympiad ? "Olympiad" : "Assessment"}
        </Typography>
      )}
      <div className="flex flex-col justify-between gap-2 sm:flex-row sm:gap-6">
        <div className={classes.subContainer}>
          <Typography variant="title3" className="mb-2">
            Things NOT to do:
          </Typography>
          {violations.map(violation => (
            <div key={violation} className={classes.violationWrapper}>
              {violation}
            </div>
          ))}
        </div>

        <div className={classes.divider} />

        <div className={classes.subContainer}>
          {/* <OffenceProgress
            totalWarnings={Math.floor(maxWarningCount / 3)}
            warningThreshold={maxWarningCount}
          /> */}
          <Typography>
            You will be given a warning at each violation. Performing any of the
            actions listed will result in an increment to the violation count.
            {"\n"}
            {"\n"}
            If you exceed the maximum violation count, your{" "}
            {isOlympiad ? "Olympiad" : "Assessment"} will be auto-submitted.
          </Typography>
        </div>
      </div>

      <div className={clsx("mt-4")}>
        <Typography variant="title3" className="mb-2">
          Face Detection
        </Typography>
        <Typography className="mb-2">
          To maintain the integrity of the olympiad and ensure fairness, the
          olympiad will be using face detection technology. This will help
          verify your identity and monitor your environment.
        </Typography>
        <Typography className="mb-2">
          Please ensure the following before getting started with the olympiad:
        </Typography>

        <ul style={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <li>
            Sit in a well-lit area where your face is clearly visible at all
            times.
          </li>
          <li>
            Choose a location with a simple, static background, without any
            people or excessive movement.
          </li>
          <li>Avoid covering your face with masks, hats, or other items.</li>
        </ul>

        <div className="mt-4 grid grid-cols-2 gap-5">
          <div className="col-span-2 lg:col-span-1">
            <FaceDetectionImage type="good" />
          </div>
          <div className="col-span-2 lg:col-span-1">
            <FaceDetectionImage type="bad" />
          </div>
        </div>
      </div>
    </div>
  )
}

const FaceDetectionImage = ({ type }: { type: "good" | "bad" }) => {
  const Icon = type === "bad" ? XmarkCircle : CheckCircle

  return (
    <div className="relative">
      <img src={FaceDetectionSample} alt="good" className="w-full rounded-xl" />
      {type === "bad" && (
        <div className="absolute left-0 top-0 size-full rounded-xl bg-[#050124] opacity-60" />
      )}

      <span className="absolute right-1 top-1 flex size-6 items-center justify-center rounded-full border-[3px] border-critical-500 md:right-4 md:top-4">
        <span className="size-3 rounded-full bg-critical-500"></span>
      </span>

      <span
        className={clsx(
          "absolute bottom-0 right-0 rounded-full md:-bottom-2 md:-right-2",
          {
            "bg-success-300": type === "good",
            "bg-critical-300": type === "bad",
          }
        )}
      >
        <Icon color="white" className="size-5 md:size-10" />
      </span>
    </div>
  )
}

export default ProctoringDuringAssessment
