import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import {
  AssignmentList,
  SUBMISSION_STATUS,
  SubmissionResultEnum,
} from "api/resources/assignments/types"
import clsx from "clsx"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import {
  buildAssignmentTitle,
  getActionConfig,
} from "features/Assignments/utils"
import { Lock } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { formatDate } from "utils/helpers"

import styles from "./Assignments.module.css"
import { checkAssignmentsNotStarted } from "./utils"
import {
  ViewAllAssignmentCard,
  ViewAllAssignmentSheet,
} from "./ViewAllAssignments"

const getDueDate = (item: AssignmentList) => {
  if (!item.dueDate) return null

  const date = (
    <Typography variant="smallBody" className="text-onSurface-500">
      Due by {formatDate(item.dueDate, "d MMM y, h:mm a")}
    </Typography>
  )

  // Show due date only in case of due, overdue, saved_for_later, rejected and failed
  if (item.result === SubmissionResultEnum.FAILED) return date
  switch (item.submissionStatus) {
    case null:
    case undefined:
    case SUBMISSION_STATUS.rejected:
    case SUBMISSION_STATUS.plagiarised:
    case SUBMISSION_STATUS.savedForLater:
      return date
  }

  return null
}

const getButtonAction = (
  item: AssignmentList,
  extras: {
    slug: string
    parentSlug: string
    canAttempt: boolean
    isDraft: boolean | undefined
  }
) => {
  const state = {
    slug: extras.slug,
    parentSlug: extras.parentSlug,
  }

  if (extras.canAttempt) {
    const path = routes.assignment
      .replace(":slug", extras.slug)
      .replace(":learningItemType", "course")
      .replace(":assignmentId", item.id.toString())

    const url = new URL(path, window.location.origin)
    if (extras.isDraft && item.submissionUuid)
      url.searchParams.set("submissionId", item.submissionUuid)

    return (
      <Button
        variant="text"
        component={Link}
        to={url.pathname + url.search}
        state={state}
      >
        View
      </Button>
    )
  }

  if (item.submissionUuid)
    return (
      <Button
        variant="text"
        component={Link}
        to={routes.submission
          .replace(":slug", extras.slug)
          .replace(":learningItemType", "course")
          .replace(":assignmentId", item.id.toString())
          .replace(":submissionId", item.submissionUuid)}
        state={state}
      >
        View
      </Button>
    )

  return (
    <Button
      component={Link}
      to={routes.assignment
        .replace(":slug", extras.slug)
        .replace(":learningItemType", "course")
        .replace(":assignmentId", item.id.toString())}
      state={state}
    >
      View
    </Button>
  )
}

const GroupedAssignmentCard = ({
  assignments,
  groupName,
  courseId,
}: {
  assignments: AssignmentList[]
  groupName: string
  courseId: number
}) => {
  const theme = useTheme<Theme>()

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const isDebugMode = localStorage.getItem("assignmentListDebug") === "true"

  const [sheetOpen, setSheetOpen] = useState(false)

  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug: string
  }

  const [selectedAssignmentId, setSelectedAssignmentId] = useState<
    number | null
  >(null)

  return (
    <>
      {sheetOpen && (
        <ViewAllAssignmentSheet
          handleClose={() => {
            setSelectedAssignmentId(null)
            setSheetOpen(false)
          }}
          open={sheetOpen}
          courseId={courseId}
          groupName={groupName}
          selectedAssignmentId={selectedAssignmentId}
          assignments={assignments}
        />
      )}
      <div className="mt-2">
        <Typography variant="title4" className="mb-1 text-onSurface-800">
          {groupName}
        </Typography>

        {assignments.length > 1 && (
          <ViewAllAssignmentCard
            assignmentCount={assignments.length}
            onClick={() => {
              setSheetOpen(true)
            }}
          />
        )}
        <ItemList>
          {assignments.map(assignment => {
            const config = getActionConfig(assignment)
            const isLocked = assignment.dueDate === null
            return (
              <ItemRow
                key={assignment.uuid}
                className={clsx("relative", {
                  "bg-[linear-gradient(270deg,#FBF0F0_0%,rgba(251,240,240,0)_39.36%)]":
                    config.isOverdue,
                  [styles.draft]: config.isDraft,
                })}
                infoSlot={
                  <div className="grow">
                    {isDebugMode && (
                      <pre>{JSON.stringify(assignment, null, 2)}</pre>
                    )}
                    {config.isOverdue && (
                      <div
                        className={clsx(
                          "absolute left-0 top-0 h-full w-0.5 bg-critical-500"
                        )}
                      />
                    )}
                    {config.isDraft && (
                      <Typography
                        variant="smallBody"
                        style={{ fontSize: 12 }}
                        className="mb-0.5 text-onSurface-500"
                      >
                        Continue Writing
                      </Typography>
                    )}
                    <TruncatedText
                      maxLength={60}
                      variant="strong"
                      className="mb-0.5"
                      hideActionButton
                    >
                      {buildAssignmentTitle(assignment)}
                    </TruncatedText>

                    <div className="flex items-center gap-1">
                      {config.tag}
                      {getDueDate(assignment)}
                    </div>
                  </div>
                }
                actionSlot={
                  <CheckRestrictions
                    accessStatus={assignment.accessStatus}
                    render={({ isRestricted }) => {
                      if (isLocked || isRestricted) return <Lock />

                      return (
                        <span className={clsx({ "self-end": isXs })}>
                          {assignments.length > 1 &&
                          checkAssignmentsNotStarted(assignments) ? (
                            <Button
                              label="View"
                              variant="text"
                              onClick={() => {
                                setSheetOpen(true)
                                setSelectedAssignmentId(assignment.id)
                              }}
                            />
                          ) : (
                            getButtonAction(assignment, {
                              slug,
                              parentSlug,
                              ...config,
                            })
                          )}
                        </span>
                      )
                    }}
                  />
                }
              />
            )
          })}
        </ItemList>
      </div>
    </>
  )
}

export default GroupedAssignmentCard
