import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import AddCertification from "features/Profile/components/Academics/Certifications/EditDialog"
import AddQualification from "features/Profile/components/Academics/Qualifications/EditDialog"
import AddLanguageDialog from "features/Profile/components/Languages/LanguageDialog"
import AddWorkExperience from "features/Profile/components/WorkExperience/EditDialog"
import { useState } from "react"

import AddAwardDialog from "../../Achievements/AwardDialog"
import AddProjectDialog from "../../Achievements/ProjectDialog"
import AddPublicationDialog from "../../Achievements/PublicationDialog"
import AddTestScoreDialog from "../../Achievements/TestScoreDialog"
import AddInterestsDialog from "../../Interests/InterestsDialog"
import AddSubjectsDialog from "../../Subjects/SubjectsDialog"
import AddWorkLocationDialog from "../../WorkLocationInterests/WorkLocationDialog"

enum DialogEnum {
  QUALIFICATION = "qualification",
  CERTIFICATION = "certification",
  WORK_EXPERIENCE = "work_experience",
  TEST_SCORE = "test_score",
  AWARD = "award",
  PROJECT = "project",
  PUBLICATION = "publication",
  LANGUAGE = "language",
  SUBJECT = "subject",
  WORK_LOCATION_INTEREST = "work_location_interest",
  HOBBIES = "hobbies",
}
enum AccordionEnum {
  CORE = "Core",
  ACHIEVEMENTS = "Achievements",
  ADDITIONAL_DETAILS = "Additional Details",
}
const data = [
  {
    title: AccordionEnum.CORE,
    subTitle:
      "Comprehensive overview of professional background, encompassing work experience, educational qualifications, and certifications.",
    actions: [
      {
        label: "Add Qualification",
        value: DialogEnum.QUALIFICATION,
      },
      {
        label: "Add Certification",
        value: DialogEnum.CERTIFICATION,
      },
      {
        label: "Add Work Experience",
        value: DialogEnum.WORK_EXPERIENCE,
      },
    ],
  },
  {
    title: AccordionEnum.ACHIEVEMENTS,
    subTitle:
      "Highlights of individual accomplishments, featuring test scores, awards, project contributions, and publications.",
    actions: [
      {
        label: "Add Test Score",
        value: DialogEnum.TEST_SCORE,
      },
      {
        label: "Add Award",
        value: DialogEnum.AWARD,
      },
      {
        label: "Add Project",
        value: DialogEnum.PROJECT,
      },
      {
        label: "Add Publication",
        value: DialogEnum.PUBLICATION,
      },
    ],
  },
  {
    title: AccordionEnum.ADDITIONAL_DETAILS,
    subTitle:
      "Supplementary information providing insight into language proficiency, subject expertise, preferred work locations, and personal interests and hobbies.",
    actions: [
      {
        label: "Add Language",
        value: DialogEnum.LANGUAGE,
      },
      {
        label: "Add Subject",
        value: DialogEnum.SUBJECT,
      },
      {
        label: "Add Work Location Interest",
        value: DialogEnum.WORK_LOCATION_INTEREST,
      },
      {
        label: "Add Interest and Hobby",
        value: DialogEnum.HOBBIES,
      },
    ],
  },
]

const AddProfileSectionDialog = ({
  open,
  onClose,
}: {
  open?: boolean
  onClose: () => void
}) => {
  const [openDialog, setOpenDialog] = useState<DialogEnum | null>(null)
  const [openAccordion, setOpenAccordion] = useState<AccordionEnum>(
    AccordionEnum.CORE
  )

  const toggleSingleExpanded = (item: AccordionEnum) => {
    setOpenAccordion(item)
  }

  return (
    <>
      {/* Core */}
      <AddQualification
        id={null}
        open={openDialog === DialogEnum.QUALIFICATION}
        setOpen={() => {
          setOpenDialog(null)
        }}
      />
      <AddCertification
        id={null}
        open={openDialog === DialogEnum.CERTIFICATION}
        setOpen={() => {
          setOpenDialog(null)
        }}
      />
      <AddWorkExperience
        onUpdate={() => {}}
        id={null}
        open={openDialog === DialogEnum.WORK_EXPERIENCE}
        handleClose={() => {
          setOpenDialog(null)
        }}
      />
      {/* Achievements */}
      <AddAwardDialog
        setOpen={() => setOpenDialog(null)}
        open={openDialog === DialogEnum.AWARD}
      />
      <AddProjectDialog
        setOpen={() => setOpenDialog(null)}
        open={openDialog === DialogEnum.PROJECT}
      />
      <AddPublicationDialog
        setOpen={() => setOpenDialog(null)}
        open={openDialog === DialogEnum.PUBLICATION}
      />
      <AddTestScoreDialog
        handleClose={() => setOpenDialog(null)}
        open={openDialog === DialogEnum.TEST_SCORE}
      />
      {/* Additional Details */}
      <AddLanguageDialog
        open={openDialog === DialogEnum.LANGUAGE}
        onRequestClose={() => {
          setOpenDialog(null)
        }}
      />
      <AddSubjectsDialog
        open={openDialog === DialogEnum.SUBJECT}
        onRequestClose={() => {
          setOpenDialog(null)
        }}
      />
      <AddWorkLocationDialog
        open={openDialog === DialogEnum.WORK_LOCATION_INTEREST}
        onRequestClose={() => {
          setOpenDialog(null)
        }}
      />
      <AddInterestsDialog
        handleClose={() => {
          setOpenDialog(null)
        }}
        open={openDialog === DialogEnum.HOBBIES}
      />

      <Dialog onOpenChange={onClose} open={open}>
        <DialogContent className="max-w-[408px]">
          <DialogHeader>
            <DialogTitle>
              <Typography variant="subtitle2">Add Profile Section</Typography>
            </DialogTitle>
          </DialogHeader>
          <DialogBody className="flex flex-col divide-y p-0">
            <Accordion
              type="single"
              value={openAccordion}
              onValueChange={toggleSingleExpanded}
            >
              {data.map((item, index) => (
                <AccordionItem
                  key={item.title}
                  value={item.title}
                  className={`border-b border-onSurface-200 ${
                    index === data.length - 1 ? "border-b-0" : ""
                  }`}
                >
                  <AccordionTrigger className="!py-2 text-start [&>.AccordionHeader-content]:w-full">
                    <Typography variant="title3">{item.title}</Typography>
                  </AccordionTrigger>
                  <AccordionContent className="!pb-0">
                    <Typography
                      variant="smallBody"
                      className="text-onSurface-500"
                    >
                      {item.subTitle}
                    </Typography>
                    <div className="flex flex-col  divide-y">
                      {item.actions.map(action => (
                        <div key={action.label}>
                          <Button
                            className="my-1.5 -ml-1 !text-onSurface-600"
                            variant="text"
                            color="black"
                            onClick={() => {
                              setOpenDialog(action.value)
                              onClose()
                            }}
                          >
                            {action.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </DialogBody>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AddProfileSectionDialog
