import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { VideoBookmark } from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import { XmarkCircle } from "iconoir-react"
import { useEffect, useState } from "react"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { getAuthInfo, handleErrors } from "utils/helpers"

import { getTimestampFromSeconds } from "./getTimestampFromSeconds"

interface Props {
  videoId: string
  courseId: string
  open: boolean
  onAdd: (data: VideoBookmark) => void
  timestamp: number
  handleClose: () => void
}

const AddNoteDialog = ({
  videoId,
  courseId,
  open,
  timestamp,
  onAdd,
  handleClose,
}: Props) => {
  const [text, setText] = useState("")

  useEffect(() => {
    if (!open) setText("")
  }, [open])

  const handleSaveNote = async () => {
    const data = {
      comment: text,
      course: courseId,
      language: localStorage.getItem(BROWSER_STORAGE_KEYS.language) || "en",
      timestamp: Math.floor(timestamp),
      video: videoId,
    }

    try {
      const res = await api.learningItems.createVideoBookmark({ data })
      onAdd({
        uuid: res.uuid,
        comment: data.comment,
        language: data.language,
        course: data.course,
        timestamp: data.timestamp,
        video: data.video,

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user: getAuthInfo()!.user.uuid,
      })
    } catch (error) {
      if (error instanceof APIError) {
        handleErrors(error)
        console.error(error)
      }
    }
  }

  return (
    <Dialog open={open} onRequestClose={handleClose} width="md">
      <DialogContent className="!p-3">
        <div className="mb-2.25 flex items-center gap-1">
          <Typography variant="strong" className="text-onSurface-800">
            Add a note at
          </Typography>
          <Chip label={getTimestampFromSeconds(timestamp)} size="sm" />
          <IconButton color="black" className="ml-auto" onClick={handleClose}>
            <XmarkCircle />
          </IconButton>
        </div>
        <form>
          <TextField
            fullWidth
            rows={8}
            helperText="You are adding a note to this video for future reference."
            multiLine
            value={text}
            autoFocus
            onChange={(e: any) => setText(e.target.value)}
          />
          <div className="mt-2 flex justify-end">
            <Button fullWidth onClick={handleSaveNote}>
              Save Note
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AddNoteDialog
