import { CircularProgress, toast, Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { Download, Notes } from "iconoir-react"
import { useEffect, useState } from "react"

import { HandoutWithLessonPlannerReturnType } from "../../../LessonPlanGenerator/useHandoutWithLessonPlanner"
import { ExportOutputButton } from "../../Export/ContentActions"
import GenerateButton from "../../GenerateButton"
import { generateUuidV4 } from "../../LessonPlanOutput/ProOutput/Content/helper"
import OutputToolbar from "../../OutputToolbar"
import ToolOutput from "../../ToolOutput"

const TemplateHandoutsList = ({
  handoutIds,

  generateToolWithLessonPlan,
  onGeneration,
  lessonPlanId,
  lessonPlanLoading,
  awaitLessonPlan,
  resetTempHandout,
  ...handoutContentProps
}: {
  handoutIds: {
    id: number
    responseIds: number[]
  }[]

  onGeneration: (id: number) => void
  lessonPlanId: number | null
  lessonPlanLoading?: boolean
  awaitLessonPlan: boolean
} & HandoutContentType &
  Pick<HandoutWithLessonPlannerReturnType, "generateToolWithLessonPlan">) => {
  const hasOnlyOneHandout = handoutIds.length === 1

  const generate = async () => {
    if (awaitLessonPlan) {
      toast.info("Please wait while lesson plan generates")
    } else {
      await generateToolWithLessonPlan.mutateAsync({
        data: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          lessonPlanResponse: lessonPlanId!,
          generatedWithLessonPlan: true,
          groupId: generateUuidV4(),
        },
        onSuccess: onGeneration,
        skipOnSuccess: true,
      })
    }
  }

  if (handoutIds.length === 0) {
    return (
      <Paper className="relative grid place-content-center py-5">
        {(lessonPlanId === null || lessonPlanLoading) && (
          <div className="absolute left-0 top-0 grid size-full place-items-center bg-white/50">
            <CircularProgress />
          </div>
        )}
        <div className="flex flex-col items-center gap-2">
          <div className="grid size-15 place-items-center rounded-full bg-interactive-50 text-interactive-500">
            <Notes className="size-10" strokeWidth={1} />
          </div>
          <Typography variant="title3" className="max-w-[400px] text-center">
            Looks like you haven’t generated any {getToolName(ToolType.handout)}{" "}
            for this Lesson Plan.
          </Typography>
          <GenerateButton
            onClick={() => {
              generate()
            }}
            loading={generateToolWithLessonPlan.isLoading}
            label="Generate"
          />
        </div>
      </Paper>
    )
  }

  return (
    <div className="mb-4 space-y-4">
      {handoutIds.length > 0 &&
        handoutIds.map((responseId, index) => {
          return (
            <div key={responseId.id}>
              <FetchHandoutContent
                {...handoutContentProps}
                sequence={hasOnlyOneHandout ? undefined : index + 1}
                responseIds={responseId.responseIds}
                resetTempHandout={resetTempHandout}
                handoutIds={handoutIds}
              />
            </div>
          )
        })}
    </div>
  )
}

export type HandoutContentType = { resetTempHandout: () => void } & Pick<
  HandoutWithLessonPlannerReturnType,
  | "isLoading"
  | "regenerateResponse"
  | "userVote"
  | "setCurrentResponseId"
  | "output"
  | "finalizedOutputs"
  | "setCurrentResponseId"
>
const FetchHandoutContent = ({
  responseIds,
  sequence,
  finalizedOutputs,
  output,
  regenerateResponse,
  userVote,
  isLoading,
  handoutIds,
  resetTempHandout,
  ...props
}: HandoutContentType & {
  responseIds: number[]
  sequence: number | undefined

  handoutIds: {
    id: number
    responseIds: number[]
  }[]
}) => {
  const [currentResponseId, setState] = useState<number | null>(responseIds[0])
  const setCurrentResponseId = (id: number, t?: { skipCall: boolean }) => {
    setState(id)
    if (!t?.skipCall) {
      props.setCurrentResponseId(id)
    }
  }
  useEffect(() => {
    if (responseIds && responseIds.length > 0 && finalizedOutputs.length > 0) {
      setCurrentResponseId(responseIds[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalizedOutputs])

  const outputData = finalizedOutputs.find(
    item => item.id === currentResponseId
  )

  const currentOutput = outputData?.output || output
  const title = sequence
    ? `${getToolName(ToolType.handout)} ${sequence + 1}`
    : getToolName(ToolType.handout)

  return (
    <div className="mb-4 flex flex-col gap-2">
      <Paper
        className="p-0"
        endSlot={
          isLoading ? undefined : (
            <OutputToolbar
              toolType={ToolType.handout}
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={currentOutput}
              isPositiveResponse={outputData?.isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              onUserReaction={reaction => {
                userVote.mutate({ reaction, id: currentResponseId })
              }}
              onRegenerate={reason => {
                regenerateResponse.mutate({
                  reason,
                  id: currentResponseId,
                  onSuccess(id) {
                    setCurrentResponseId(id, { skipCall: true })
                  },
                })
              }}
            />
          )
        }
      >
        <div>
          {currentResponseId && !isLoading && (
            <div className="ml-auto mr-2 mt-3 w-fit">
              <ExportOutputButton
                currentResponseId={currentResponseId}
                toolType={ToolType.handout}
                title={title}
              >
                <Button
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  size="sm"
                  color="black"
                  variant="outlined"
                  startAdornment={<Download />}
                >
                  Download Handout
                </Button>
              </ExportOutputButton>
            </div>
          )}

          <ToolOutput
            isLoading={isLoading}
            className={isLoading ? undefined : "pt-0"}
            onClick={() => {
              if (handoutIds.length === 1) {
                resetTempHandout()
              } else if (responseIds) {
                setCurrentResponseId(
                  responseIds?.length > 1
                    ? responseIds[responseIds?.length - 1]
                    : responseIds[0]
                )
              }
            }}
            showTitle
          >
            {currentOutput}
          </ToolOutput>
        </div>
      </Paper>
    </div>
  )
}

export default TemplateHandoutsList
