import { Checkbox, Typography } from "@suraasa/placebo-ui"
import { ResumeFillerType } from "api/resources/aiTools/types"
import { Gender } from "api/resources/profile/types"
import clsx from "clsx"
import { useMemo } from "react"
import { Controller } from "react-hook-form"
import {
  buildPhoneNumber,
  buildUserName,
  formatDate,
  getAuthInfo,
} from "utils/helpers"

import CheckListItem from "./CheckListItem"

const genderList: {
  label: string
  value: Gender
}[] = [
  { label: "Male", value: Gender.MALE },
  { label: "Female", value: Gender.FEMALE },
  { label: "Prefer not to say", value: Gender.PREFER_NOT_TO_SAY },
]

const Item = ({ title, subTitle }: { title: string; subTitle?: string }) => (
  <div>
    <Typography variant="strong">{title}</Typography>
    {subTitle && <Typography>{subTitle}</Typography>}
  </div>
)
const BasicDetails = ({
  className,
  basicDetails,
  control,
}: {
  className?: string
  basicDetails: {
    firstName?: ResumeFillerType["firstName"]
    lastName?: ResumeFillerType["lastName"]
    gender?: ResumeFillerType["gender"]
    country?: ResumeFillerType["country"]
    state?: ResumeFillerType["state"]
    dateOfBirth?: ResumeFillerType["dateOfBirth"]
    phoneNumber?: ResumeFillerType["phoneNumber"]
    lookingForJobs?: ResumeFillerType["lookingForJobs"]
    nationality?: ResumeFillerType["nationality"]
  }
  control: any
}) => {
  const {
    firstName,
    lastName,
    gender,
    country,
    state,
    dateOfBirth,
    phoneNumber,
    lookingForJobs,
    nationality,
  } = basicDetails

  const isEmpty = useMemo(
    () =>
      !phoneNumber &&
      !lookingForJobs?.lookingForJobs &&
      !nationality?.name &&
      !firstName?.firstName &&
      !lastName?.lastName &&
      !gender?.gender &&
      !country?.name &&
      !state?.name &&
      !dateOfBirth?.dateOfBirth,
    [
      phoneNumber,
      lookingForJobs?.lookingForJobs,
      nationality?.name,
      firstName?.firstName,
      lastName?.lastName,
      gender?.gender,
      country?.name,
      state?.name,
      dateOfBirth?.dateOfBirth,
    ]
  )

  if (isEmpty) return null

  return (
    <div className={clsx("flex flex-col space-y-1.5", className)}>
      <Typography variant="title3">Basic Details</Typography>

      {(firstName?.firstName || lastName?.lastName) && (
        <Controller
          control={control}
          name="firstName"
          render={({ field: firstNameField }) => {
            const firstNameValue =
              firstName?.firstName || getAuthInfo()?.user.firstName || ""

            const lastNameValue =
              lastName?.lastName || getAuthInfo()?.user.lastName || null

            return (
              <Controller
                control={control}
                name="lastName"
                render={({ field: lastNameField }) => {
                  return (
                    <CheckListItem
                      checked={
                        firstNameValue === firstNameField.value?.firstName ||
                        lastNameValue === lastNameField.value?.lastName
                      }
                      onChange={checked => {
                        if (checked) {
                          firstNameField.onChange({ firstName: firstNameValue })
                          lastNameField.onChange({ lastName: lastNameValue })
                        } else {
                          firstNameField.onChange(null)
                          lastNameField.onChange(null)
                        }
                      }}
                    >
                      <Item
                        title="Name"
                        subTitle={buildUserName({
                          firstName: firstNameValue,
                          lastName: lastNameValue,
                        })}
                      />
                    </CheckListItem>
                  )
                }}
              />
            )
          }}
        />
      )}

      {dateOfBirth?.dateOfBirth && (
        <Controller
          control={control}
          name="dateOfBirth"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={dateOfBirth.dateOfBirth === value}
              onCheckedChange={checked => {
                if (checked) {
                  onChange(dateOfBirth.dateOfBirth)
                } else {
                  onChange(null)
                }
              }}
            >
              <Item
                title="Date of Birth"
                subTitle={formatDate(dateOfBirth.dateOfBirth)}
              />
            </Checkbox>
          )}
        />
      )}
      {(state || country) && (
        <Controller
          control={control}
          name="state"
          render={({ field: stateField }) => {
            return (
              <Controller
                control={control}
                name="country"
                render={({ field: countryField }) => {
                  return (
                    <Checkbox
                      containerClass="flex items-start space-x-2"
                      checked={
                        (country &&
                          country.uuid === countryField.value?.uuid) ||
                        (state && state?.uuid === stateField.value?.uuid)
                      }
                      onCheckedChange={checked => {
                        if (checked) {
                          stateField.onChange(state)
                          countryField.onChange(country)
                        } else {
                          countryField.onChange(null)
                          stateField.onChange(null)
                        }
                      }}
                      label={
                        <Item
                          title="Current Location"
                          subTitle={[state?.name, country?.name]
                            .filter(Boolean)
                            .join(", ")}
                        />
                      }
                    />
                  )
                }}
              />
            )
          }}
        />
      )}
      {gender?.gender && (
        <Controller
          control={control}
          name="gender"
          render={({ field: { onChange, value } }) => (
            <CheckListItem
              checked={gender.gender === value}
              onChange={checked => {
                if (checked) {
                  onChange(gender.gender)
                } else {
                  onChange(null)
                }
              }}
            >
              <Item
                title="Gender"
                subTitle={
                  genderList.find(item => item.value === gender.gender)?.label
                }
              />
            </CheckListItem>
          )}
        />
      )}
      {phoneNumber?.code && phoneNumber?.number && (
        <Controller
          control={control}
          name="phoneNumber.code"
          render={({ field: codeField }) => (
            <Controller
              control={control}
              name="phoneNumber.number"
              render={({ field: numberField }) => (
                <CheckListItem
                  checked={
                    phoneNumber.code.toString() === codeField.value ||
                    phoneNumber.number === numberField.value
                  }
                  onChange={checked => {
                    if (checked) {
                      codeField.onChange(phoneNumber?.code)
                      numberField.onChange(phoneNumber?.number)
                    } else {
                      codeField.onChange(null)
                      numberField.onChange(null)
                    }
                  }}
                >
                  <Item
                    title="Phone Number"
                    subTitle={buildPhoneNumber(phoneNumber)}
                  />
                </CheckListItem>
              )}
            />
          )}
        />
      )}
      {lookingForJobs?.lookingForJobs && (
        <Controller
          control={control}
          name="lookingForJobs"
          render={({ field: { onChange, value } }) => (
            <CheckListItem
              checked={lookingForJobs.lookingForJobs === value}
              onChange={checked => {
                if (checked) {
                  onChange(lookingForJobs)
                } else {
                  onChange(null)
                }
              }}
            >
              <Item title="Looking For Jobs" />
            </CheckListItem>
          )}
        />
      )}
      {nationality && (
        <Controller
          control={control}
          name="nationality"
          render={({ field: { onChange, value } }) => (
            <CheckListItem
              checked={nationality.uuid === value?.uuid}
              onChange={checked => {
                if (checked) {
                  onChange(nationality)
                } else {
                  onChange(null)
                }
              }}
            >
              <Item title="Nationality" subTitle={nationality.name} />
            </CheckListItem>
          )}
        />
      )}
    </div>
  )
}

export default BasicDetails
