import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { UserPercentileType } from "api/resources/ito/registrations/types"
import { ITOCertificate } from "api/resources/profile/types"
import clsx from "clsx"
import ShareSheet from "components/ShareSheet"
import React, { useEffect, useRef, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { getPlatformURL } from "utils/helpers"

import PercentileAnimation from "./PercentileAnimation"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    border: " 2px solid",
    borderLeft: "0px !important",
    borderRight: "0px !important",
    background: "#050124",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
  },
  bannerTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "39px",
    letterSpacing: "-0.04em",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "34px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
      lineHeight: "34px",
    },
  },
  gold: {
    // background:
    //   "linear-gradient(90.21deg, #F7E3B3 0.18%, #F6DA92 53.12%, #EABD61 99.83%)",

    // background:
    //   "conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg)",

    backgroundImage:
      "url('/assets/ito/2023/AfterResultPhase/TopPercentageBanner/top-1-blob.webp')",
    borderColor: "#FFF2D1",
    "& .title": {
      color: "transparent",
      backgroundImage: "linear-gradient(91.41deg, #FFC241 0.3%, #FFFEE3 100%)",
    },
  },
  silver: {
    borderImageSlice: 1,
    backgroundImage:
      "url('/assets/ito/2023/AfterResultPhase/TopPercentageBanner/top-10-blob.webp')",
    border: "2px solid transparent",
    borderImageSource:
      "conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg)",

    "& .title": {
      backgroundImage: "linear-gradient(91.41deg, #B7B7B7 0.3%, #E7E7E7 100%)",
    },
  },
  bronze: {
    backgroundImage:
      "url('/assets/ito/2023/AfterResultPhase/TopPercentageBanner/top-30-blob.webp')",
    borderColor:
      "conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg)",
    "& .title": {
      backgroundImage: "linear-gradient(91deg, #EBC098 0.3%, #FFF9F2 100%)",
    },
  },
  contentContainer: {
    maxWidth: 500,
    width: "100%",
  },
}))

const TopPercentageBanner = ({
  certificate,
  data,
  isTop100,
}: {
  certificate?: ITOCertificate
  data?: UserPercentileType | null
  isTop100: boolean
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const runAnimation =
    localStorage.getItem(BROWSER_STORAGE_KEYS.hasSeenITOBadgeAnimation) ===
    "true"
      ? false
      : true

  const [videoEnded, setVideoEnded] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const [animationEnded, setAnimationEnded] = useState(false)

  const bigLogoRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (videoEnded && bigLogoRef && bigLogoRef.current) {
      setStartAnimation(true)
    }
  }, [bigLogoRef, videoEnded])

  useEffect(() => {
    if (startAnimation) {
      setTimeout(() => {
        setAnimationEnded(true)
      }, 1500)
    }
  }, [startAnimation])

  const openShareDialog = () => {
    setShareDialogOpen(!shareDialogOpen)
  }

  const getBannerStatus = (data?: UserPercentileType | null) => {
    if (isTop100) {
      return "100"
    }

    const percentile = data?.percentile ? data.percentile : null

    if (!percentile) return "none"

    if (percentile >= 99) {
      return "1%"
    }
    if (percentile >= 90) {
      return "10%"
    }
    if (percentile >= 70) {
      return "30%"
    }

    return "none"
  }

  const getBannerDetails = (status: string) => {
    if (status === "100") {
      return (
        <>
          {isXs && (
            <div
              ref={bigLogoRef}
              className={clsx(
                "pointer-events-none left-0 -ml-8 -mt-2 flex overflow-hidden transition-all duration-1400",
                startAnimation || !runAnimation
                  ? "w-full"
                  : "h-screen w-screen opacity-100",
                animationEnded || !runAnimation ? "z-10" : "z-40"
              )}
            >
              <img
                src="/assets/ito/2023/AfterResultPhase/Percentiles/top100.png"
                alt="badge"
                className={clsx(
                  "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                  startAnimation || !runAnimation
                    ? " h-full w-[200px]"
                    : "w-full"
                )}
              />
            </div>
          )}
          <div className={classes.contentContainer}>
            <div className={clsx("title mb-0.5", classes.bannerTitle)}>
              Top 100
            </div>
            <Typography
              className="mb-2 max-w-lg"
              style={{ color: "white" }}
              variant={isXs ? "body" : "largeBody"}
            >
              Congratulations! You are among the top 100 teachers globally. This
              is an exemplary achievement. Let the world know why you deserve
              all the success. Share it with them!
            </Typography>
            <Button
              rounded
              style={{
                background: "#485160",
              }}
              onClick={openShareDialog}
            >
              Share Achievement
            </Button>
          </div>
          {!isXs && (
            <div
              ref={bigLogoRef}
              className={clsx(
                "pointer-events-none absolute left-0 flex max-w-[1680px] justify-end overflow-hidden transition-all duration-1400 xl:max-w-none",
                startAnimation || !runAnimation
                  ? "w-full"
                  : "h-screen w-screen opacity-100",
                animationEnded || !runAnimation ? "z-10" : "z-40"
              )}
            >
              <img
                src="/assets/ito/2023/AfterResultPhase/Percentiles/top100.png"
                alt="badge"
                className={clsx(
                  "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                  startAnimation || !runAnimation
                    ? "-mr-10 h-full w-[400px] md:-mr-8 lg:mr-8 xl:mr-[calc(100vw-1680px)]"
                    : "mt-12 w-full xl:mt-[190px]"
                )}
              />
            </div>
          )}
        </>
      )
    }

    if (status === "1%") {
      return (
        <>
          {isXs && (
            <div
              ref={bigLogoRef}
              className={clsx(
                "pointer-events-none left-0 -ml-8 -mt-2 flex overflow-hidden transition-all duration-1400",
                startAnimation || !runAnimation
                  ? "w-full"
                  : "h-screen w-screen opacity-100",
                animationEnded || !runAnimation ? "z-10" : "z-40"
              )}
            >
              <img
                src="/assets/ito/2023/AfterResultPhase/Percentiles/top1.png"
                alt="badge"
                className={clsx(
                  "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                  startAnimation || !runAnimation
                    ? " h-full w-[200px]"
                    : "w-full"
                )}
              />
            </div>
          )}
          <div className={classes.contentContainer}>
            <div className={clsx("title mb-0.5", classes.bannerTitle)}>
              Top 1 Percentile
            </div>
            <Typography
              className="mb-2 max-w-lg"
              style={{ color: "white" }}
              variant={isXs ? "body" : "largeBody"}
            >
              Congratulations! You are among the top 1% of highly passionate and
              motivated teachers internationally. This is an exemplary
              achievement. Let the world know why you deserve all the success.
              Share it with them!
            </Typography>
            <Button
              rounded
              onClick={openShareDialog}
              style={{
                background: "#444943",
              }}
            >
              Share Achievement
            </Button>
          </div>
          {!isXs && (
            <div
              ref={bigLogoRef}
              className={clsx(
                "pointer-events-none absolute left-0 flex max-w-[1680px] justify-end overflow-hidden transition-all duration-1400 xl:max-w-none",
                startAnimation || !runAnimation
                  ? "w-full"
                  : "h-screen w-screen opacity-100",
                animationEnded || !runAnimation ? "z-10" : "z-40"
              )}
            >
              <img
                src="/assets/ito/2023/AfterResultPhase/Percentiles/top1.png"
                alt="badge"
                className={clsx(
                  "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                  startAnimation || !runAnimation
                    ? "-mr-10 h-full w-[400px] md:-mr-8 lg:mr-8 xl:mr-[calc(100vw-1680px)]"
                    : "mt-12 w-full xl:mt-[190px]"
                )}
              />
            </div>
          )}
        </>
      )
    }
    if (status === "10%") {
      return (
        <>
          {isXs && (
            <div
              ref={bigLogoRef}
              className={clsx(
                "pointer-events-none left-0 -ml-8 -mt-2 flex overflow-hidden transition-all duration-1400",
                startAnimation || !runAnimation
                  ? "w-full"
                  : "h-screen w-screen opacity-100",
                animationEnded || !runAnimation ? "z-10" : "z-40"
              )}
            >
              <img
                src="/assets/ito/2023/AfterResultPhase/Percentiles/top10.png"
                alt="badge"
                className={clsx(
                  "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                  startAnimation || !runAnimation
                    ? " h-full w-[200px]"
                    : "w-full"
                )}
              />
            </div>
          )}
          <div className={classes.contentContainer}>
            <div className={clsx("title mb-0.5", classes.bannerTitle)}>
              Top 10 Percentile
            </div>
            <Typography
              className="mb-2 max-w-lg"
              style={{ color: "white" }}
              variant={isXs ? "body" : "largeBody"}
            >
              Congratulations! You are among the top 10% teachers globally. This
              is an exemplary achievement. Let the world know why you deserve
              all the success. Share it with them!
            </Typography>
            <Button
              rounded
              style={{
                background: "#485160",
              }}
              onClick={openShareDialog}
            >
              Share Achievement
            </Button>
          </div>
          {!isXs && (
            <div
              ref={bigLogoRef}
              className={clsx(
                "pointer-events-none absolute left-0 flex max-w-[1680px] justify-end overflow-hidden transition-all duration-1400 xl:max-w-none",
                startAnimation || !runAnimation
                  ? "w-full"
                  : "h-screen w-screen opacity-100",
                animationEnded || !runAnimation ? "z-10" : "z-40"
              )}
            >
              <img
                src="/assets/ito/2023/AfterResultPhase/Percentiles/top10.png"
                alt="badge"
                className={clsx(
                  "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                  startAnimation || !runAnimation
                    ? "-mr-10 h-full w-[400px] md:-mr-8 lg:mr-8 xl:mr-[calc(100vw-1680px)]"
                    : "mt-12 w-full xl:mt-[190px]"
                )}
              />
            </div>
          )}
        </>
      )
    }

    return (
      <>
        {isXs && (
          <div
            ref={bigLogoRef}
            className={clsx(
              "pointer-events-none left-0 -ml-8 -mt-2 flex overflow-hidden transition-all duration-1400",
              startAnimation || !runAnimation
                ? "w-full"
                : "h-screen w-screen opacity-100",
              animationEnded || !runAnimation ? "z-10" : "z-40"
            )}
          >
            <img
              src="/assets/ito/2023/AfterResultPhase/Percentiles/top30.png"
              alt="badge"
              className={clsx(
                "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                startAnimation || !runAnimation ? " h-full w-[200px]" : "w-full"
              )}
            />
          </div>
        )}
        <div className={classes.contentContainer}>
          <div className={clsx("title mb-0.5", classes.bannerTitle)}>
            Top 30 Percentile
          </div>
          <Typography
            className="mb-2 max-w-lg"
            style={{ color: "white" }}
            variant={isXs ? "body" : "largeBody"}
          >
            Congratulations! You are among the top 30% of highly passionate and
            motivated teachers internationally. This is an exemplary
            achievement. Let the world know why you deserve all the success.
            Share it with them!
          </Typography>
          <Button
            rounded
            style={{
              background: "#44464A",
            }}
            onClick={openShareDialog}
          >
            Share Achievement
          </Button>
        </div>
        {!isXs && (
          <div
            ref={bigLogoRef}
            className={clsx(
              "pointer-events-none absolute left-0 flex max-w-[1680px] justify-end overflow-hidden transition-all duration-1400 xl:max-w-none",
              startAnimation || !runAnimation
                ? "w-full"
                : "h-screen w-screen opacity-100",
              animationEnded || !runAnimation ? "z-10" : "z-40"
            )}
          >
            <img
              src="/assets/ito/2023/AfterResultPhase/Percentiles/top30.png"
              alt="badge"
              className={clsx(
                "pointer-events-none transition-all delay-100 duration-1400	ease-in",
                startAnimation || !runAnimation
                  ? "-mr-10 h-full w-[400px] md:-mr-8 lg:mr-8 xl:mr-[calc(100vw-1680px)]"
                  : "mt-12 w-full xl:mt-[190px]"
              )}
            />
          </div>
        )}
      </>
    )
  }

  const status = getBannerStatus(data)

  if (status === "none" || !certificate) return null

  const certificateURL = getPlatformURL(
    "suraasa",
    `/international-teachers-olympiad/verify/${certificate.serialNumber}`
  )

  const shareText = `Super happy to share that I stand among the top ${status} teachers globally. Special thanks to the International Teachers’ Olympiad by Suraasa for giving all teachers such a wonderful opportunity of assessing their skills and getting celebrated for it.

I am thrilled to proceed to the next level of growth in my teaching career and discover my #TrueTeachingPotential!

Check out my certificate: ${certificateURL}`

  const onVideoEnded = () => {
    setVideoEnded(true)
    localStorage.setItem(BROWSER_STORAGE_KEYS.hasSeenITOBadgeAnimation, "true")
  }

  return (
    <div
      className={clsx("mb-2.5 py-4.5 sm:mb-6 md:mb-7", classes.container, {
        [classes.silver]: status === "10%" || status === "100",
        [classes.gold]: status === "1%",
        [classes.bronze]: status === "30%",
      })}
    >
      {runAnimation && (
        <PercentileAnimation
          onEnded={onVideoEnded}
          videoEnded={videoEnded}
          status={status}
        />
      )}
      <ShareSheet
        data={{
          url: certificateURL,
          text: shareText,
        }}
        open={shareDialogOpen}
        handleClose={() => setShareDialogOpen(false)}
      />
      <Container>
        <div className="grid items-center justify-between gap-1.5 md:grid-flow-col">
          {getBannerDetails(status)}
        </div>
      </Container>
    </div>
  )
}

export default TopPercentageBanner
