import { Divider, IconButton, Typography } from "@suraasa/placebo-ui"
import { WorkExperienceType } from "api/resources/profile/types"
import Section from "features/Profile/components/Section"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Plus } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"

import ProfileContext from "../../context"
import SectionContent from "../Section/SectionContent"
import EditDialog from "./EditDialog"
import WorkExperienceItem from "./WorkExperienceItem"

const WorkExperience = () => {
  const [openDialog, toggle] = useState(false)

  const { workExperiences, isPublic, updateProfile } =
    useContext(ProfileContext)

  const [workExperienceToEdit, setWorkExperienceToEdit] = useState<
    WorkExperienceType["id"] | null
  >(null)

  useEffect(() => {
    // Clear workExperienceToEdit when dialog is closed
    if (workExperienceToEdit && !openDialog) {
      setWorkExperienceToEdit(null)
    }
  }, [workExperienceToEdit, openDialog])

  const handleEditWorkExperience = (id: WorkExperienceType["id"]) => {
    setWorkExperienceToEdit(id)
    toggle(true)
  }

  return (
    <>
      {openDialog && (
        <EditDialog
          id={workExperienceToEdit}
          open={openDialog}
          handleClose={() => toggle(false)}
          onUpdate={() => updateProfile()}
        />
      )}

      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={() => toggle(true)}>
                    <Plus className="!size-3" />
                  </IconButton>
                )
              }
              title="Work Experience"
            />
          }
          xPadding={2}
        />

        <SectionContent className="flex flex-col" xPadding={2}>
          {workExperiences.data.map((experience, index) => (
            <React.Fragment key={experience.id}>
              <WorkExperienceItem
                data={experience}
                isPublic={isPublic}
                onEdit={() => handleEditWorkExperience(experience.id)}
              />
              {index < workExperiences.data.length - 1 && (
                <Divider className="my-2.25 bg-onSurface-200" weight="light" />
              )}
            </React.Fragment>
          ))}

          {workExperiences.data.length === 0 && (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any work experiences. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default WorkExperience
