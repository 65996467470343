import api from "api"

import { BROWSER_STORAGE_KEYS, isLocalhost } from "./constants"
import { getAuthInfo } from "./helpers"
import { ValueOf } from "./types"

export const USER_PREFS_KEY_NAME = "userPreferences"

type Keys = ValueOf<typeof BROWSER_STORAGE_KEYS>
const SETTING_SLUG = "platform-preferences"

/**
 * Don't import this class directly.
 * Always import the singleton instance that's created at the end of this file.
 */
class _UserPreferenceManager {
  static _instance: _UserPreferenceManager
  // @ts-expect-error we don't need to assign initial values
  _currentUserPrefs: Record<Keys, any> = {}

  _enableSync = false

  constructor() {
    if (_UserPreferenceManager._instance) {
      return _UserPreferenceManager._instance
    }
    this.init()
    _UserPreferenceManager._instance = this
  }

  async init() {
    const authInfo = getAuthInfo()
    if (!authInfo) return

    const existingPrefs = this._getLocalPreferences()
    this._currentUserPrefs = existingPrefs[authInfo.user.email] || {}

    try {
      const response = await api.settings.getUserPreferences()
      const prefs =
        JSON.parse(
          response.find(x => x.setting.slug === SETTING_SLUG)?.value || "null"
        ) || {}

      this._currentUserPrefs = {
        ...this._currentUserPrefs,
        ...prefs,
      }
      this._enableSync = true

      // console.log("> Turning on preferences syncing")

      this._updatePreferences(this._currentUserPrefs, { skipAPICall: true })
    } catch (e) {
      console.error("> Failed to fetch user preferences from server", e)
    }

    // this.list()
  }

  /**
   * @returns Must always return an empty object in case of no data
   */
  _getLocalPreferences() {
    try {
      return (
        JSON.parse(localStorage.getItem(USER_PREFS_KEY_NAME) || "null") || {}
      )
    } catch (e) {
      console.error("Error parsing JSON", e)
      // Start with a clean slate if JSON is invalid
      localStorage.setItem(USER_PREFS_KEY_NAME, "null")
      return {}
    }
  }

  set(keyName: Keys, value: any) {
    this._currentUserPrefs[keyName] = value
    this._updatePreferences(this._currentUserPrefs)
  }

  get(keyName: Keys) {
    return this._currentUserPrefs ? this._currentUserPrefs[keyName] : null
  }

  remove(keyName: Keys) {
    delete this._currentUserPrefs[keyName]
    this._updatePreferences(this._currentUserPrefs)
  }

  _updatePreferences(newPreferences: any, options?: any) {
    const authInfo = getAuthInfo()
    if (!authInfo) return

    // TODO: Implement this
    // if (!this._enableSync) {
    //   _checkIfAPIIsWorking()
    // }

    const existingPrefs = this._getLocalPreferences()
    existingPrefs[authInfo.user.email] = newPreferences
    localStorage.setItem(USER_PREFS_KEY_NAME, JSON.stringify(existingPrefs))

    if (!this._enableSync) {
      console.warn("> Preferences syncing is off")
      return
    }
    if (options?.skipAPICall) return
    if (authInfo.shadow) return

    // API Call to update prefs on backend
    api.settings.updateUserPreferences({
      data: {
        settings: [
          { slug: SETTING_SLUG, value: JSON.stringify(newPreferences) },
        ],
      },
    })
  }

  list() {
    console.table(this._currentUserPrefs)
  }
}

const UserPreferenceManager = new _UserPreferenceManager()

if (isLocalhost || sessionStorage.getItem("debug") === "1") {
  // @ts-expect-error safe to do
  window.UserPreferenceManager = UserPreferenceManager
}

export { UserPreferenceManager }
