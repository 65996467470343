import { Typography } from "@suraasa/placebo-ui"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import React from "react"
import Glider from "react-glider"

import { testimonials } from "./data"
import TestimonialCard from "./TestimonialCard"

const Testimonials = () => {
  return (
    <div className="rounded-2xl">
      <Typography className="text-ito-midnight" variant="title2">
        2022 Participants&apos; Reviews on ITO Reports
      </Typography>
      <div className="mt-2 pb-2 sm:p-0">
        <div className="relative">
          <Glider
            id="glider"
            hasArrows
            draggable={true}
            slidesToShow={3.5}
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 3000,
                settings: {
                  slidesToShow: 3000 / 1000 + 1.5,
                },
              },
              {
                breakpoint: 2500,
                settings: {
                  slidesToShow: 2500 / 1000 + 1.5,
                },
              },
              {
                breakpoint: 2000,
                settings: {
                  slidesToShow: 4.1,
                },
              },
              {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 3.1,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2.1,
                },
              },
              {
                breakpoint: 300,
                settings: {
                  slidesToShow: 1.1,
                },
              },
            ]}
            arrows={{
              prev: "#prev-testimonial-btn",
              next: "#next-testimonial-btn",
            }}
          >
            {testimonials.map((item, index) => (
              <TestimonialCard
                key={index}
                profile={item.profile}
                testimony={item.testimony}
              />
            ))}
          </Glider>

          <div className="absolute -left-5 top-1/2 hidden md:block">
            <button
              className="flex size-3.5 items-center justify-center rounded-full bg-surface-100"
              id="prev-testimonial-btn"
            >
              <NavArrowLeft />
            </button>
          </div>

          <div className="absolute -right-5 top-1/2 hidden md:block">
            <button
              className="flex size-3.5 items-center justify-center rounded-full bg-surface-100"
              id="next-testimonial-btn"
            >
              <NavArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
