import { CircularProgress, Container } from "@suraasa/placebo-ui"
import CoverStyle from "features/CoverStyle"
import { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import About from "./components/About"
import Academics from "./components/Academics"
import Achievements from "./components/Achievements"
import EducationWithSuraasa from "./components/EducationWithSuraasa"
import Interests from "./components/Interests"
import Languages from "./components/Languages"
import MasterclassesAttended from "./components/MasterclassesAttended"
import Overview from "./components/Overview"
import AddResume from "./components/Overview/AddResume"
import PersonalInfo from "./components/PersonalInfo"
import ProfileStrength from "./components/ProfileStrength"
import ReferenceSection from "./components/References"
import Resume from "./components/Resume"
import SkillProfileOverview from "./components/SkillProfileOverview"
import Skills from "./components/Skills"
import Subjects from "./components/Subjects"
import WorkExperience from "./components/WorkExperience"
import WorkLocationInterests from "./components/WorkLocationInterests"
import ProfileContext from "./context"
import PersonalDetailsSheet from "./Edit/PersonalDetailsSheet"
import ProfileOnboarding from "./ProfileOnboarding"
import VideoPortfolio from "./VideoPortfolio"

const ProfilePage = () => {
  const [searchParams] = useSearchParams()
  const { isPublic, videoPortfolioURL, loading } = useContext(ProfileContext)

  const [editProfile, setEditProfile] = useState(false)
  const [changeCoverStyle, setChangeCoverStyle] = useState(false)

  const [openAddResume, setOpenAddResume] = useState(false)
  const [videoPortfolioDialogOpen, setVideoPortfolioDialogOpen] =
    useState(false)

  const toggleChangeCoverStyle = () => {
    setChangeCoverStyle(!changeCoverStyle)
  }

  useEffect(() => {
    if (searchParams.get("edit") === "true") {
      setEditProfile(true)
    }
  }, [searchParams])

  return (
    <div>
      {loading ? (
        <div className="grid place-items-center pt-20">
          <CircularProgress size="lg" />
        </div>
      ) : (
        <>
          {changeCoverStyle ? (
            <CoverStyle toggleChangeCoverStyle={toggleChangeCoverStyle} />
          ) : (
            <div>
              <Overview
                onResumeAddClick={() => setOpenAddResume(true)}
                toggleChangeCoverStyle={toggleChangeCoverStyle}
                openVideoPortfolioDialog={() => {
                  setVideoPortfolioDialogOpen(true)
                }}
                videoPortfolioURL={videoPortfolioURL.data}
                openEditProfile={() => {
                  setEditProfile(true)
                }}
              />
              <Container
                className="!pb-10 placebo-container-w-full sm:placebo-container"
                // this is for 10 column layout
                // className={clsx({
                //   "!grid grid-cols-12 gap-2 pb-5 sm:[&>div]:col-span-10 sm:[&>div]:col-start-2 [&>div]:col-span-12 [&>div]:!min-w-[auto] xl:[&>div]:!min-w-[1280px]":
                //     isPublic,
                // })}
              >
                <div>
                  {isPublic && (
                    <div className="flex flex-col gap-2 md:gap-3">
                      <About />
                      <Resume />
                      <EducationWithSuraasa />
                      <SkillProfileOverview />
                      <Academics />
                      <WorkExperience />
                      <ReferenceSection />
                      <Achievements />
                      <MasterclassesAttended />
                      <Languages />
                      <Subjects />
                      <WorkLocationInterests />
                      <Interests />
                      <Skills />
                      <PersonalInfo />
                    </div>
                  )}
                </div>
                {!isPublic && (
                  <div className="grid grid-cols-12 sm:gap-2 md:gap-3">
                    <div className="col-span-12 flex flex-col gap-2 sm:col-span-7 md:col-span-8">
                      <ProfileStrength />
                      <About />
                      <Resume />
                      <EducationWithSuraasa />
                      <SkillProfileOverview />
                      <Academics />
                      <WorkExperience />
                      <ReferenceSection />
                      <Achievements />
                      <MasterclassesAttended />
                    </div>

                    <div className="col-span-12 flex flex-col gap-2 sm:col-span-5 md:col-span-4">
                      <Skills />
                      <Languages />
                      <Subjects />
                      <WorkLocationInterests />
                      <Interests />
                      <PersonalInfo />
                    </div>
                  </div>
                )}
              </Container>
            </div>
          )}
        </>
      )}
      {!isPublic && (
        <AddResume
          open={openAddResume}
          onClose={() => setOpenAddResume(false)}
        />
      )}
      <VideoPortfolio
        toggleOpenSheet={state => setVideoPortfolioDialogOpen(state)}
        openSheet={videoPortfolioDialogOpen}
      />
      {!isPublic && <ProfileOnboarding />}
      <PersonalDetailsSheet
        openSheet={editProfile}
        setOpenSheet={setEditProfile}
      />
    </div>
  )
}

export default ProfilePage
