import { Button, IconButton, Tooltip, Typography } from "@suraasa/placebo-ui"
import { theme } from "@suraasa/placebo-ui-legacy"
import {
  Reference,
  SourceType,
  UserReferenceRelationship,
  userReferenceRelationshipChoices,
} from "api/resources/references/types"
import ReferenceIcon from "assets/icons/system/ReferenceIcon"
import { differenceInHours, parseISO } from "date-fns"
import ProfileContext from "features/Profile/context"
import { CheckCircleSolid, Edit } from "iconoir-react"
import { Check } from "lucide-react"
import { useContext, useState } from "react"
import { pluralize } from "utils/helpers"

import ReferenceEditDialog from "./EditDialog"
import Reminder from "./Reminder"
import { useScrollToReference } from "./useScrollToReference"

interface ReferenceStatusProps {
  data: Reference
}

interface AwaitingResponseCardProps {
  data: Reference
}

interface ReferenceCardProps {
  data: Reference
}

interface ConfirmationProps {
  isReminder: boolean
  referrerName: string
}

function getRelationshipLabel(
  relationshipValue: UserReferenceRelationship
): string {
  const choice = userReferenceRelationshipChoices.find(
    choice => choice.value === relationshipValue
  )

  return choice?.label || ""
}

const ReferenceStatusDisplay: React.FC<ReferenceStatusProps> = ({ data }) => {
  if (data?.userQuestionnaire?.submittedAt) {
    return <ReferenceReceivedCard />
  }
  return <AwaitingResponseCard data={data} />
}

const ReferenceReceivedCard = () => {
  return (
    <div className="flex items-center">
      <CheckCircleSolid className="size-2.5 text-success-500" />
      <Typography
        color="success.500"
        className="ml-0.75"
        variant="strongSmallBody"
      >
        Reference Received
      </Typography>
    </div>
  )
}

const AwaitingResponseCard: React.FC<AwaitingResponseCardProps> = ({
  data,
}) => {
  const reminderSent = differenceInHours(new Date(), parseISO(data.sentAt)) < 24
  const [openDialog, toggle] = useState(false)
  return (
    <>
      {openDialog && (
        <Reminder
          data={data}
          open={openDialog}
          handleClose={() => toggle(false)}
        />
      )}
      <div className="rounded-lg bg-warning-50 p-1.5">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="size-2.5 rounded-full bg-warning-200 p-0.75">
              <div className="size-1 rounded-full bg-warning-700" />
            </div>
            <Typography
              color="warning.700"
              className="ml-0.75 "
              variant="strongSmallBody"
            >
              Awaiting Response
            </Typography>
          </div>
          <Tooltip
            content="You can only send one reminder in 24 hours. Please wait before sending another."
            className="max-w-[300px]"
            enabled={!reminderSent}
          >
            <div>
              <Button
                disabled={reminderSent}
                onClick={() => {
                  toggle(true)
                }}
                variant="text"
              >
                Send Reminder
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

const PublicReferenceCard: React.FC<{
  count: number
  referenceType?: SourceType
}> = ({ count, referenceType }) => {
  const { profile } = useContext(ProfileContext)

  const getTypeString = (): string => {
    switch (referenceType) {
      case SourceType.UserQualification:
        return "qualification"
      case SourceType.UserWorkExperience:
        return "work experience"
      default:
        return profile.user.firstName
    }
  }
  return (
    <div className="mt-1 max-w-xl rounded-lg border border-onSurface-200 p-2">
      <div className="flex flex-row items-center">
        <div className="mr-1 rounded-full bg-success-50 p-0.75">
          <ReferenceIcon color={theme.colors.success[600]} />
        </div>

        {referenceType ? (
          <Typography color="onSurface.600" variant="smallBody">
            {pluralize("reference", count)} for this {getTypeString()}
          </Typography>
        ) : (
          <Typography color="onSurface.600" variant="smallBody">
            {pluralize("reference", count)} for {getTypeString()}
          </Typography>
        )}
      </div>
    </div>
  )
}

const ReferenceCard: React.FC<ReferenceCardProps> = ({ data }) => {
  const { elementId } = useScrollToReference(data.id)

  const { isPublic } = useContext(ProfileContext)
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <>
      {openDialog && (
        <ReferenceEditDialog
          data={data}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}
      <div
        className="mt-1 rounded-lg border border-onSurface-200 p-1.5"
        id={elementId}
      >
        <div className="flex items-start">
          <div className="rounded-full bg-success-50 p-0.75">
            <ReferenceIcon color={theme.colors.success[600]} />
          </div>

          <div className="ml-0.75 grow">
            <div className="flex justify-between">
              <Typography variant="smallBody" color="onSurface.600">
                Reference
              </Typography>

              {!isPublic && !data?.userQuestionnaire?.submittedAt && (
                <IconButton onClick={() => setOpenDialog(true)}>
                  <Edit className="!size-3" />
                </IconButton>
              )}
            </div>

            <div className="mt-2">
              <Typography variant="strongSmallBody" color="onSurface.900">
                {data.referrer.name}
              </Typography>

              <Typography
                className="mt-0.75"
                variant="smallBody"
                color="onSurface.600"
              >
                {data.referrer.position},{" "}
                {getRelationshipLabel(data.relationship)}
              </Typography>

              <a
                href={`mailto:${data.referrer.email}`}
                className="mt-0.5 text-sm text-interactive-500 underline"
              >
                {data.referrer.email}
              </a>
            </div>
            {data?.userQuestionnaire?.submittedAt && (
              <div className="mt-2">
                <ReferenceReceivedCard />
              </div>
            )}
          </div>
        </div>
        {!data?.userQuestionnaire?.submittedAt && (
          <div className="mt-2">
            <AwaitingResponseCard data={data} />
          </div>
        )}
      </div>
    </>
  )
}

const ConfirmationCard: React.FC<ConfirmationProps> = ({
  isReminder,
  referrerName,
}) => {
  const confirmationText = isReminder ? "Reminder" : "Request"
  return (
    <div className="mx-8 my-5 flex flex-col items-center">
      <div className="mb-3  rounded-[32px] bg-surface-100 p-2.25">
        <Check size={"40px"} className="text-primary-500" />
      </div>

      <Typography variant="title2" color="onSurface.900" className="mb-2">
        {confirmationText} Sent.
      </Typography>

      <Typography variant="body" color="onSurface.600" className="text-center">
        Your {confirmationText} has been successfully sent to {referrerName}.
        They’ll receive an email with the details and instructions to complete
        the reference.
      </Typography>
    </div>
  )
}

export {
  ConfirmationCard,
  getRelationshipLabel,
  PublicReferenceCard,
  ReferenceCard,
  ReferenceStatusDisplay,
}
