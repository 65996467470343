/* eslint-disable @cspell/spellchecker */
import { Button, Typography } from "@suraasa/placebo-ui"
import GridBg from "assets/AITools/grid.webp"
import Paper from "features/AItools/components/Paper"
import { ArrowLeft, WarningTriangle } from "iconoir-react"
import React from "react"

const AIToolsError = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Paper className="relative py-5 sm:h-[375px] sm:py-0">
      <img
        src={GridBg}
        alt="grid-bg"
        className="absolute inset-x-0 top-0 m-auto block scale-125 object-fill sm:hidden"
      />
      <img src={GridBg} alt="grid-bg" className="mx-auto hidden sm:block" />
      <div className="relative z-1 flex flex-col items-center sm:absolute sm:left-2/4 sm:top-2/4 sm:-translate-x-2/4 sm:-translate-y-2/4">
        <div className="mb-2 grid size-5 place-items-center rounded-lg border border-solid border-surface-200 bg-white shadow-[0px_6px_15px_0px_#0000000D]">
          <WarningTriangle className="size-2.5" />
        </div>

        <Typography className="mb-1 text-center" variant="title2">
          Oops! Something went wrong.
        </Typography>
        <Typography className="mb-4 max-w-[330px] text-center">
          We couldn&apos;t generate your request due to an error or restrictions
          on the content. Please review your input and try again.
        </Typography>
        <Button
          className="bg-onSurface-900 text-white"
          size="sm"
          onClick={onClick}
          startAdornment={<ArrowLeft />}
        >
          Review Input
        </Button>
      </div>
    </Paper>
  )
}

export default AIToolsError
