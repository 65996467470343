import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
  Tag,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import {
  attendanceStatus,
  ClassDetailsType,
} from "api/resources/learningItems/types"
import clsx from "clsx"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import { Check, Lock, NavArrowDown } from "iconoir-react"
import { useTheme } from "react-jss"
import { formatDate } from "utils/helpers"

import styles from "./ClassDetailsCard.module.css"

const ClassDetailsCard = ({
  data,
  onViewRecording,
}: {
  data: ClassDetailsType
  onViewRecording: (recordingUrl: string) => void
}) => {
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const getIcon = (
    item: ClassDetailsType,
    className?: string
  ): JSX.Element | null => {
    if (getRestriction(item)) {
      return (
        <div
          className={clsx(
            "flex size-4 items-center justify-center rounded-full bg-secondary-300 p-0.75 text-onSurface-500",
            className
          )}
        >
          <Lock />
        </div>
      )
    }
    if (item.videoMeeting?.joiningUrl) {
      return (
        <div
          className={clsx(
            "flex size-4 items-center justify-center rounded-full bg-primary-500",
            className
          )}
        >
          <div
            className={clsx(
              "size-1.25 rounded-full bg-white",
              styles.impulseCircle
            )}
          />
        </div>
      )
    }

    switch (item.attendanceStatus) {
      case attendanceStatus.YetToStart:
        return (
          <div
            className={clsx(
              "flex size-4 items-center justify-center rounded-full bg-primary-500",
              className
            )}
          >
            <div className={clsx("size-1.25 rounded-full bg-white")} />
          </div>
        )
      case attendanceStatus.Present:
      case attendanceStatus.RescheduledAndAttended:
        return (
          <div
            className={clsx(
              "flex size-4 items-center justify-center rounded-full bg-success-500 p-0.75 text-white",
              className
            )}
          >
            <Check />
          </div>
        )

      case attendanceStatus.Absent:
      case attendanceStatus.Rescheduled:
      case attendanceStatus.RescheduledButNotAttended:
        return (
          <div
            className={clsx(
              "flex size-4 items-center justify-center rounded-full bg-warning-500",
              className
            )}
          >
            <div className="size-1.25 rounded-full bg-white" />
          </div>
        )
    }

    return (
      <div
        className={clsx(
          "flex size-4 items-center justify-center rounded-full bg-secondary-300 p-0.75 text-onSurface-500",
          className
        )}
      >
        <Lock />
      </div>
    )
  }

  const getAction = () => {
    if (data.videoMeeting?.joiningUrl) {
      return (
        <CheckRestrictions
          accessStatus={data.accessStatus}
          render={({ isRestricted }) => (
            <Button
              disabled={isRestricted}
              fullWidth={isSmDown}
              variant="filled"
              component="a"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              href={data.videoMeeting!.joiningUrl!}
              target="_blank"
            >
              Join Class
            </Button>
          )}
        />
      )
    }
    if (
      data.learningContentType === "class_schedule" &&
      data.videoMeeting?.recordings &&
      data.videoMeeting.recordings.length > 0
    ) {
      return (
        <Menu
          menuButton={
            <CheckRestrictions
              accessStatus={data.accessStatus}
              render={({ isRestricted }) => (
                <Button variant="text" fullWidth disabled={isRestricted}>
                  Watch Recording
                </Button>
              )}
            />
          }
        >
          {data.videoMeeting.recordings.map((recording, index) => (
            <MenuItem
              key={recording.recording}
              onClick={() => onViewRecording(recording.recording)}
            >
              Part&nbsp;{index + 1}
            </MenuItem>
          ))}
        </Menu>
      )
    }
    return null
  }

  const getTag = (
    item: ClassDetailsType,
    className?: string
  ): JSX.Element | null => {
    if (getRestriction(item)) {
      return null
    }
    switch (item.attendanceStatus) {
      case attendanceStatus.Present: {
        return <Tag label="Attended" color="success" className={className} />
      }
      case attendanceStatus.RescheduledAndAttended: {
        return <Tag label="Attended" color="success" className={className} />
      }
      case attendanceStatus.Rescheduled: {
        return <Tag label="Rescheduled" color="warning" className={className} />
      }
      case attendanceStatus.RescheduledButNotAttended: {
        return (
          <Tag label="Not Attended" color="warning" className={className} />
        )
      }
      case attendanceStatus.YetToStart: {
        return item.videoMeeting?.joiningUrl ? null : (
          <Tag label="Upcoming" color="primary" className={className} />
        )
      }
      case attendanceStatus.Absent: {
        return (
          <Tag label="Not Attended" color="warning" className={className} />
        )
      }
      case null:
        return null
    }
  }

  return (
    <div className="flex flex-col rounded-2xl border border-surface-200 bg-white p-2 sm:flex-row sm:justify-between">
      <div className="flex flex-col sm:flex-row">
        {getIcon(data, "me-0 sm:me-2 mb-2 sm:mb-0 shrink-0")}

        <div>
          <div className="mb-1 flex flex-wrap gap-1 sm:mb-0.5">
            <Typography variant="title3" className="me-1 text-onSurface-800">
              {data.learningContentType === "class"
                ? data?.name
                : data.classId.name}
            </Typography>
            {getTag(data)}
          </div>
          {/* If joiningUrl is present, then class is live */}
          {data.videoMeeting?.joiningUrl ? (
            <div className="mb-1 me-1">
              <Typography
                variant="smallBody"
                className="mb-0.5 text-onSurface-500"
              >
                {formatDate(data.startTime, "do MMMM Y, p")}
              </Typography>
              {data.professor && (
                <Typography variant="smallBody" className="text-onSurface-500">
                  Conducted by Prof. {data.professor.fullName}
                </Typography>
              )}
            </div>
          ) : (
            <>
              <Typography
                variant="smallBody"
                className="mb-1 me-1 text-onSurface-500"
              >
                <>
                  {formatDate(data.startTime, "do MMMM Y, p")}
                  {data.professor && " • Prof. " + data.professor.fullName}
                </>
              </Typography>
            </>
          )}
          {data.videoMeeting?.joiningUrl ? (
            <>
              <Typography className="mb-1 me-2" variant="strong">
                Topics Covered:
              </Typography>
              <div>
                {data.lectures &&
                  data.lectures.map(item => {
                    const tag = item.name.split(":", 1)[0]
                    const name = item.name.split(":", 2)[1]
                    return (
                      <div key={item.id} className="mb-0.5 flex items-center">
                        <Tag color="onSurface" className="me-2" label={tag} />
                        <p className={styles.topicTitle}>{name}</p>
                      </div>
                    )
                  })}
              </div>
            </>
          ) : (
            <>
              {data.lectures && data.lectures.length > 0 && (
                <Accordion>
                  <AccordionHeader
                    icon={<NavArrowDown color="#1E293B" />}
                    className={clsx(
                      styles.nudgeAccordion,
                      styles.nudgeAccordionHeader,
                      "gap-1 text-onSurface-800"
                    )}
                  >
                    <Typography variant="strong">Topics Covered</Typography>
                  </AccordionHeader>
                  <AccordionSummary className={styles.nudgeAccordion}>
                    <div className="flex">
                      {data.lectures.map(item => {
                        const tag = item.name.split(":", 1)[0]

                        return (
                          <div
                            key={item.id}
                            className="mb-0.5 flex items-center"
                          >
                            <Tag
                              color="onSurface"
                              className="me-2"
                              label={tag}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={styles.nudgeAccordion}>
                    <div>
                      {data.lectures.map(item => {
                        const tag = item.name.split(":", 1)[0]
                        const name = item.name.split(":", 2)[1]
                        return (
                          <div
                            key={item.id}
                            className="mb-0.5 flex items-center"
                          >
                            <Tag
                              color="onSurface"
                              className="me-2"
                              label={tag}
                            />
                            <p className={styles.topicTitle}>{name}</p>
                          </div>
                        )
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
        </div>
      </div>
      <div className="mt-3 sm:mt-0">{getAction()}</div>
    </div>
  )
}

export default ClassDetailsCard
