import { toast, Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
// import { intervalToDuration } from "date-fns"
import { Microphone, RefreshDouble, Square } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { CountdownCircleTimer } from "react-countdown-circle-timer"

import AllowMicrophoneDialog from "./AllowMicrophoneDialog"
import { useReactMediaRecorder } from "./useReactMediaRecorder"
import WaveForm from "./WaveForm"

type Props = {
  hideWaveForm?: boolean
  onRecordingStart: () => void
  onRecordingStop: (blobUrl: { mpeg: string; wav: string }) => void
  text: string
  perWordRecordDuration: number
  startRecordingDelay: number
  enableTimer?: boolean
}

const RecordPronunciation = ({
  hideWaveForm,
  onRecordingStart,
  onRecordingStop,
  text,
  perWordRecordDuration,
  startRecordingDelay,
  enableTimer = true,
}: Props) => {
  const {
    error,
    startRecording,
    stopRecording,
    status,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({
    audio: true,
    video: false,
    onStop: onRecordingStop,
  })

  const [showMicrophonePermissionsDialog, setShowMicrophonePermissionsDialog] =
    useState(false)

  useEffect(() => {
    if (error === "permission_denied") {
      setShowMicrophonePermissionsDialog(true)
    }
  }, [error])

  const [runTimer, setRunTimer] = useState(false)

  const start = () => {
    clearBlobUrl()
    startRecording()
    onRecordingStart()
  }

  const stop = () => {
    stopRecording()
    setRunTimer(false)
  }

  const onRecordClick = async () => {
    if (status === "recording") {
      stop()
      return
    }

    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then(stream => {
        const tracks = stream.getTracks()
        tracks.forEach(track => track.clone().stop())

        if (enableTimer) setRunTimer(true)
        else start()
      })
      .catch(e => {
        console.log({ e })
        toast.error("Need microphone permission", {
          description: "Please allow microphone access.",
        })
        setShowMicrophonePermissionsDialog(true)
      })
  }

  useEffect(() => {
    return () => {
      clearBlobUrl()
      stopRecording()
      setRunTimer(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, stopRecording])

  if (import.meta.env.MODE === "development") console.log("Re-rendering")

  return (
    <div className="flex flex-col items-center justify-center">
      <AllowMicrophoneDialog open={showMicrophonePermissionsDialog} />
      <div className={clsx("flex items-center justify-center space-x-1")}>
        <div className="w-full">
          {/* {showRecordingWaveForm &&
            previewAudioStream &&
            status === "recording" && (
              <div className="flex items-center space-x-1 min-w-[200px] justify-between">
                <WaveForm
                  height={40}
                  width={200}
                  stream={previewAudioStream}
                  isRecording
                />
                <ElapsedTime />
              </div>
            )} */}
          {mediaBlobUrl && status !== "recording" && !hideWaveForm && (
            <WaveForm height={40} width="200px" stream={mediaBlobUrl} />
          )}
        </div>

        {mediaBlobUrl && (
          <Button
            onClick={() => {
              setRunTimer(false)
              clearBlobUrl()
            }}
            className="!rounded-full !bg-black !px-1"
            size="sm"
          >
            <div className="flex items-center space-x-0.5 text-xs text-white">
              <RefreshDouble height={15} />
              <span>Retry</span>
            </div>
          </Button>
        )}
      </div>
      <div className="my-4">
        {enableTimer && (
          <Timers
            onStart={start}
            isRunning={runTimer}
            text={text}
            perWordRecordDuration={perWordRecordDuration}
            startRecordingDelay={startRecordingDelay}
          />
        )}
      </div>
      {!mediaBlobUrl && (
        <div className="fixed bottom-4 mt-1 flex flex-col items-center">
          <Button
            size="lg"
            endAdornment={
              status === "recording" ? (
                <Square className="text-white" />
              ) : (
                <Microphone className="text-white" />
              )
            }
            disabled={runTimer && status !== "recording"}
            rounded
            className="shadow"
            color={status === "recording" ? "critical" : "primary"}
            onClick={onRecordClick}
          >
            {status === "recording" ? "Stop Recording" : "Record Now"}
          </Button>
        </div>
      )}
    </div>
  )
}

RecordPronunciation.displayName = "RecordPronunciation"
export default React.memo(RecordPronunciation)

// const getElapsedTime = (startTime: Date | null) => {
//   if (!startTime) {
//     return
//   }

//   const { minutes = 0, seconds = 0 } = intervalToDuration({
//     start: startTime,
//     end: new Date(),
//   })
//   return (
//     <div>
//       {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
//     </div>
//   )
// }

// const ElapsedTime = () => {
//   const [startTime, setStartTime] = useState<Date | null>(null)
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const [_, setTriggerUpdate] = useState(1)

//   useEffect(() => {
//     setStartTime(new Date())
//     const interval = window.setInterval(() => {
//       setTriggerUpdate(n => n + 1)
//     }, 1000)
//     return () => {
//       clearInterval(interval)
//     }
//   }, [])

//   return <div className="w-2">{startTime && getElapsedTime(startTime)}</div>
// }

const Timers = ({
  text,
  isRunning,
  perWordRecordDuration,
  onStart,
}: {
  onStart: () => void
  text: string
  perWordRecordDuration: number
  startRecordingDelay: number
  isRunning: boolean
}) => {
  const [index, setIndex] = useState<number | undefined>(0)

  useEffect(() => {
    if (isRunning) {
      onStart()
      setIndex(0)
    }

    return () => setIndex(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning])

  if (index === undefined || !isRunning) return null

  // if (index === null)
  //   return (
  //     <CountdownCircleTimer
  //       key="start-timer"
  //       isPlaying={isRunning}
  //       duration={startRecordingDelay}
  //       colors={["#3852C5", "#3852C5"]}
  //       colorsTime={[0, 1]}
  //       strokeWidth={6}
  //       onComplete={() => {
  //         setIndex(0)
  //         onStart()
  //       }}
  //     >
  //       {({ remainingTime }) => {
  //         return (
  //           <div className="flex items-center justify-center">
  //             <Typography variant="strongSmallBody">
  //               Recording starting in {Math.ceil(remainingTime)}
  //             </Typography>
  //           </div>
  //         )
  //       }}
  //     </CountdownCircleTimer>
  //   )

  const totalWords = text.split(" ").length

  return (
    <CountdownCircleTimer
      key="word-timer"
      isPlaying={index != null && isRunning}
      duration={perWordRecordDuration}
      colors={["#3852C5", "#3852C5"]}
      colorsTime={[0, 1]}
      strokeWidth={6}
      onComplete={totalTime => {
        const iteration = totalTime / perWordRecordDuration

        if (iteration >= totalWords) {
          setIndex(undefined)
          return undefined
        }

        setIndex(iteration)
        return {
          shouldRepeat: true,
        }
      }}
    >
      {() => {
        return (
          <div className="flex items-center justify-center">
            <Typography>{text.split(" ")[index]}</Typography>
          </div>
        )
      }}
    </CountdownCircleTimer>
  )
}
