import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("assignments", {
  list: (...params) => ({
    queryKey: ["assignmentList", ...params],
  }),
  retrieve: (props: {
    assignmentUUID: string | undefined
    userPlannerItemId: number | undefined
  }) => ({
    queryKey: ["assignment", props],
  }),
  rubrics: (assignmentId: string) => ({
    queryKey: ["assignmentRubrics", assignmentId],
  }),
  reviewRubrics: (reviewId: string) => ({
    queryKey: ["submissionReviewRubrics", reviewId],
  }),
  submissionsListByAssignmentId: (assignmentId: string) => ({
    queryKey: ["assignmentSubmissionsList", assignmentId],
  }),
  submissionsByUUID: (submissionId: string) => ({
    queryKey: ["submission", submissionId],
  }),
  dueAssignmentList: () => ({ queryKey: ["dueAssignmentList"] }),
})
