import { IconButton } from "@suraasa/placebo-ui-legacy"
import { LearningItem } from "api/resources/learningItems/types"
import clsx from "clsx"
import { LearningModuleContext } from "features/LearningModule/context"
import { useUpdateItemStatus } from "features/LearningModule/hooks/useUpdateItemStatus"
import { Bookmark } from "iconoir-react"
import { useContext, useEffect } from "react"

import TitleCard from "../TitleCard"
import styles from "./Article.module.css"

const Article = ({ item }: { item: LearningItem }) => {
  const { handleArticleBookmark } = useContext(LearningModuleContext)
  const { updateItemStatus } = useUpdateItemStatus(item)

  if (item.learningContentType !== "article")
    throw new Error("Invalid content type supplied to Article component")

  const { title, content, bookmarkArticle, optimumTime, uuid } = item

  const optimumTimeInMinutes = Math.floor(optimumTime / 60)

  useEffect(() => {
    async function fetchAndExecuteScript(url: string) {
      try {
        const response = await fetch(url)
        const script = await response.text()
        eval(script)
      } catch (error) {
        console.error(`Error fetching or executing script: ${error}`)
      }
    }

    if (content && content.includes("suraasaAudio")) {
      fetchAndExecuteScript(
        "https://assets.suraasa.com/lms/activity/suraasa-audio-player-flutter.js"
      )
    }
  }, [content])

  useEffect(() => {
    updateItemStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mb-6">
      <TitleCard
        title={title}
        subtitle={
          optimumTimeInMinutes > 0
            ? optimumTimeInMinutes + " min read"
            : undefined
        }
        action={
          <IconButton
            variant="filled"
            className="shrink-0 !rounded-full"
            size="md"
            onClick={() => {
              handleArticleBookmark(uuid)
            }}
          >
            <Bookmark
              height={20}
              width={20}
              className={clsx({
                "fill-white": bookmarkArticle.length > 0,
              })}
            />
          </IconButton>
        }
      />

      {content && (
        <div
          id="article-content"
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  )
}

export default Article
