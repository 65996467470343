import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { isLocalhost } from "utils/constants"

const paramName = "highlight-element-id"
const highlightClass = "element-highlight"

const generateId = (id: string) => `#highlight-element-id-${id}`

export const useScrollToField = (id: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const scrollToId = searchParams.get(paramName)

  const elementId =
    scrollToId && scrollToId === id ? generateId(scrollToId) : ""

  useEffect(() => {
    if (elementId) {
      setTimeout(() => {
        const element = document.getElementById(elementId)
        if (element) {
          element.classList.add(highlightClass)
          element.scrollIntoView({ behavior: "smooth", block: "center" })

          if (!isLocalhost) {
            searchParams.delete(paramName)
            setSearchParams(searchParams, {
              replace: true,
              preventScrollReset: true,
            })
          }

          // Remove the highlight class after 2 seconds
          setTimeout(() => {
            element.classList.remove(highlightClass)
          }, 2000)
        }
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId])

  return { elementId }
}
