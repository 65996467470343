import { Avatar, toast, Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { BlockedUser } from "api/resources/users/types"
import { APIError } from "api/utils"
import { useSettings } from "features/Settings/index"
import React, { useState } from "react"

import RemoveDialog from "@/common/RemoveDialog"

import MobileHeadBar from "../MobileHeadbar"

const Privacy = () => {
  const { privacyDetails, setPrivacyDetails } = useSettings()
  const [unblockUser, setUnblockDetails] = useState<{
    data: BlockedUser | null
    openDialog: boolean
  }>({ data: null, openDialog: false })

  const handleRemove = async () => {
    try {
      if (unblockUser.data) {
        await api.users.block.delete({
          urlParams: { id: unblockUser.data.uuid },
        })
        setPrivacyDetails(prev =>
          prev.filter(data => data.uuid !== unblockUser.data?.uuid)
        )
        setUnblockDetails({ data: null, openDialog: false })

        toast.success("User successfully unblocked")
      }
    } catch (err) {
      if (err instanceof APIError) {
        toast.error(err.errors.message || "Something went wrong")
      }
    }
  }

  return (
    <div>
      <MobileHeadBar title="Privacy" />
      <Typography variant="title3" className="mb-3">
        Blocked Accounts
      </Typography>
      {privacyDetails.length > 0 ? (
        <>
          {privacyDetails.map((user, idx) => {
            return (
              <div key={idx} className="mt-2 flex items-center justify-between">
                <div className="flex items-center">
                  <Avatar
                    className="size-6"
                    name={user.fullName}
                    src={user.photo}
                  />
                  <Typography variant="strong" className="ms-1.5">
                    {user.fullName}
                  </Typography>
                </div>
                <Button
                  variant="text"
                  color="critical"
                  onClick={() => {
                    setUnblockDetails({ data: user, openDialog: true })
                  }}
                >
                  Unblock
                </Button>
              </div>
            )
          })}
        </>
      ) : (
        <Typography className="text-onSurface-500">
          You have not blocked any accounts.
        </Typography>
      )}

      <RemoveDialog
        title={`Unblock ${unblockUser.data?.fullName.split(" ")[0]}`}
        onRemove={handleRemove}
        isOpen={unblockUser.openDialog}
        setIsOpen={() => setUnblockDetails({ data: null, openDialog: false })}
      >
        Are you sure you want to unblock <b>{unblockUser?.data?.fullName}</b>?
      </RemoveDialog>
    </div>
  )
}

export default Privacy
