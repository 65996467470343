import { Button, cn, Typography } from "@suraasa/placebo-ui"
import { Plus } from "iconoir-react"
import { trackElementOnPH } from "utils/tracking"

import { CarouselItem } from "@/components/Carousel"

const ActionCard = ({
  title,
  isRequired,
  className,
  containerClassName,
  action,
}: {
  title: string
  className?: string
  containerClassName?: string
  isRequired?: boolean
  action: () => void
}) => {
  return (
    <CarouselItem className={containerClassName}>
      <button
        className={cn(
          "flex w-full items-center gap-2 rounded-xl border border-onSurface-300 p-2  active:bg-onSurface-50 hover:shadow-[1px_1px_5px_0px_#0000001A] focus-within:outline-interactive-400 focus-within:outline-offset-1",
          "group",
          className
        )}
        onClick={action}
        {...trackElementOnPH({
          name: title,
          id: "profile-strength-action-card",
        })}
      >
        <div className="grow text-start">
          {isRequired && (
            <Typography
              variant="preTitle"
              className="mb-0.25 text-critical-500"
            >
              Required
            </Typography>
          )}
          <Typography variant="title4">{title}</Typography>
        </div>

        <span className="rounded-lg bg-primary-50 py-0.75 pl-1 pr-1.5 text-button text-primary hover:bg-primary-100">
          <span className="flex items-center gap-0">
            <Plus className="!size-2.5" />
            Add
          </span>
        </span>
      </button>
    </CarouselItem>
  )
}

export default ActionCard
