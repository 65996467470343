import { cn } from "@suraasa/placebo-ui"

import BadgeColor from "../../assets/ProfileStrength/badge-color.webp"
import BadgeOutlined from "../../assets/ProfileStrength/badge-outline.webp"

const Badge = ({
  className,
  isColored,
}: {
  className?: string
  isColored?: boolean
}) => {
  return (
    <img
      src={isColored ? BadgeColor : BadgeOutlined}
      alt="profile strength badge"
      className={cn("shrink-0", className)}
      style={{
        ...(isColored
          ? {
              filter: `drop-shadow(0px 8px 15px rgba(148, 94, 204, 0.25)) drop-shadow(0px 2px 5px rgba(145, 91, 203, 0.45))`,
            }
          : {}),
      }}
    />
  )
}

const DashedLine = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="1.5"
    className={cn("block bg-onSurface-100", className)}
  >
    <line
      x1="0"
      y1="1"
      x2="100%"
      y2="1"
      stroke="#cbd5e1"
      className="bg-transparent"
      strokeWidth="1.5"
      strokeDasharray="7,7"
    />
  </svg>
)

export { Badge, DashedLine }
