import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import Stars from "assets/AITools/stars-gradient.json"
import PDFSvg from "assets/PDFSvg.svg"
import { WordRotate } from "components/WordRotate"
import ResumeProfileFiller from "features/AItools/components/Generator/ResumeProfileFiller"
import { useResumeProfileFiller } from "features/AItools/components/Generator/ResumeProfileFiller/Provider"
import ProfileContext from "features/Profile/context"
import { PROFILE_ONBOARDING_TARGETS } from "features/Profile/ProfileOnboarding/constants"
import { ProfileQuickAccessTabs, usePreviewMode } from "features/Profile/utils"
import { NavArrowDown, Upload } from "iconoir-react"
import { useContext, useState } from "react"
import Lottie from "react-lottie"
import { buildUserName, getAuthInfo, handleErrors } from "utils/helpers"

import AddResume from "../Overview/AddResume"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import FakePDF from "./FakePDF"
import PDFViewer from "./PDFViewer"

const Resume = () => {
  const [openSheet, setOpenSheet] = useState(false)
  const { isViewingAsOtherPlatform, previewMode } = usePreviewMode()

  const isLoggedIn = Boolean(getAuthInfo())

  const [uploadResumeDialogOpen, setUploadResumeDialogOpen] = useState(false)

  const resumeFiller = useResumeProfileFiller()
  const { profile, isPublic, updateProfile } = useContext(ProfileContext)

  const { resume, user } = profile

  const fullName = buildUserName(user)

  const { mutate } = useMutation({
    mutationFn: () =>
      api.profile.update({
        data: { resume: null },
      }),
    onSuccess: res => {
      if (res.isSuccessful) updateProfile()
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const NotUploaded = (
    <Typography className="text-onSurface-400" variant="smallBody">
      {profile.user.firstName} has not uploaded their resume yet.
    </Typography>
  )

  const PublicResumeComponent = (() => {
    if (!isPublic) return null

    if (isViewingAsOtherPlatform || previewMode) {
      if (resume) return <PDFViewer url={resume} />
      return NotUploaded
    }

    return <FakePDF name={fullName} />
  })()

  if (resumeFiller.isQueued) {
    return (
      <Section id={ProfileQuickAccessTabs.resume}>
        <SectionHeading
          heading={<SectionTitle title="Resume" />}
          xPadding={2}
        />
        <SectionContent xPadding={2} className="!py-1">
          <div className="flex items-center justify-center gap-2">
            <div className="grow">
              <WordRotate
                className="grow text-strong text-black"
                words={[
                  "Parsing your professional experience 🔍📄",
                  "Analysing credentials and optimizing profile performance 📊📈",
                  "Structuring your achievements into a refined profile 🏗️💼",
                  "Extracting key insights and highlighting your strengths 🗂️✨",
                  "Enhancing your experience to maximize career potential 🚀💼",
                  "Processing qualifications and aligning with industry standards 📜✅",
                  "Refining details to elevate your professional presence ✍️🎯",
                  "Compiling data to craft a tailored profile 📑🔧",
                  "Synthesizing your skills and accomplishments 🧠📋",
                  "Cross-referencing expertise to ensure accuracy 🔎📝",
                  "Polishing your profile to highlight key achievements 💎📊",
                  "Analysing career milestones to shape a compelling narrative 🗺️📚",
                  "Optimizing your credentials for maximum impact 🏆💼",
                  "Scanning for opportunities to enhance your profile ⚡🔍",
                  "Aligning your experience with growth opportunities 📈💡",
                ]}
              />
            </div>
            <div className="hidden md:block">
              {/* @ts-expect-error react version issues */}
              <Lottie
                isClickToPauseDisabled
                style={{
                  cursor: "default",
                  width: 40,
                  height: 40,
                }}
                speed={1.75}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: Stars,
                }}
              />
            </div>
          </div>
        </SectionContent>
      </Section>
    )
  }
  if (resumeFiller.data && resumeFiller.data.output) {
    return (
      <Section id={ProfileQuickAccessTabs.resume}>
        <SectionHeading
          heading={<SectionTitle title="Resume" />}
          xPadding={2}
        />
        <SectionContent className="!rounded-none !p-0">
          <div>
            <ResumeProfileFiller
              data={resumeFiller.data.output}
              open={openSheet}
              onClose={() => {
                setOpenSheet(false)
              }}
            />

            <button
              onClick={() => {
                setOpenSheet(true)
              }}
              className="flex w-full flex-col items-center justify-between gap-2 bg-interactive-500 p-2 text-start text-white sm:rounded-b-lg md:flex-row"
            >
              <Typography variant="subtitle2">
                Your resume analysis is complete! Click here to add them to your
                profile.
              </Typography>

              <span className="relative inline-flex h-5 w-full overflow-hidden rounded-full p-0.25 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 md:max-w-[200px]">
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                <span className="inline-flex size-full cursor-pointer items-center justify-center rounded-full bg-white px-3 py-1 text-sm font-medium text-interactive-500 backdrop-blur-3xl">
                  Review Items
                </span>
              </span>
            </button>
          </div>
        </SectionContent>
      </Section>
    )
  }

  if (isPublic) {
    return (
      <Section id={ProfileQuickAccessTabs.resume}>
        <SectionHeading
          heading={<SectionTitle title="Resume" />}
          xPadding={2}
        />
        <SectionContent xPadding={2}>
          <div>{PublicResumeComponent}</div>
        </SectionContent>
      </Section>
    )
  }

  return (
    <div>
      <Section
        className={PROFILE_ONBOARDING_TARGETS.resume}
        id={ProfileQuickAccessTabs.resume}
      >
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isLoggedIn && resume ? (
                  <Button variant="text">
                    <a
                      href={resume}
                      download="resume.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    onClick={() => setUploadResumeDialogOpen(true)}
                    startAdornment={<Upload />}
                  >
                    Upload
                  </Button>
                )
              }
              title="Resume"
            />
          }
          xPadding={2}
        />
        <SectionContent>
          {resume && (
            <div className="flex flex-wrap items-center justify-between gap-1">
              <div className="flex items-center space-x-1">
                <img
                  src={PDFSvg}
                  alt="pdf-icon"
                  className="h-7 w-5 shadow-[0px_3px_7.5px_0px_#0000000D]"
                />
                <div>
                  <Typography variant="strong">
                    {profile.user.firstName}&apos;s Resume
                  </Typography>
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-1.5">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      endAdornment={<NavArrowDown />}
                      size="sm"
                      rounded
                      variant="outlined"
                      className="!border-onSurface-400"
                      color="black"
                    >
                      Edit Resume
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onClick={() => {
                        setUploadResumeDialogOpen(true)
                      }}
                    >
                      Change Resume
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="text-critical-500"
                      onClick={() => mutate()}
                    >
                      Delete Resume
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          )}
          {!resume && (
            <Typography variant="smallBody" className="text-onSurface-400">
              Boost your chances and stand out to potential opportunities by
              uploading your resume!
            </Typography>
          )}
        </SectionContent>
      </Section>
      <AddResume
        open={uploadResumeDialogOpen}
        onClose={() => setUploadResumeDialogOpen(false)}
      />
    </div>
  )
}

export default Resume
