import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import { AssignmentProForm, ToolType } from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import { getToolName, proPointScaleOptions } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { AssignmentReturnType } from "../useAssignment"

const toolType = ToolType.assignmentPro
type AutoFillableFields = "grade" | "curriculum" | "subject"

type Props = Pick<
  AssignmentReturnType,
  "generateProTool" | "formData" | "preferredPromptData"
>

const ProForm = ({ generateProTool, formData, preferredPromptData }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AssignmentProForm>({
    defaultValues: formData?.proForm || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
    },
  })

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!data.book) {
      delete data.book
    }

    ensurePlusAccess(async () => {
      await generateProTool.mutateAsync({ data, setError })
    })
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData?.basicForm || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredPromptData])
  const [isEditing, setEditing] = useState(true)

  const subject = watch("subject")
  const grade = watch("grade")
  const curriculum = watch("curriculum")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                label="Class/Grade"
                placeholder="Ex: 5th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
            {(!grade || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                label="Curriculum"
                placeholder="Ex: CBSE"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          {(!grade || isEditing) && (
            <TextField
              label="Subject"
              placeholder="Ex: Environmental Studies"
              errors={errors.subject?.message}
              {...register("subject", {
                required: { value: true, message: "Required" },
              })}
            />
          )}
          <Controller
            control={control}
            name="rubricScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                errors={errors.rubricScale?.message}
                label="Rubric Point Scale"
                options={proPointScaleOptions}
                placeholder="Ex: 7"
                mountOnBody
                onBlur={onBlur}
                value={proPointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <TextField
          label="book"
          placeholder="Ex: Environmental Studies Looking Around"
          errors={errors.book?.message}
          {...register("book")}
        />
        <TextField
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
          label="Chapter/topic"
          placeholder="Ex: Environmental Impact Study"
          errors={errors.topic?.message}
        />
        <TextArea
          rows={4}
          label="Objective/Description"
          placeholder="Ex: Analyze and present the effects of human activities on the local environment, focusing on how these activities impact plants, animals, and natural resources, and suggest ways to reduce negative impacts."
          errors={errors.assignmentObjectives?.message}
          {...register("assignmentObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default ProForm
