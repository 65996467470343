import {
  ProfileStrengthCriteria,
  ProfileStrengthLevel,
} from "api/resources/profile/profileStrength/types"
import ProfileContext from "features/Profile/context"
import camelCase from "lodash/camelCase"
import { useContext, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { UserPreferenceManager } from "utils/userPreferenceManager"

export enum ActionDialogNameEnum {
  PROFILE = "profile",
  SUBJECTS = "subjects",
  WORK_INTEREST = "work_interest",
  RESUME = "resume",
  VIDEO_PORTFOLIO = "video_portfolio",
  CAREER_ASPIRATION = "career_aspiration",
  LANGUAGE = "language",
  WORK_EXPERIENCE = "work_experience",
  CERTIFICATION = "certification",
  CERTIFICATION_EVIDENCE = "certification_evidence",
  QUALIFICATION = "qualification",
  QUALIFICATION_EVIDENCE = "qualification_evidence",
}

type CarouselCardType = {
  title: string
  keyToMatchWithBackend: AdditionalRequirementKeys | null
  isDone: boolean | undefined
  action: () => void
  isRequired: boolean
}

const getNextLevel = (levelName: ProfileStrengthLevel) => {
  switch (levelName) {
    case ProfileStrengthLevel.WEAK:
      return camelCase(ProfileStrengthLevel.BASIC)
    case ProfileStrengthLevel.BASIC:
      return camelCase(ProfileStrengthLevel.STRONG)
    case ProfileStrengthLevel.STRONG:
      return camelCase(ProfileStrengthLevel.VERY_STRONG)
    case ProfileStrengthLevel.VERY_STRONG:
      return camelCase(ProfileStrengthLevel.VERY_STRONG)
    default:
      return camelCase(ProfileStrengthLevel.BASIC)
  }
}

export const getLevelName = (levelName: ProfileStrengthLevel): string => {
  switch (levelName) {
    case ProfileStrengthLevel.WEAK:
      return "Weak"
    case ProfileStrengthLevel.BASIC:
      return "Basic"
    case ProfileStrengthLevel.STRONG:
      return "Strong"
    case ProfileStrengthLevel.VERY_STRONG:
      return "Very Strong"
  }
}

enum AdditionalRequirementKeys {
  userWorkExperience = "UserWorkExperience",
  userProfileNationality = "UserProfile.nationality_id",
  userQualificationEvidence = "UserQualification.evidences",
  userCertificationEvidence = "UserCertification.evidences",
  userProfileDateOfBirth = "UserProfile.date_of_birth",
  userProfileGender = "UserProfile.gender",
  userProfileCarrierAspiration = "UserProfile.career_aspiration",
  userProfilePicture = "UserProfile.picture",
  userLanguage = "UserLanguage",
  userQualification = "UserQualification",
  userCertification = "UserCertification",
  userProfilePhoneNumber = "UserProfile.phone_number",
  userProfileYearsOfExperience = "UserProfile.years_of_experience",
  userSubjectInterestsSubjectName = "UserSubjectInterest.subject_id",
  userMiscellaneousDetailNextDesiredMilestone = "UserMiscellaneousDetail.next_desired_milestone",
  userProfileCountryId = "UserProfile.country_id",
  userProfileStateId = "UserProfile.state_id",
  userProfileCity = "UserProfile.city",
  userProfileLookingForJobs = "UserProfile.looking_for_jobs",
  preferredWorkLocationId = "PreferredWorkLocation.id",
  userProfileResume = "UserProfile.resume",
  userProfileVideoPortfolio = "UserProfile.video_portfolio",
  backgroundVerification = "background_verification",
}

function fromValue(value: string): AdditionalRequirementKeys {
  return Object.values(AdditionalRequirementKeys).find(
    key => key === value
  ) as AdditionalRequirementKeys
}

export const useProfileStrength = () => {
  const profile = useContext(ProfileContext)

  const [isActionDialogOpen, setIsActionDialogOpen] =
    useState<ActionDialogNameEnum | null>(null)

  const currentLevel =
    profile.profileStrength.data?.levelName || ProfileStrengthLevel.WEAK
  const progress = profile.profileStrength.data?.progress || 0

  const hasProfileStrengthBadge =
    currentLevel === ProfileStrengthLevel.VERY_STRONG && progress === 100

  const currentCriteria: ProfileStrengthCriteria = useMemo(
    () =>
      profile.profileStrengthCriteria
        ? profile.profileStrengthCriteria[getNextLevel(currentLevel)]
        : {
            additionalRequirements: [],
            preRequisites: [],
          },

    [profile.profileStrengthCriteria, currentLevel]
  )

  const navigate = useNavigate()

  const actionList: CarouselCardType[] = useMemo(
    () => [
      {
        title: "Finish Account Setup",
        keyToMatchWithBackend: null,
        isDone:
          UserPreferenceManager.get(
            BROWSER_STORAGE_KEYS.signupOnboardingCompleted
          ) === "true",
        action: () => {
          const link = `${routes.signUpOnboarding}?utm_source=profile-strength-action&next=${window.location.pathname}`
          navigate(link)
        },
        isRequired: true,
      },
      {
        title: "Add Phone Number",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfilePhoneNumber
        ),
        isDone: Boolean(profile.profile.phoneNumber),
        action: () => {
          const link = `${routes.accountDetails}?highlight-element-id=phone-number&utm_source=profile-strength-action`
          navigate(link)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfilePhoneNumber)
        ),
      },
      {
        title: "Add Date Of Birth",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileDateOfBirth
        ),
        isDone: Boolean(profile.profile.dateOfBirth),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.PROFILE)
        },
        isRequired: Boolean(
          currentCriteria.additionalRequirements.includes(
            fromValue(AdditionalRequirementKeys.userProfileDateOfBirth)
          )
        ),
      },
      {
        title: "Add Current Location",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileCountryId
        ),
        isDone:
          Boolean(profile.profile.country) && Boolean(profile.profile.state),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.PROFILE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileCountryId)
        ),
      },
      {
        title: "Add Subject Interest",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userSubjectInterestsSubjectName
        ),
        isDone: profile.subjects.data.length > 0,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.SUBJECTS)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userSubjectInterestsSubjectName)
        ),
      },
      {
        title: "Are You looking for Jobs?",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileLookingForJobs
        ),
        isDone:
          profile.profile.lookingForJobs !== null ||
          profile.profile.lookingForJobs !== undefined,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.PROFILE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileLookingForJobs)
        ),
      },
      {
        title: "Add Work Location Interests",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.preferredWorkLocationId
        ),
        isDone: profile.workLocationInterest.data.length > 0,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.WORK_INTEREST)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.preferredWorkLocationId)
        ),
      },
      {
        title: "Tell viewers about yourself",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileCarrierAspiration
        ),
        isDone: profile.profile.careerAspiration !== null,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.CAREER_ASPIRATION)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileCarrierAspiration)
        ),
      },
      {
        title: "Add Qualification",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userQualification
        ),
        isDone: profile.academics.qualifications.data.length > 0,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.QUALIFICATION)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userQualification)
        ),
      },
      {
        title: "Add Profile Picture",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfilePicture
        ),
        isDone: profile.profile.picture !== null,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.PROFILE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfilePicture)
        ),
      },
      {
        title: "Add Gender",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileGender
        ),
        isDone: Boolean(profile.profile.gender),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.PROFILE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileGender)
        ),
      },
      {
        title: "Add Nationality",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileNationality
        ),
        isDone: Boolean(profile.profile.nationality),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.PROFILE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileNationality)
        ),
      },
      {
        title: "Add Language Proficiency",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userLanguage
        ),
        isDone: Boolean(profile.languages.data.length),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.LANGUAGE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userLanguage)
        ),
      },
      {
        title: "Add Work Experience",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userWorkExperience
        ),
        isDone: profile.workExperiences.data.length > 0,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.WORK_EXPERIENCE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userWorkExperience)
        ),
      },
      {
        title: "Add Certification",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userCertification
        ),
        isDone: profile.academics.certifications.data.length > 0,
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.CERTIFICATION)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userCertification)
        ),
      },
      {
        title: "Add Qualification Evidence",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userQualificationEvidence
        ),
        isDone:
          profile.academics.qualifications.data.length > 0 &&
          profile.academics.qualifications.data
            .filter(el => el.isVerified === false)
            .every(el => el.evidences !== undefined && el.evidences.length > 0),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.QUALIFICATION_EVIDENCE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userQualificationEvidence)
        ),
      },
      {
        title: "Add Certification Evidence",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userCertificationEvidence
        ),
        isDone:
          profile.academics.certifications.data.length > 0 &&
          profile.academics.certifications.data
            .filter(el => el.isVerified === false)
            .every(el => el.evidences !== undefined && el.evidences.length > 0),

        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.CERTIFICATION_EVIDENCE)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userCertificationEvidence)
        ),
      },
      {
        title: "Add Resume",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileResume
        ),
        isDone: Boolean(profile.profile.resume),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.RESUME)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileResume)
        ),
      },

      {
        title: "Add Video Portfolio",
        keyToMatchWithBackend: fromValue(
          AdditionalRequirementKeys.userProfileVideoPortfolio
        ),
        isDone: Boolean(profile.videoPortfolioURL.data),
        action: () => {
          setIsActionDialogOpen(ActionDialogNameEnum.VIDEO_PORTFOLIO)
        },
        isRequired: currentCriteria.additionalRequirements.includes(
          fromValue(AdditionalRequirementKeys.userProfileVideoPortfolio)
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentCriteria.additionalRequirements,
      profile.academics.certifications,
      profile.academics.qualifications,
      profile.languages.data.length,
      profile.profile.careerAspiration,
      profile.profile.country,
      profile.profile.dateOfBirth,
      profile.profile.gender,
      profile.profile.lookingForJobs,
      profile.profile.nationality,
      profile.profile.phoneNumber,
      profile.profile.picture,
      profile.profile.resume,
      profile.profile.state,
      profile.subjects.data.length,
      profile.videoPortfolioURL.data,
      profile.workExperiences.data.length,
      profile.workLocationInterest.data.length,
      // navigate,
    ]
  )

  const stepsToAdvance: CarouselCardType[] = useMemo(() => {
    const filteredActions = actionList.filter(action => !action.isDone)

    const sortedActions = filteredActions.sort((a, b) => {
      const isARequired = a.isRequired ? 1 : 0
      const isBRequired = b.isRequired ? 1 : 0
      return isBRequired - isARequired
    })

    return sortedActions.slice(0, 3)
  }, [actionList])

  const hasIncompleteRequiredActions = useMemo(
    () =>
      stepsToAdvance.length > 0
        ? stepsToAdvance.some(item => item?.isRequired && !item?.isDone)
        : false,
    [stepsToAdvance]
  )

  const onCloseDialog = () => {
    setIsActionDialogOpen(null)
  }

  const dialog = {
    action: isActionDialogOpen,
    close: onCloseDialog,
    open: setIsActionDialogOpen,
  }

  return {
    stepsToAdvance,
    currentLevel,
    progress,
    hasIncompleteRequiredActions,
    dialog,
    hasProfileStrengthBadge,
    isProfileStrengthLoading: profile.isPollingProfileStrength,
  }
}
