import {
  Button,
  Checkbox,
  CreatableSelect,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Divider,
  Select,
  SplitTextField,
  TextArea,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Country, State } from "api/resources/global/types"
import {
  OrganisationType,
  WorkExperienceType,
} from "api/resources/profile/types"
import { Config } from "api/types"
import LoadingOverlay from "components/LoadingOverlay"
import pick from "lodash/pick"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ActionMeta } from "react-select"
import { getAuthInfo } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"
import { trackingService } from "utils/tracking"

import RemoveDialog from "@/common/RemoveDialog"

import ProfileContext from "../../context"
import { defaultOrganizationOptions, employmentTypeOptions } from "./utils"

const formDataKeys = [
  "title",
  "description",
  "organisationName",
  "currentlyWorking",
  "startDate",
  "endDate",
  "currencyId",
  "salary",
  "employmentType",
  "subjects",
  "organisationType",
  "curriculumId",
  "teachingLevelId",
  "teachingRoleId",
  "curriculum",
  "teachingLevel",
  "teachingRole",
  "countryId",
  "stateId",
  "otherOrganisationType",
  "otherCurriculum",
  "otherTeachingLevel",
  "otherTeachingRole",
] as const

type FormData = Omit<WorkExperienceType, "subjects" | "curriculum"> & {
  subjects?: string[] | null
  curriculumId: string | null
  teachingLevelId: string | null
  teachingRoleId: string | null
  otherTeachingLevel: string | null
  otherTeachingRole: string | null
  otherCurriculum: string | null
}

type Option = {
  label: string
  value: string
  isCustom?: boolean
}

type Props = Pick<DialogProps, "open"> & {
  id: WorkExperienceType["id"] | null
  handleClose: () => void
  source?: string
}

const EditDialog = ({
  id,
  open,
  onUpdate,
  handleClose: toggle,
  source,
}: Props & { onUpdate: () => void }) => {
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors, isSubmitting },
    unregister,
    resetField,
  } = useForm<FormData>()

  const authInfo = getAuthInfo()

  const { subjects, countries, currencies } = useResource([
    "subjects",
    "countries",
    "currencies",
  ])

  const currencyOptions = currencies.map(({ code, uuid, name }) => ({
    label: `(${code}) ${name}`,
    value: uuid,
  }))

  const {
    workExperiences,
    academics: { qualifications, certifications },
  } = useContext(ProfileContext)

  const [organisationTypeOptions, setOrganisationTypeOptions] = useState<
    {
      label: string
      value: string
    }[]
  >(defaultOrganizationOptions)
  /**
   * undefined --> options are being fetched(loading...)
   * null --> default || api call failed || options don't exist
   */
  const [states, setStates] = useState<State[] | null | undefined>(null)
  const [curriculumIdOptions, setCurriculumIdOptions] = useState<
    Option[] | null | undefined
  >(null)
  const [teachingLevelIdOptions, setTeachingLevelIdOptions] = useState<
    Option[] | null | undefined
  >(null)
  const [teachingRoleIdOptions, setTeachingRoleIdOptions] = useState<
    Option[] | null | undefined
  >(null)

  const [loading, setLoading] = useState(false)

  const isEditable = Boolean(id)
  const organisationType = watch("organisationType")

  const orgTypeIsCustom = !defaultOrganizationOptions.some(
    e => e.value === organisationType
  )

  const showSubjects = organisationType === undefined ? true : !orgTypeIsCustom

  /**
   * Creates options for these keys
   * otherOrganisationType,otherTeachingLevel,* otherTeachingRole
   */
  const createOtherOptions = (
    key: keyof Pick<
      FormData,
      "otherOrganisationType" | "otherTeachingLevel" | "otherTeachingRole"
    >,
    option: Option
  ) => {
    // const option: Option = { label: value, value }

    switch (key) {
      case "otherOrganisationType":
        setOrganisationTypeOptions(prevState => [...prevState, option])
        break
      case "otherTeachingLevel":
        setTeachingLevelIdOptions(prevState => [...(prevState ?? []), option])
        break
      case "otherTeachingRole":
        setTeachingRoleIdOptions(prevState => [...(prevState ?? []), option])
        break
      default:
        break
    }
  }
  const fetchStates = async (countryId: Country["uuid"]) => {
    setStates(undefined)
    setValue("stateId", null)
    const res = await api.global.listStates({
      urlParams: {
        countryId,
      },
      params: {
        page: -1,
      },
    })
    if (res.isSuccessful) {
      setStates(res.data)
    } else {
      setStates(null)
    }
  }

  const fetchTeachingLevels = async ({ params }: Config["params"]) => {
    setTeachingLevelIdOptions(undefined)
    const res = await api.global.teachingLevel.list({
      params,
    })
    if (res.isSuccessful) {
      setTeachingLevelIdOptions(s => [
        ...(s ?? []),
        ...res.data.map(item => ({ label: item.name, value: item.uuid })),
      ])
    } else {
      setTeachingLevelIdOptions(null)
    }
    return res
  }

  const fetchTeachingRoles = async ({ params }: Config["params"]) => {
    setTeachingRoleIdOptions(undefined)
    const res = await api.global.teachingRole.list({
      params,
    })
    if (res.isSuccessful) {
      setTeachingRoleIdOptions(
        res.data.map(item => ({ label: item.name, value: item.uuid }))
      )
    } else {
      setTeachingRoleIdOptions([])
    }
    return res
  }

  const fetchCurricula = async (option?: Option) => {
    setCurriculumIdOptions(undefined)
    const res = await api.global.curriculum.list({ params: { page: -1 } })
    if (res.isSuccessful) {
      const data = res.data.map(item => ({
        label: item.name,
        value: item.uuid,
      }))
      if (option) {
        data.push(option)
      }
      setCurriculumIdOptions(data)
    }
  }

  const setCurrency = async (countryID: FormData["countryId"]) => {
    try {
      const res = await api.global.listCurrencies({
        params: {
          countryID,
        },
      })
      if (res[0]) {
        setValue("currencyId", res[0].uuid)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!isEditable)
      if (organisationType === OrganisationType.SCHOOL) {
        fetchCurricula()
      }
  }, [organisationType, isEditable])

  useEffect(() => {
    if (orgTypeIsCustom) {
      unregister("subjects")
      resetField("subjects")
    } else {
      register("subjects")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgTypeIsCustom])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await api.profile.workExperiences.retrieve({
        urlParams: { id: id || "" },
      })
      if (res.isSuccessful) {
        const data = pick(res.data, formDataKeys)

        if (data.otherOrganisationType) {
          /**
           * We are using otherOrganisationType as organisationType
           * because the <Select> is hooked to 'organisationType' and we need to
           * display 'otherOrganisationType' as its value
           */
          // @ts-expect-error if user types other option then it will be a string
          data.organisationType = data.otherOrganisationType
          createOtherOptions("otherOrganisationType", {
            label: data.otherOrganisationType,
            value: data.otherOrganisationType,
          })
        }

        const { curriculum } = data
        let isCurriculumCustom = false
        if (curriculum) {
          // This means that curriculum was custom
          if (curriculum.isApproved === false) {
            isCurriculumCustom = true

            fetchCurricula({
              value: curriculum.uuid,
              label: curriculum.name,
            })
            await fetchTeachingLevels({
              params: { curriculum__is_default: true },
            })
          } else {
            fetchCurricula()
            await fetchTeachingLevels({
              params: { curriculumID: curriculum.uuid },
            })
          }
        }

        const { teachingLevel } = data
        let isTLCustom = false

        if (teachingLevel) {
          // This means that Teaching Level was custom
          if (
            !teachingLevelIdOptions?.find(
              ({ value }) => value === teachingLevel.uuid
            )
          ) {
            isTLCustom = true
            createOtherOptions("otherTeachingLevel", {
              label: teachingLevel.name,
              value: teachingLevel.uuid,
            })
          }
          if (isCurriculumCustom && !isTLCustom)
            await fetchTeachingRoles({
              params: {
                curriculum__is_default: true,
                teachingLevelID: teachingLevel.uuid,
              },
            })

          if (!isCurriculumCustom && !isTLCustom)
            await fetchTeachingRoles({
              params: {
                curriculumID: curriculum?.uuid,
                teachingLevelID: teachingLevel.uuid,
              },
            })
        }

        const { teachingRole } = data
        if (teachingRole) {
          createOtherOptions("otherTeachingRole", {
            label: teachingRole.name,
            value: teachingRole.uuid,
          })
        }

        if (data.countryId) {
          fetchStates(data.countryId)
        }

        reset({
          ...data,
          curriculumId: curriculum?.uuid,
          teachingLevelId: teachingLevel?.uuid,
          teachingRoleId: teachingRole?.uuid,
          subjects: data.subjects ? data.subjects.map(i => i.subjectId) : [],
        })
      }
      setLoading(false)
    }

    if (isEditable) {
      fetchData()
    } else {
      reset({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditable, reset, setLoading])

  const formatData = (data: FormData) => {
    if (data.curriculumId === undefined) {
      data.curriculumId = null
    }
    if (data.teachingLevelId === undefined) {
      data.teachingLevelId = null
    }
    if (data.teachingRoleId === undefined) {
      data.teachingRoleId = null
    }

    if (orgTypeIsCustom) {
      delete data.subjects
    }

    return {
      ...data,
      organisationType: orgTypeIsCustom ? null : data.organisationType,
      currentlyWorking: Boolean(data.currentlyWorking),
      endDate: data.currentlyWorking ? null : data.endDate,

      curriculumId: data.curriculumId,
      teachingLevelId: data.teachingLevelId,
      teachingRoleId: data.teachingRoleId,

      otherOrganisationType: orgTypeIsCustom
        ? data.otherOrganisationType
        : null,

      otherCurriculum: data.otherCurriculum ? data.otherCurriculum : null,

      otherTeachingLevel: data.otherTeachingLevel
        ? data.otherTeachingLevel
        : null,

      otherTeachingRole: data.otherTeachingRole ? data.otherTeachingRole : null,
    }
  }

  const createCurriculumBoard = (curriculum: string) =>
    api.global.curriculum.create({
      data: {
        new: [
          {
            curriculum,
            userUuid: authInfo?.user.uuid,
          },
        ],
      },
    })
  const createTeachingLevel = (teachingLevel: string) =>
    api.global.teachingLevel.create({
      data: {
        new: [
          {
            teachingLevel,
            curriculumId: getValues("curriculumId") || undefined,
            userUuid: authInfo?.user.uuid,
          },
        ],
      },
    })
  const createTeachingRole = (teachingRole: string) =>
    api.global.teachingRole.create({
      data: {
        new: [
          {
            teachingRole,
            teachingLevelId: getValues("teachingLevelId") || undefined,
            curriculumId: getValues("curriculumId") || undefined,
            userUuid: authInfo?.user.uuid,
          },
        ],
      },
    })

  const showHideSkillsAlert = ![
    workExperiences.data.length,
    qualifications.data.length,
    certifications.data.length,
  ].some(length => length > 1)

  const handleRemove = async () => {
    if (!id) return

    const res = await api.profile.workExperiences.delete({ urlParams: { id } })

    if (res.isSuccessful) {
      workExperiences.refetch()
      onUpdate()
      toggle()
    } else if (res.errors.message) {
      toast.error(res.errors.message)
    }
  }

  const getCBParams = (): any => {
    const params: any = {}

    if (getValues("curriculumId") === getValues("otherCurriculum")) {
      params.curriculum__is_default = true
    } else {
      params.curriculumID = getValues("curriculumId")
    }
    return params
  }

  const handleOrganizationSchoolFields = (
    option: Option | null,

    actionMeta: ActionMeta<Option>
  ) => {
    switch (
      actionMeta.name as keyof Pick<
        WorkExperienceType,
        "curriculumId" | "teachingLevelId" | "teachingRoleId"
      >
    ) {
      case "curriculumId":
        if (actionMeta.action === "create-option") {
          createCurriculumBoard(actionMeta.option.value).then(res => {
            if (res.isSuccessful) {
              const { name, uuid } = res.data[0]
              setValue("otherCurriculum", uuid)
              setValue("curriculumId", uuid)
              setCurriculumIdOptions(
                i => i && [{ label: name, value: uuid, isCustom: true }, ...i]
              )
              fetchTeachingLevels({ params: getCBParams() })
            }
          })
        }

        if (actionMeta.action === "select-option") {
          setValue("curriculumId", option?.value ?? null)
          setValue("otherCurriculum", null)
          setCurriculumIdOptions(c => c?.filter(i => !i.isCustom))
          fetchTeachingLevels({
            params: getCBParams(),
          })
        }

        if (actionMeta.action === "clear") {
          setValue("otherCurriculum", null)
          setValue("curriculumId", null)
          setCurriculumIdOptions(c => c?.filter(i => !i.isCustom))
          setTeachingLevelIdOptions([])
          setTeachingRoleIdOptions([])
        }
        break

      case "teachingLevelId":
        if (actionMeta.action === "create-option") {
          createTeachingLevel(actionMeta.option.value).then(res => {
            if (res.isSuccessful) {
              const { name, uuid } = res.data[0]
              setValue("teachingLevelId", uuid)
              setValue("otherTeachingLevel", uuid)
              setTeachingLevelIdOptions(
                i => i && [{ label: name, value: uuid, isCustom: true }, ...i]
              )
              setTeachingRoleIdOptions([])
            }
          })
        }

        if (actionMeta.action === "select-option") {
          setValue("teachingLevelId", option?.value ?? null)
          setValue("otherTeachingLevel", null)

          setTeachingLevelIdOptions(c => c?.filter(i => !i.isCustom))
          fetchTeachingRoles({
            params: {
              ...getCBParams(),
              teachingLevelID: getValues("teachingLevelId"),
            },
          })
        }

        if (actionMeta.action === "clear") {
          setValue("teachingLevelId", null)
          setValue("otherTeachingLevel", null)
          setTeachingLevelIdOptions(c => c?.filter(i => !i.isCustom))
          setTeachingRoleIdOptions([])
        }
        break

      case "teachingRoleId":
        if (actionMeta.action === "create-option") {
          createTeachingRole(actionMeta.option.value).then(res => {
            if (res.isSuccessful) {
              const { name, uuid } = res.data[0]
              setValue("teachingRoleId", uuid)
              setValue("otherTeachingRole", uuid)
              setTeachingRoleIdOptions(
                i => i && [{ label: name, value: uuid, isCustom: true }, ...i]
              )
            }
          })
        }

        if (actionMeta.action === "select-option") {
          setValue("teachingRoleId", option?.value ?? null)
          setValue("otherTeachingRole", null)
          setTeachingRoleIdOptions(c => c?.filter(i => !i.isCustom))
        }

        if (actionMeta.action === "clear") {
          setValue("teachingRoleId", null)
          setValue("otherTeachingRole", null)
          setTeachingLevelIdOptions(c => c?.filter(i => !i.isCustom))
        }
        break

      default:
        break
    }

    return null
  }

  const currencyId = watch("currencyId")

  const onSubmit = handleSubmit(async formData => {
    clearErrors("currencyId")
    clearErrors("salary")

    if (!formData.salary) {
      formData.salary = null
      formData.currencyId = null
    }

    if (id) {
      const res = await api.profile.workExperiences.update({
        data: formatData(formData),
        urlParams: { id },
      })
      if (res.isSuccessful) {
        workExperiences.refetch()
        onUpdate()
        return toggle()
      }

      if (res.errors.message) toast.error(res.errors.message)
      if (res.errors.fieldErrors)
        for (const [key, value] of Object.entries(
          pick(res.errors.fieldErrors, formDataKeys)
        )) {
          // @ts-expect-error TODO(profile): test this
          setError(key as keyof FormData, { message: value })
        }

      if (res.is500) {
        toast.error("Something went wrong", {
          description: "Please contact care@suraasa.com",
        })
      }
    } else {
      const res = await api.profile.workExperiences.create({
        data: formatData(formData),
      })
      if (res.isSuccessful) {
        trackingService.trackEvent("profile_work_experience_added", {
          source,
        })
        workExperiences.refetch()
        onUpdate()
        return toggle()
      }
      if (res.errors.message) toast.error(res.errors.message)
      if (res.errors.fieldErrors)
        for (const [key, value] of Object.entries(res.errors.fieldErrors)) {
          // @ts-expect-error TODO(profile): test this
          setError(key as keyof FormData, { message: value })
        }

      if (res.is500) {
        toast.error("Something went wrong", {
          description: "Please contact care@suraasa.com",
        })
      }
    }
  })

  const TitleAndDescription = () => (
    <>
      <TextField
        errors={errors.title?.message}
        required
        label="Title"
        placeholder="Ex: Head of Department"
        {...register("title", {
          required: { value: true, message: "Required" },
        })}
      />

      <Controller
        control={control}
        name="employmentType"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            errors={errors.employmentType?.message}
            required
            label="Employment Type"
            ref={ref}
            options={employmentTypeOptions}
            value={value && employmentTypeOptions.find(c => c.value === value)}
            isClearable
            onBlur={onBlur}
            onChange={val => onChange(val?.value)}
          />
        )}
        rules={{
          required: { value: true, message: "Required" },
        }}
      />
      {showSubjects && (
        <Controller
          control={control}
          name="subjects"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              errors={errors.subjects?.message}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ uuid }) => uuid}
              required
              ref={ref}
              label="subjects"
              options={subjects}
              value={value && subjects.filter(c => value.includes(c.uuid))}
              isClearable
              isMulti
              onBlur={onBlur}
              onChange={val => onChange(val?.map(item => item.uuid))}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      )}
      <TextArea
        errors={errors.description?.message}
        label="Description"
        rows={3}
        {...register("description", {
          maxLength: {
            value: 400,
            message: "Try to keep it short",
          },
        })}
      />
    </>
  )

  const currentlyWorking = watch("currentlyWorking") || false

  const curriculumId = watch("curriculumId")
  const teachingLevelId = watch("teachingLevelId")

  return (
    <>
      <Dialog open={open} onOpenChange={toggle}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle onBack={toggle} className="text-strong">
              {isEditable ? "Edit" : "Add"} Work Experience
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            <form className="flex flex-col gap-3" onSubmit={onSubmit}>
              {loading && <LoadingOverlay />}

              <TitleAndDescription />
              <div className="my-1">
                <Divider weight="medium" />
              </div>

              <TextField
                errors={errors.organisationName?.message}
                required
                label="Organisation/institute name"
                placeholder="Ex: Harvard University"
                {...register("organisationName", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
              />

              <Controller
                control={control}
                name="organisationType"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CreatableSelect
                    errors={errors.organisationType?.message}
                    required
                    label="organisation type"
                    options={organisationTypeOptions}
                    value={
                      value
                        ? organisationTypeOptions.find(c => c.value === value)
                        : null
                    }
                    ref={ref}
                    isClearable
                    onBlur={onBlur}
                    onChange={(option, actionMeta) => {
                      onChange(option?.value)

                      clearErrors("curriculumId")
                      clearErrors("teachingLevelId")
                      clearErrors("teachingRoleId")

                      if (actionMeta.action === "create-option") {
                        if (option) {
                          setOrganisationTypeOptions(i => [option, ...i])
                          setValue(
                            "otherOrganisationType",
                            String(option.value)
                          )
                        }
                      }
                      if (actionMeta.action === "clear") {
                        setOrganisationTypeOptions(defaultOrganizationOptions)
                        setValue("otherOrganisationType", null)
                      }
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              {organisationType === OrganisationType.SCHOOL && (
                <>
                  <Controller
                    control={control}
                    name="curriculumId"
                    render={({ field: { onBlur, value, ref } }) => (
                      <CreatableSelect
                        errors={errors.curriculumId?.message}
                        required
                        isLoading={curriculumIdOptions === undefined}
                        label="Curriculum Board"
                        name="curriculumId"
                        options={curriculumIdOptions ?? []}
                        placeholder="Ex: IB"
                        value={
                          value
                            ? curriculumIdOptions &&
                              curriculumIdOptions.find(c => c.value === value)
                            : null
                        }
                        ref={ref}
                        isClearable
                        onBlur={onBlur}
                        onChange={(option, actionMeta) => {
                          setValue("teachingLevelId", "")
                          setValue("teachingRoleId", "")
                          handleOrganizationSchoolFields(option, actionMeta)
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />
                  <Controller
                    control={control}
                    name="teachingLevelId"
                    render={({ field: { onBlur, value, ref } }) => (
                      <CreatableSelect
                        errors={errors.teachingLevelId?.message}
                        required
                        isDisabled={!curriculumId}
                        isLoading={teachingLevelIdOptions === undefined}
                        label="teaching level"
                        name="teachingLevelId"
                        options={teachingLevelIdOptions ?? []}
                        placeholder="Ex: Senior Secondary"
                        value={
                          value
                            ? teachingLevelIdOptions &&
                              teachingLevelIdOptions.find(
                                c => c.value === value
                              )
                            : null
                        }
                        ref={ref}
                        isClearable
                        onBlur={onBlur}
                        onChange={(option, actionMeta) => {
                          setValue("teachingRoleId", "")
                          handleOrganizationSchoolFields(option, actionMeta)
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />
                  <Controller
                    control={control}
                    name="teachingRoleId"
                    render={({ field: { onBlur, value, ref } }) => (
                      <CreatableSelect
                        errors={errors.teachingRoleId?.message}
                        required
                        isDisabled={!teachingLevelId || !curriculumId}
                        isLoading={teachingRoleIdOptions === undefined}
                        label="teaching role"
                        name="teachingRoleId"
                        options={teachingRoleIdOptions ?? []}
                        placeholder="Ex: Class Teacher"
                        value={
                          value
                            ? teachingRoleIdOptions &&
                              teachingRoleIdOptions.find(c => c.value === value)
                            : null
                        }
                        ref={ref}
                        isClearable
                        onBlur={onBlur}
                        onChange={(option, actionMeta) => {
                          handleOrganizationSchoolFields(option, actionMeta)
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />
                </>
              )}

              <div className="flex flex-col gap-3 sm:flex-row">
                <Controller
                  control={control}
                  name="countryId"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      errors={errors.countryId?.message}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      required
                      label="country"
                      options={countries}
                      value={
                        value ? countries.find(c => c.uuid === value) : null
                      }
                      isClearable
                      mountOnBody
                      onBlur={onBlur}
                      ref={ref}
                      onChange={val => {
                        if (val) {
                          fetchStates(val.uuid)

                          if (!currencyId) setCurrency(val.uuid)
                        }
                        onChange(val ? val.uuid : "")
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                <Controller
                  control={control}
                  name="stateId"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      errors={errors.stateId?.message}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ uuid }) => uuid}
                      required
                      isLoading={states === undefined}
                      label="State/Region/Province"
                      options={states ?? []}
                      placeholder="Ex: Dubai"
                      value={
                        value
                          ? states && states.find(c => c.uuid === value)
                          : null
                      }
                      ref={ref}
                      isClearable
                      mountOnBody
                      onBlur={onBlur}
                      onChange={val => onChange(val ? val.uuid : "")}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </div>
              <div className="my-1">
                <Divider weight="medium" />
              </div>
              <span>
                <Controller
                  control={control}
                  name="currentlyWorking"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="flex items-center space-x-1">
                      <Checkbox
                        checked={value}
                        onBlur={onBlur}
                        onCheckedChange={onChange}
                      />
                      <Typography variant="smallBody">
                        Currently Working
                      </Typography>
                    </div>
                  )}
                />
              </span>

              <div className="flex flex-col gap-3 sm:flex-row">
                <TextField
                  errors={errors.startDate?.message}
                  required
                  label="start date"
                  placeholder="Ex: Jan 2021"
                  type="date"
                  {...register("startDate", {
                    required: { value: true, message: "Required" },
                  })}
                />
                <TextField
                  disabled={currentlyWorking}
                  errors={errors.endDate?.message}
                  required={!currentlyWorking}
                  label="end date"
                  placeholder="Ex: May 2021"
                  type="date"
                  {...register("endDate", {
                    required: { value: !currentlyWorking, message: "Required" },
                  })}
                />
              </div>

              <Controller
                control={control}
                name="salary"
                render={({ field }) => (
                  <SplitTextField
                    fullWidth
                    selectSize="200px"
                    label="Salary P.A. (will not show on your profile)"
                    error={Boolean(errors.currencyId) || Boolean(errors.salary)}
                    helperText={
                      errors.currencyId?.message || errors.salary?.message
                    }
                    selectProps={{
                      isClearable: true,
                      placeholder: "Currency...",
                      options: currencyOptions,
                      value: currencyOptions.find(c => c.value === currencyId),
                      onChange: val => {
                        clearErrors("salary")
                        clearErrors("currencyId")
                        setValue("currencyId", val?.value || null)
                      },
                      menuPlacement: "top",
                    }}
                    textFieldProps={{
                      type: "number",
                      placeholder: "20,000",
                      value: field?.value ?? undefined,
                      onChange: val => {
                        clearErrors("salary")
                        clearErrors("currencyId")
                        // @ts-expect-error this is expected
                        setValue("salary", val.target.value)
                      },
                    }}
                  />
                )}
              />
            </form>
          </DialogBody>
          <DialogFooter className="flex flex-row justify-between">
            <RemoveDialog
              onRemove={handleRemove}
              title="Remove Work Experience"
              trigger={
                <Button variant="text" color="critical">
                  Remove
                </Button>
              }
            >
              Are you sure you want to remove{" "}
              <b>{workExperiences.data.find(item => item.id === id)?.title}</b>{" "}
              from your profile?
            </RemoveDialog>

            <div>
              <Button
                variant="outlined"
                className="border-muted text-muted"
                size="sm"
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                loading={isSubmitting}
                onClick={onSubmit}
                className="ml-1.5 py-1.25"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditDialog
