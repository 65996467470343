import { Typography } from "@suraasa/placebo-ui"
import { Tag } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"
import { learningItemSlugs } from "utils/constants"

const data = [
  {
    gpaRange: "9.5 to 10.0",
    grade: "A+",
    gradePoint: 10,
    description: "Exceptional",
    explanation:
      "Demonstrates exceptional mastery and understanding, delivering insightful, high quality work beyond basic requirements.",
  },
  {
    gpaRange: "9.0 to <9.5",
    grade: "A",
    gradePoint: 9.5,
    description: "Outstanding",
    explanation:
      "Indicates strong understanding and high quality execution meeting all key expectations without errors.",
  },
  {
    gpaRange: "8.5 to <9.0",
    grade: "B+",
    gradePoint: 9,
    description: "Excellent",
    explanation:
      "Reflects above-average proficiency with well executed work that covers core requirements with minor issues.",
  },
  {
    gpaRange: "8.0 to <8.5",
    grade: "B",
    gradePoint: 8.5,
    description: "Very Good",
    explanation:
      "Shows a solid understanding, meeting the core requirements but lacking depth or precision in some areas.",
  },
  {
    gpaRange: "7.0 to <8.0",
    grade: "C",
    gradePoint: 8,
    description: "Good",
    explanation:
      "Indicates adequate understanding with basic requirements met but evident room for improvement.",
  },
  {
    gpaRange: "6.0 to <7.0",
    grade: "D",
    gradePoint: 7,
    description: "Average",
    explanation:
      "Meets some requirements but lacks consistency, requiring substantial improvement.",
  },
  {
    gpaRange: "5.0 to <6.0",
    grade: "E",
    gradePoint: 6,
    description: "Pass",
    explanation:
      "Minimal understanding of the subject; work has significant deficiencies and requires further improvement.",
  },
  {
    gpaRange: "0 to <5.0",
    grade: "F",
    gradePoint: 0,
    description: "Fail",
    explanation:
      "Indicates insufficient understanding and failure to meet basic requirements. Work is incomplete or lacks coherence.",
  },
]

const medData = [
  {
    gpaRange: "3.8 to 4.0",
    grade: "A+",
    gradePoint: 4.0,
    description: "Exceptional",
    explanation:
      "Demonstrates exceptional mastery and understanding, delivering insightful, high quality work beyond basic requirements.",
  },
  {
    gpaRange: "3.6 to <3.8",
    grade: "A",
    gradePoint: 3.8,
    description: "Outstanding",
    explanation:
      "Indicates strong understanding and high quality execution meeting all key expectations without errors.",
  },
  {
    gpaRange: "3.4 to <3.6",
    grade: "B+",
    gradePoint: 3.6,
    description: "Excellent",
    explanation:
      "Reflects above-average proficiency with well executed work that covers core requirements with minor issues.",
  },
  {
    gpaRange: "3.2 to <3.4",
    grade: "B",
    gradePoint: 3.4,
    description: "Very Good",
    explanation:
      "Shows a solid understanding, meeting the core requirements but lacking depth or precision in some areas.",
  },
  {
    gpaRange: "2.8 to <3.2",
    grade: "C",
    gradePoint: 3.2,
    description: "Good",
    explanation:
      "Indicates adequate understanding with basic requirements met but evident room for improvement.",
  },
  {
    gpaRange: "2.4 to <2.8",
    grade: "D",
    gradePoint: 2.8,
    description: "Average",
    explanation:
      "Meets some requirements but lacks consistency, requiring substantial improvement.",
  },
  {
    gpaRange: "2.0 to <2.4",
    grade: "E",
    gradePoint: 2.4,
    description: "Pass",
    explanation:
      "Minimal understanding of the subject; work has significant deficiencies and requires further improvement.",
  },
  {
    gpaRange: "0 to <2.0",
    grade: "F",
    gradePoint: 0,
    description: "Fail",
    explanation:
      "Indicates insufficient understanding and failure to meet basic requirements. Work is incomplete or lacks coherence.",
  },
]

const AcademicsGuide = ({
  className,
  slug,
}: {
  slug: string
  className?: string
}) => {
  const headerData = [
    "GPA Range",
    "Grade",
    "Grade Points",
    "Description",
    "Explanation",
  ]

  let tableData = data

  if (slug === learningItemSlugs.med) tableData = medData

  return (
    <div className={className}>
      <Typography variant="title4" className="mb-2">
        Understanding Your Academic Grades
      </Typography>
      <div className="hidden overflow-hidden rounded-lg border border-onSurface-200 md:block">
        <table>
          <thead className="text-left text-xs font-semibold leading-[15.6px]">
            <tr>
              {headerData.map(item => (
                <th
                  key={item}
                  className="w-max text-nowrap border-b border-r border-onSurface-200 bg-onSurface-100 px-1.5 py-1.25 text-xs font-semibold leading-[15.6px] text-onSurface-800"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index} className="bg-[#FFFFFF01] ">
                <td
                  className={clsx(
                    "text-nowrap  border border-l-0 border-onSurface-200 bg-onSurface-50 px-1.5 py-1.25 text-xs font-normal leading-[15.6px]",
                    { "border-b-0": index === tableData.length - 1 }
                  )}
                >
                  {item.gpaRange}
                </td>
                <td
                  className={clsx(
                    "text-nowrap border border-onSurface-200 bg-onSurface-50 px-1.5 py-1.25 text-xs font-normal leading-[15.6px]",
                    {
                      "border-b-0": index === tableData.length - 1,
                    }
                  )}
                >
                  {item.grade}
                </td>
                <td
                  className={clsx(
                    "text-nowrap border border-onSurface-200 bg-onSurface-50 px-1.5 py-1.25 text-xs font-normal leading-[15.6px]",
                    {
                      "border-b-0": index === tableData.length - 1,
                    }
                  )}
                >
                  {item.gradePoint}
                </td>
                <td
                  className={clsx(
                    "text-nowrap border border-onSurface-200 bg-onSurface-50 px-1.5 py-1.25 text-xs font-normal leading-[15.6px]",
                    {
                      "border-b-0": index === tableData.length - 1,
                    }
                  )}
                >
                  {item.description}
                </td>
                <td
                  className={clsx(
                    "border border-r-0 border-onSurface-200 bg-onSurface-50 px-1.5 py-1.25 text-xs font-normal leading-[15.6px]",
                    { "border-b-0": index === tableData.length - 1 }
                  )}
                >
                  {item.explanation}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className="flex flex-col divide-y divide-onSurface-200 overflow-hidden rounded-lg border border-onSurface-200
       md:hidden"
      >
        {tableData.map((item, index) => (
          <div className="p-2" key={index}>
            <div className="mb-0.5 flex items-center justify-between">
              <Typography variant="smallBody" className="text-onSurface-400">
                Grade (GPA Range)
              </Typography>
              <Tag
                label={`${item.gradePoint} Grade Points`}
                color="secondary"
              />
            </div>
            <Typography variant="title4" className="mb-1">
              {item.grade} ({item.gpaRange})
            </Typography>
            <Typography
              variant="strongSmallBody"
              className="mb-0.5 text-onSurface-600"
            >
              {item.description}
            </Typography>
            <Typography variant="strongSmallBody" className="mb-0.5">
              {item.explanation}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AcademicsGuide
