import * as Sentry from "@sentry/react"
import { ToolType } from "api/resources/aiTools/types"
import { urls } from "api/resources/aiTools/urls"
import { useState } from "react"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/config"
import { getCurrentToken } from "utils/platform"
import { trackingService } from "utils/tracking"

export type StreamQueryProps<T = unknown> = {
  onSuccess?: (props?: StreamFetchProps<T>) => void
  onError?: () => void
  onStreaming?: (chunks: string, props?: StreamFetchProps<T>) => void
  toolType: ToolType
  responseType: StreamResponseEnum
  trackingParams?: Record<string, any>
}

export enum StatusType {
  success = "success",
  failed = "failed",
}

export enum StreamResponseEnum {
  "generate" = "generate",
  "title" = "title",
  "regenerate" = "regenerate",
}

export type StreamFetchProps<T> = {
  currentResponseId: number
  variables?: T
  regenReason?: string | undefined | null
}

export type StreamQuery = {
  data: string
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  refetchAsync: <T>(props: StreamFetchProps<T>) => Promise<void>
  handleCancel: () => void
}

export const useStreamQuery = (props: StreamQueryProps): StreamQuery => {
  const [streamedData, setStreamedData] = useState("")
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [abortController, setAbortController] =
    useState<AbortController | null>(null)

  const baseURL = import.meta.env.VITE_API_ENDPOINT

  const initialState = () => {
    setStreamedData("")
    setIsSuccess(false)
    setIsError(false)
    setIsLoading(true)
  }
  const trackEvents = ({
    toolType,
    responseType,
    reason,
    trackingParams,
  }: {
    toolType: ToolType
    responseType: StreamResponseEnum
    reason?: string | null
    trackingParams?: Record<string, any>
  }): {
    logStreamStarted: () => void
    logStreamSuccess: () => void
    logStreamFailed: () => void
  } => {
    const noOp = () => {
      // console.warn(`No valid tracking events for responseType: ${responseType}`)
    }
    if (responseType === StreamResponseEnum.title)
      return {
        logStreamStarted: noOp,
        logStreamSuccess: noOp,
        logStreamFailed: noOp,
      }

    const eventNameMap: Record<StreamResponseEnum, Record<string, string>> = {
      [StreamResponseEnum.generate]: {
        start: "ai_tool_streaming_started",
        success: "ai_tool_streaming_success",
        failed: "ai_tool_streaming_failed",
      },
      [StreamResponseEnum.regenerate]: {
        start: "ai_tool_regeneration_started",
        success: "ai_tool_regeneration_success",
        failed: "ai_tool_regeneration_failed",
      },
      [StreamResponseEnum.title]: {}, // Include empty object to avoid undefined access
    }

    const eventNames = eventNameMap[responseType]

    if (!eventNames || Object.keys(eventNames).length === 0) {
      // Handle unsupported response types internally
      return {
        logStreamStarted: noOp,
        logStreamSuccess: noOp,
        logStreamFailed: noOp,
      }
    }
    const payload = {
      tool_type: toolType,
      ...(reason && { reason }),
      ...trackingParams,
    }
    return {
      logStreamStarted: () => {
        trackingService.trackEvent(eventNames.start, payload)
      },
      logStreamSuccess: () => {
        trackingService.trackEvent(eventNames.success, payload)
      },
      logStreamFailed: () => {
        trackingService.trackEvent(eventNames.failed, payload)
        const message = "AI Tools Streaming Error"
        Sentry.captureException(new Error(message), scope => {
          scope.setTag("unexpected_backend_error", true)
          scope.setTag("feature", "ai_tools")
          scope.setExtras({
            data: JSON.stringify({ responseType, ...payload }),
          })
          return scope
        })
      },
    }
  }

  const fetchStream = async <T,>({
    currentResponseId: id,
    variables,
    regenReason,
  }: StreamFetchProps<T>) => {
    const controller = new AbortController()
    const tracking = trackEvents({
      toolType: props.toolType,
      responseType: props.responseType,
      reason: regenReason,
      trackingParams: props.trackingParams,
    })
    setAbortController(controller)

    const url: string = (() => {
      switch (props.toolType) {
        case ToolType.lessonPlan:
        case ToolType.lessonPlanPro:
          return urls.lessonPlan.stream(id)
        case ToolType.rubrics:
        case ToolType.rubricsPro:
          return urls.rubrics.stream(id)
        case ToolType.quiz:
        case ToolType.quizPro:
          return urls.quiz.stream(id)
        case ToolType.handout:
          return urls.handout.stream(id)
        case ToolType.narration:
          return urls.narration.stream(id)
        case ToolType.subjectiveAssessment:
        case ToolType.subjectiveAssessmentPro:
          return urls.subjectiveAssessment.stream(id)
        case ToolType.assignment:
        case ToolType.assignmentPro:
          return urls.assignment.stream(id)
        case ToolType.activityPlanner:
          return urls.activityPlanner.stream(id)
        case ToolType.videoSummary:
          return urls.videoSummary.stream(id)
        case ToolType.unitPlanner:
          return urls.unitPlanner.stream(id)
        default:
          throw new Error("Invalid tool type")
      }
    })()

    try {
      initialState()
      tracking.logStreamStarted()

      const token = getCurrentToken()

      const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${token}`

      const response = await fetch(
        baseURL
          .concat("/")
          .concat(url)
          .concat(
            `?${encodeURIComponent("response_type")}=${encodeURIComponent(
              props.responseType
            )}`
          ),
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: tokenString,
          },
          signal: controller.signal,
        }
      )

      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.")
      }
      let hasError = false
      const reader = response.body.getReader()
      const decoder = new TextDecoder("utf-8")
      let done = false

      while (!done) {
        const { value, done: streamDone } = await reader.read()
        if (streamDone) {
          done = streamDone
          setIsLoading(false)
        }
        const chunk = decoder.decode(value, { stream: !done })
        if (props?.onStreaming)
          props.onStreaming(chunk, {
            currentResponseId: id,
            variables,
          })

        if (chunk.includes("[[__ERROR__]]")) {
          tracking.logStreamFailed()
          setIsError(true)
          hasError = true
        }
        setStreamedData(prevData => prevData + chunk)
      }
      if (done && !hasError) {
        setIsSuccess(true)

        tracking.logStreamSuccess()

        if (props?.onSuccess) {
          props?.onSuccess({
            currentResponseId: id,
            variables,
          })
        }
      }
    } catch (error) {
      setIsError(true)

      tracking.logStreamFailed()

      if (props?.onError) props.onError()
      console.error("Error fetching stream:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    if (abortController) {
      abortController.abort()
      setAbortController(null)
    }
  }
  return {
    data: streamedData,
    refetchAsync: fetchStream,
    isLoading,
    isSuccess,
    isError,
    handleCancel,
  }
}
