import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, Divider, Tag } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { SocialAccount, SocialAuthProvider } from "api/resources/settings/types"
import { APIError } from "api/utils"
import AppleIcon from "assets/icons/apple-icon.svg"
import FacebookIcon from "assets/icons/facebook-circle-icon.svg"
import GoogleIcon from "assets/icons/google-icon.svg"
import LoadingOverlay from "components/LoadingOverlay"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

import RemoveDialog from "@/common/RemoveDialog"

import { useSettings } from ".."
import {
  appleAuthHandler,
  facebookAuthHandler,
  getFirebaseAuth,
  googleAuthHandler,
} from "./utils"

const MAX_ACCOUNTS_ALLOWED = 1

const SocialRow = ({
  name,
  icon,
  accounts,
  onLink,
  onRemove,
}: {
  name: string
  icon: any
  accounts: SocialAccount[]
  onLink: () => void
  onRemove: (account: SocialAccount) => Promise<void>
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <img src={icon} alt="" className="h-2.5" />
          <Typography variant="strong" className="text-onSurface-800">
            {name}
          </Typography>
          {accounts.length !== 0 && (
            <Tag color="success" label="Linked" size="sm" />
          )}
        </div>
        {accounts.length < MAX_ACCOUNTS_ALLOWED && (
          <Button variant="text" color="primary" onClick={onLink}>
            Link Account
          </Button>
        )}
      </div>
      <div className="ml-4 flex flex-col space-y-1">
        {accounts.map(account => (
          <div
            className="flex w-full items-center justify-between"
            key={account.id}
          >
            <Typography variant="body" className="text-onSurface-500">
              {account.email}
            </Typography>

            <RemoveDialog
              onRemove={async () => await onRemove(account)}
              title="Remove Account"
              trigger={
                <Button variant="text" color="critical">
                  Remove
                </Button>
              }
            >
              <Typography>
                Are you sure you want to unlink your {account.provider} account
                ({account.email}) from Suraasa?
              </Typography>
            </RemoveDialog>
          </div>
        ))}
      </div>
    </div>
  )
}

const IconNameMap = {
  google: {
    icon: GoogleIcon,
    name: "Google",
  },
  facebook: {
    icon: FacebookIcon,
    name: "Facebook",
  },
  apple: {
    icon: AppleIcon,
    name: "Apple",
  },
}

const SocialAccounts = () => {
  const { socialAccounts, setSocialAccounts } = useSettings()
  const [authReady, setAuthReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const featureToggle = useFeatureToggle()

  const socialAuthEnabled = featureToggle.socialAuth.isEnabled
  const allowedProviders = featureToggle.socialAuth.providers

  const refreshAccounts = async () => {
    const data = await api.settings.socialAuth.list()
    setSocialAccounts(data)
  }
  const onRemoveAccount = async (account: SocialAccount) => {
    try {
      await api.settings.socialAuth.unlink({
        urlParams: {
          id: account.id,
        },
      })
      await refreshAccounts()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err)
      } else {
        toast.error("Some error occurred")
      }
    }
  }

  const onLinkAccount = async (type: SocialAuthProvider) => {
    if (!authReady) {
      return
    }
    setLoading(true)

    const signInHandler = (() => {
      switch (type) {
        case SocialAuthProvider.GOOGLE:
          return googleAuthHandler
        case SocialAuthProvider.FACEBOOK:
          return facebookAuthHandler
        case SocialAuthProvider.APPLE:
          return appleAuthHandler
      }
    })()

    const result = await signInHandler()

    if (result === null || !result.accessToken) {
      toast.error("Error signing in.")
      setLoading(false)
      return
    }

    try {
      const data = { accessToken: result.accessToken, provider: type }
      if (type === SocialAuthProvider.GOOGLE) {
        try {
          // @ts-expect-error _tokenResponse exist
          const scope = JSON.parse(result._tokenResponse.rawUserInfo)
          data["scope"] = scope.granted_scopes
        } catch (e) {
          console.error(e)
        }
      }
      await api.settings.socialAuth.link({
        data,
      })
      refreshAccounts()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err)
      } else {
        toast.error("Something went wrong. Please try again.")
      }
      setLoading(false)
      return
    }

    setLoading(false)
  }

  useEffect(() => {
    getFirebaseAuth()
      .authStateReady()
      .then(() => {
        setAuthReady(true)
      })
  }, [])

  if (!socialAuthEnabled || allowedProviders?.length === 0) {
    return null
  }

  return (
    <>
      <Divider weight="light" className="mb-3 mt-4" />
      <Typography variant="strong">Link Social Accounts</Typography>
      <div className="relative">
        {loading && <LoadingOverlay />}
        <div className="mt-2 space-y-2">
          {allowedProviders
            .filter(v =>
              Object.values(SocialAuthProvider).includes(
                v as SocialAuthProvider
              )
            )
            .map((provider, i) => (
              <SocialRow
                accounts={socialAccounts.filter(
                  acc => acc.provider === provider
                )}
                icon={IconNameMap[provider].icon}
                name={IconNameMap[provider].name}
                onLink={() => onLinkAccount(provider as SocialAuthProvider)}
                onRemove={onRemoveAccount}
                key={i}
              />
            ))}
        </div>
      </div>
    </>
  )
}

export default SocialAccounts
