import { toast } from "@suraasa/placebo-ui"
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  LessonPlanForm,
  LessonPlanProForm,
  LessonPlanPromptData,
  LessonPlanProResponse,
  LessonPlanResponse,
  PreferredPromptData,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import { getToolName, isNumberArray } from "features/AItools/helper"
import {
  StreamFetchProps,
  StreamQuery,
  StreamResponseEnum,
  useStreamQuery,
} from "features/AItools/hooks/useStreamQuery"
import { useCallback, useEffect, useRef, useState } from "react"
import { flushSync } from "react-dom"
import { UseFormSetError } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { mapErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

type LessonPlanResponseIds = {
  id: number
  responseIds: number[]
  hasOutput?: boolean
  handoutIds: { id: number; responseIds: number[] }[]
  narrationIds: { id: number; responseIds: number[] }[]
}
export type LessonPlannerReturnType = {
  currentResponseId: number | null
  setCurrentResponseId: (id: number | null) => void
  promptDataId: string | number | null
  setPromptDataId: React.Dispatch<React.SetStateAction<string | number | null>>
  responseIds: number[] | null
  setResponseIds: React.Dispatch<React.SetStateAction<number[] | null>>
  overviewData: LessonPlanPromptData | undefined
  lessonPlanResponseIds: LessonPlanResponseIds[] | undefined

  userVote: UseMutationResult<
    never,
    unknown,
    {
      reaction: boolean | undefined | null
      id?: number | null
      isPro: boolean
    },
    unknown
  >

  currentStreamValues: string

  isLoading: boolean
  stream: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }
  streamPro: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }
  regenerateStream: StreamQuery
  generateTool: UseMutationResult<
    APIResponse<LessonPlanResponse>,
    any,
    {
      data: BasicFormType
      setError: UseFormSetError<BasicFormType>
    },
    unknown
  >
  generateProTool: UseMutationResult<
    APIResponse<LessonPlanProResponse>,
    any,
    {
      data: LessonPlanProForm
      setError: UseFormSetError<LessonPlanProForm>
    },
    unknown
  >
  regenerateResponse: UseMutationResult<
    APIResponse<{
      id: number
    }>,
    unknown,
    {
      reason: string
      id?: number | null
      onSuccess?: (id: number) => void
      isPro: boolean
    },
    unknown
  >
  generateHandout: boolean

  setGenerateHandout: React.Dispatch<React.SetStateAction<boolean>>
  generateNarration: boolean
  setGenerateNarration: React.Dispatch<React.SetStateAction<boolean>>
  setLessonPlanResponseIds: React.Dispatch<
    React.SetStateAction<LessonPlanResponseIds[] | undefined>
  >
  isPositiveResponse: boolean | undefined | null

  resetState: () => void
  finalizedOutputs: {
    id: number
    output: string
    isPositiveResponse: boolean | null | undefined
  }[]
  refetchOverview: (promptId?: string | number | null) => void
  pageLoading: boolean
  onBack: () => void
  handleCancelApi: () => void
  // setFormData: React.Dispatch<
  //   React.SetStateAction<BasicForm | LessonPlanProForm | undefined>
  // >
  formData: {
    basicForm?: BasicFormType
    proForm?: ProForm
  } | null
  preferredPromptData: PreferredPromptData | null
  isError: boolean
}
export type BasicFormType = LessonPlanForm & {
  generateHandout: boolean
  generateNarration: boolean
}
type ProForm = LessonPlanProForm
export const useLessonPlanner = (): LessonPlannerReturnType => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const [pageLoading, setPageLoading] = useState(false)
  const [promptDataId, setPromptDataId] = useState<string | number | null>(id)
  const [responseIds, setResponseIds] = useState<number[] | null>(null)
  const [generateHandout, setGenerateHandout] = useState(false)
  const [generateNarration, setGenerateNarration] = useState(false)
  const [lessonPlanResponseIds, setLessonPlanResponseIds] = useState<
    LessonPlanResponseIds[] | undefined
  >()
  const [isPositiveResponse, setIsPositiveResponse] = useState<
    boolean | undefined | null
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [overviewData, setOverviewData] = useState<
    LessonPlanPromptData | undefined
  >()
  const [currentStreamValues, setCurrentStreamValues] = useState<string>("")
  const [formData, setFormData] = useState<{
    basicForm?: BasicFormType
    proForm?: ProForm
  } | null>(null)

  const currentStep = useRef(0)
  const currentValue = useRef("")
  const finalizedOutputs = useRef<
    {
      id: number
      output: string
      isPositiveResponse: boolean | null | undefined
    }[]
  >([])
  const [currentMode, setCurrentMode] = useState<"basic" | "pro" | null>(null)

  const resetState = () => {
    setPromptDataId(null)
    setResponseIds(null)
    setGenerateHandout(false)
    setGenerateNarration(false)
    setLessonPlanResponseIds(undefined)
    setIsPositiveResponse(null)
    setIsLoading(false)
    setOverviewData(undefined)
    setCurrentStreamValues("")
    setCurrentResponseId(null)
    currentStep.current = 0
    currentValue.current = ""
  }

  const resetStream = () => {
    setCurrentStreamValues("")
    currentValue.current = ""
  }

  const handleCancelApi = () => {
    if (streamTitle.isLoading) streamTitle.handleCancel()
    if (streamOutput.isLoading) streamOutput.handleCancel()
    if (regenerateStream.isLoading) regenerateStream.handleCancel()

    if (streamTitlePro.isLoading) streamTitlePro.handleCancel()
    if (streamOutputPro.isLoading) streamOutputPro.handleCancel()
    if (regenerateStreamPro.isLoading) regenerateStreamPro.handleCancel()
  }
  const onBack = () => {
    navigate(routes.aiTools.lessonPlan, { replace: true })
    resetState()
    handleCancelApi()
  }
  // This flow is for when user clicks on history item
  // If that is the case then we just fetch the overview and the latest generated output
  useEffect(() => {
    if (id) {
      setPageLoading(true)
      api.aiTools.lessonPlanner
        .getPromptData({
          urlParams: {
            id: id,
          },
        })
        .then(async data => {
          setPromptDataId(id)
          const { responseIds, isPro } = data

          if (data.isPro) {
            setCurrentMode("pro")
          } else {
            setCurrentMode("basic")
          }

          if (responseIds && !isNumberArray(responseIds)) {
            const [latestLessonPlan] = responseIds
            const [latestResponseId] = latestLessonPlan.responseIds

            setCurrentResponseId(latestResponseId)
            if (isPro) {
              for (const item of responseIds) {
                await getContent(item.responseIds[0])
              }
            } else {
              setResponseIds(latestLessonPlan.responseIds)
              await getContent(latestResponseId)
            }
          }

          setOverviewData(data)
          setPageLoading(false)
        })
        .catch(error => {
          console.error("Error fetching prompt data:", error)
          setPageLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const generateTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: BasicFormType
      setError: UseFormSetError<BasicFormType>
    }) => {
      setFormData(prevState => {
        if (!prevState) {
          return { basicForm: data }
        }
        return { ...prevState, basicForm: data }
      })
      setCurrentMode("basic")
      return api.aiTools.lessonPlanner.generate({ data })
    },
    onSuccess: async res => {
      const { responseId, id } = res
      flushSync(() => {
        setCurrentResponseId(responseId)
        setPromptDataId(id)
      })
      setOverviewData({
        id: res.id,
        bloomTaxonomyLevel: res.bloomTaxonomyLevel,
        country: res.country,
        curriculum: res.curriculum,
        subject: res.subject,
        grade: res.grade,
        topic: res.topic,
        duration: res.duration,
        title: `New ${getToolName(ToolType.lessonPlan)}`,
        book: undefined,
        numberOfClasses: null,
        isPro: false,
        responseIds: [],
      })

      await streamOutput.refetchAsync({
        currentResponseId: responseId,
      })
      await streamTitle.refetchAsync({
        currentResponseId: responseId,
      })
      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.lessonPlan).queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["bloomTaxonomyLevel"],
          ["country"],
          ["curriculum"],
          ["subject"],
          ["grade"],
          ["topic"],
          ["duration"],
        ])
      }
    },
  })

  const generateProTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: LessonPlanProForm
      setError: UseFormSetError<LessonPlanProForm>
    }) => {
      setFormData(prevState => {
        if (!prevState) {
          return { proForm: data }
        }
        return { ...prevState, proForm: data }
      })
      setCurrentMode("pro")
      return api.aiTools.lessonPlanner.generatePro({ data })
    },
    onSuccess: async res => {
      const { responseIds, id } = res
      flushSync(() => {
        setLessonPlanResponseIds(
          res.responseIds.map(item => ({
            id: item,
            responseIds: [item],
            handoutIds: [],
            narrationIds: [],
          }))
        )
        setCurrentResponseId(responseIds[0])
        setPromptDataId(id)
      })
      setOverviewData({
        id: res.id,
        bloomTaxonomyLevel: res.bloomTaxonomyLevel,
        country: res.country,
        curriculum: res.curriculum,
        subject: res.subject,
        grade: res.grade,
        topic: res.topic,
        duration: res.duration,
        title: `New ${getToolName(ToolType.lessonPlanPro)}`,
        book: res.book,
        numberOfClasses: res.numberOfClasses,
        isPro: false,
        responseIds: [],
      })

      await streamOutputPro.refetchAsync({
        currentResponseId: responseIds[0],
        variables: {
          lessonPlanResponseIds: res.responseIds,
        },
      })

      await streamTitlePro.refetchAsync({
        currentResponseId: responseIds[0],
      })

      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.lessonPlan).queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["bloomTaxonomyLevel"],
          ["country"],
          ["curriculum"],
          ["subject"],
          ["numberOfClasses"],
          ["grade"],
          ["book"],
          ["topic"],
          ["duration"],
        ])
      }
    },
  })

  const overviewAPI = useCallback(
    async (promptId?: string | number | null) => {
      const id = promptId || promptDataId
      if (!id) {
        throw new Error("Prompt id missing from overview api")
      }
      const res = await api.aiTools.lessonPlanner.getPromptData({
        urlParams: {
          id: id,
        },
      })

      if (res.isPro) {
        setCurrentMode("pro")
      } else {
        setCurrentMode("basic")
      }
      if (res instanceof APIError) {
        toast.error("Prompt failed to generate")
        return
      }

      const { responseIds, isPro } = res

      if (responseIds && !isNumberArray(responseIds)) {
        const [latestLessonPlan] = responseIds
        const [latestResponseId] = latestLessonPlan.responseIds

        setCurrentResponseId(latestResponseId)
        if (!isPro) {
          setResponseIds(latestLessonPlan.responseIds)
        }
      }

      setOverviewData(res)
    },
    [promptDataId]
  )

  const streamTitle = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: ToolType.lessonPlan,
  })
  const streamTitlePro = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: ToolType.lessonPlanPro,
  })

  const streamOutput = useStreamQuery({
    responseType: StreamResponseEnum.generate,
    toolType: ToolType.lessonPlan,
    onError: () => {
      toast.error("Generation Failed")
    },
    onSuccess() {
      toast.success("Generated Successfully")
    },
    onStreaming(chunks, props) {
      setCurrentStreamValues(prev => prev + chunks)
      if (props && props.variables) {
        const { lessonPlanResponseIds } = props.variables as {
          lessonPlanResponseIds: number[]
        }

        const splitRegex = /\[\[__STOP__\]\]|\[\[__BREAK__\]\]|\[\[__END__\]\]/
        const errorRegex = /\[\[__ERROR__\]\]/

        const isError = chunks.match(errorRegex)
        const isComplete = chunks.match(splitRegex)

        if (isError) {
          const id = lessonPlanResponseIds[currentStep.current]

          finalizedOutputs.current.push({
            id,
            output: currentValue.current,
            isPositiveResponse: null,
          })
          currentStep.current++
          resetStream()
        } else if (isComplete) {
          const id = lessonPlanResponseIds[currentStep.current]

          finalizedOutputs.current.push({
            id,
            output: currentValue.current,
            isPositiveResponse: null,
          })
          currentStep.current++
          resetStream()
        }

        currentValue.current += chunks

        if (lessonPlanResponseIds[currentStep.current]) {
          updateLessonPlanResponseIds(
            lessonPlanResponseIds[currentStep.current]
          )
        }
      }
    },
  })

  const streamOutputPro = useStreamQuery({
    responseType: StreamResponseEnum.generate,
    toolType: ToolType.lessonPlanPro,
    onError: () => {
      toast.error("Generation Failed")
    },
    onSuccess() {
      toast.success("Generated Successfully")
    },
    onStreaming(chunks, props) {
      setCurrentStreamValues(prev => prev + chunks)
      if (props && props.variables) {
        const { lessonPlanResponseIds } = props.variables as {
          lessonPlanResponseIds: number[]
        }

        const splitRegex = /\[\[__STOP__\]\]|\[\[__BREAK__\]\]|\[\[__END__\]\]/
        const errorRegex = /\[\[__ERROR__\]\]/

        const isError = chunks.match(errorRegex)
        const isComplete = chunks.match(splitRegex)

        if (isError) {
          const id = lessonPlanResponseIds[currentStep.current]

          finalizedOutputs.current.push({
            id,
            output: currentValue.current,
            isPositiveResponse: null,
          })
          currentStep.current++
          resetStream()
        } else if (isComplete) {
          const id = lessonPlanResponseIds[currentStep.current]

          finalizedOutputs.current.push({
            id,
            output: currentValue.current,
            isPositiveResponse: null,
          })
          currentStep.current++
          resetStream()
        }

        currentValue.current += chunks

        if (lessonPlanResponseIds[currentStep.current]) {
          updateLessonPlanResponseIds(
            lessonPlanResponseIds[currentStep.current]
          )
        }
      }
    },
  })

  const regenerateStream = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: ToolType.lessonPlan,
    onSuccess: props => {
      toast.success("Re-generated Successfully")
      if (props && props.currentResponseId) {
        finalizedOutputs.current.push({
          id: props.currentResponseId,
          output: currentValue.current,
          isPositiveResponse: null,
        })
      }
      resetStream()
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks, props) {
      setCurrentStreamValues(prev => prev + chunks)
      if (props && props.currentResponseId) {
        currentValue.current += chunks
        updateLessonPlanResponseIds(props.currentResponseId)
      }
    },
  })
  const regenerateStreamPro = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: ToolType.lessonPlanPro,
    onSuccess: props => {
      toast.success("Re-generated Successfully")
      if (props && props.currentResponseId) {
        finalizedOutputs.current.push({
          id: props.currentResponseId,
          output: currentValue.current,
          isPositiveResponse: null,
        })
      }
      resetStream()
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks, props) {
      setCurrentStreamValues(prev => prev + chunks)
      if (props && props.currentResponseId) {
        currentValue.current += chunks
        updateLessonPlanResponseIds(props.currentResponseId)
      }
    },
  })

  const userVote = useMutation({
    mutationFn: async ({
      reaction,
      id,
      isPro,
    }: {
      reaction: boolean | undefined | null
      id?: number | null
      isPro: boolean
    }) => {
      const eventName = reaction
        ? "ai_tool_output_upvoted"
        : "ai_tool_output_downvoted"
      const toolType = isPro ? ToolType.lessonPlanPro : ToolType.lessonPlan

      trackingService.trackEvent(eventName, {
        tool_type: toolType,
      })

      return api.aiTools.lessonPlanner.updateData({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: id || currentResponseId!,
          type: toolType,
        },
        data: {
          isPositiveResponse: reaction,
        },
      })
    },
  })

  const getContent = async (
    id: number,
    callBack?: (output: string) => void
  ) => {
    resetStream()
    setIsLoading(true)
    const res = await api.aiTools.lessonPlanner.retrieveContent({
      urlParams: {
        id: id,
      },
    })
    if (res instanceof APIError) {
      toast.error(res.message || "Something went wrong")
      setIsLoading(false)
      return
    }
    const findCurrentIndex = finalizedOutputs.current.findIndex(
      item => item.id === res.id
    )
    if (findCurrentIndex === -1) {
      finalizedOutputs.current.push({
        id,
        output: res.output,
        isPositiveResponse: res.isPositiveResponse,
      })
    } else {
      finalizedOutputs.current[findCurrentIndex].output = res.output
      finalizedOutputs.current[findCurrentIndex].isPositiveResponse =
        res.isPositiveResponse
    }

    setIsPositiveResponse(res.isPositiveResponse)
    setCurrentStreamValues(res.output)
    if (callBack) callBack(res.output)
    setIsLoading(false)
    resetStream()
  }

  const regenerateResponse = useMutation({
    mutationFn: ({
      reason,
      id,
    }: {
      reason: string
      id?: number | null
      onSuccess?: (id: number) => void
      isPro: boolean
    }) =>
      api.aiTools.lessonPlanner.regenerateResponse({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: id || currentResponseId!,
        },
        data: {
          regenerateInstruction: reason,
        },
      }),
    onSuccess: async (res, { onSuccess, isPro, reason }) => {
      flushSync(() => {
        setCurrentResponseId(res.id)
        setCurrentStreamValues("")
        if (onSuccess) onSuccess(res.id)
      })
      if (isPro) {
        await regenerateStreamPro.refetchAsync({
          currentResponseId: res.id,
          regenReason: reason,
        })
      } else {
        await regenerateStream.refetchAsync({
          currentResponseId: res.id,
          regenReason: reason,
        })
      }
      overviewAPI(promptDataId)
    },
    onError: err => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      } else {
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      }
    },
  })

  const updateLessonPlanResponseIds = (id: number) => {
    setLessonPlanResponseIds(prevLessonPlanResponseIds => {
      if (!prevLessonPlanResponseIds) {
        return prevLessonPlanResponseIds
      }
      return prevLessonPlanResponseIds.map(item =>
        item.id === id ? { ...item, hasOutput: true } : item
      )
    })
  }

  const preferredPromptData = useQuery({
    queryFn: () => api.aiTools.retrievePreferredPromptData(),
    queryKey: ["preferredPromptData"],
    // select: data => ({
    //   ...data,
    //   subject: null,
    // }),
  })

  return {
    isPositiveResponse,
    setLessonPlanResponseIds,
    lessonPlanResponseIds,
    promptDataId,
    generateTool,
    setPromptDataId,
    currentResponseId,
    setCurrentResponseId: id => {
      setCurrentResponseId(id)
      if (id) {
        if (overviewData) getContent(id)
      }
    },
    responseIds,
    setResponseIds,
    stream: {
      ...streamOutput,
      refetchAsync: ({ currentResponseId: id }) =>
        streamOutput.refetchAsync({
          currentResponseId: id,
        }),
    },
    streamPro: {
      ...streamOutputPro,
      refetchAsync: ({ currentResponseId: id }) =>
        streamOutputPro.refetchAsync({
          currentResponseId: id,
        }),
    },
    generateProTool,
    regenerateResponse,
    overviewData: overviewData,
    userVote,
    regenerateStream,
    currentStreamValues,
    isLoading:
      isLoading ||
      regenerateStream.isLoading ||
      streamOutput.isLoading ||
      regenerateStreamPro.isLoading ||
      streamOutputPro.isLoading,
    generateHandout,
    setGenerateHandout,
    generateNarration,
    setGenerateNarration,
    resetState,
    finalizedOutputs: finalizedOutputs.current,
    refetchOverview: overviewAPI,
    pageLoading,
    onBack,
    handleCancelApi,
    formData,
    // setFormData,
    preferredPromptData: preferredPromptData.data ?? null,
    isError:
      currentMode === "pro" ? streamOutputPro.isError : streamOutput.isError,
  }
}
