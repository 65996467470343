import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Project } from "api/resources/profile/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

import RemoveDialog from "@/common/RemoveDialog"

import ProfileContext from "../../context"

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id?: string
  open: boolean
}

const ProjectDialog = ({ id, open, setOpen }: Props) => {
  const {
    achievements: { projects },
  } = useContext(ProfileContext)

  const [data, setData] = useState<Project>()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Project>()

  const startDate = watch("startDate")
  const endDate = watch("endDate")

  useEffect(() => {
    clearErrors(["startDate", "endDate"])
  }, [startDate, endDate, clearErrors])

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.projects.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        reset(res.data)
      }
    }
    if (id) fetchData()
  }, [id, reset])

  const currentlyWorking = watch("currentlyWorking")

  const onSubmit = handleSubmit(
    async ({
      title,
      currentlyWorking: cw,
      startDate: startDateFormData,
      endDate: endDateFormData,
      url,
      description,
    }) => {
      const apiData = {
        title,
        currentlyWorking: cw,
        startDate: startDateFormData,
        endDate: cw ? null : endDateFormData,
        url: url || null,
        description: description || null,
      }

      if (id) {
        const res = await api.profile.projects.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          projects.refetch()
          setOpen(false)
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.projects.create({ data: apiData })
        if (res.isSuccessful) {
          projects.refetch()
          setOpen(false)
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    if (id) {
      const res = await api.profile.projects.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        projects.refetch()
        setOpen(false)
      }
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="strong">
                {id ? "Edit" : "Add New"} Project
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            {id && !data && <LoadingOverlay />}
            <form className="flex flex-col gap-3" onSubmit={onSubmit}>
              <TextField
                autoFocus
                errors={errors.title?.message}
                required
                label="Title"
                placeholder="Ex: Light detection nears its quantum limit"
                {...register("title", {
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />

              <div>
                <Controller
                  control={control}
                  name="currentlyWorking"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="flex items-center space-x-1">
                      <Checkbox
                        checked={value}
                        onBlur={onBlur}
                        onCheckedChange={onChange}
                      />
                      <Typography variant="smallBody">
                        I am currently working on this project
                      </Typography>
                    </div>
                  )}
                />
              </div>
              <div className="flex flex-col gap-3 sm:flex-row">
                <TextField
                  errors={errors.startDate?.message}
                  required
                  label="Start Date"
                  placeholder="Ex: 21 Jan 2021"
                  type="date"
                  {...register("startDate", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <TextField
                  disabled={currentlyWorking}
                  errors={errors.endDate?.message}
                  required={!currentlyWorking}
                  label="End Date"
                  placeholder="Ex: 21 Jan 2021"
                  type="date"
                  {...register("endDate", {
                    required: { value: !currentlyWorking, message: "Required" },
                  })}
                />
              </div>

              <TextField
                errors={errors.url?.message}
                label="Project Url"
                placeholder="Ex:"
                {...register("url")}
              />
              <TextArea
                errors={errors.description?.message}
                label="Description"
                placeholder="Talk about your role and experience etc..."
                rows={5}
                {...register("description", {
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />
            </form>
          </DialogBody>

          <DialogFooter
            className={clsx("flex", {
              "!justify-between": id,
            })}
          >
            {id && (
              <RemoveDialog
                trigger={
                  <Button
                    variant="text"
                    color="critical"
                    loading={isSubmitting}
                  >
                    Remove
                  </Button>
                }
                title="Remove Project?"
                onRemove={handleRemove}
              >
                <Typography variant="smallBody">
                  Are you sure you want to remove&nbsp;
                  <Typography className="inline" variant="strongSmallBody">
                    <strong>{data?.title}</strong>
                  </Typography>
                  &nbsp;from your profile?
                </Typography>
              </RemoveDialog>
            )}

            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpen(false)
                }}
                className="mr-1.25 border-muted text-muted"
                size="sm"
                color="black"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="sm"
                loading={isSubmitting}
                className="py-1.25"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ProjectDialog
