import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { VerifiedParticipationHistory } from "api/resources/ito/registrations/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import styles from "features/ITO/LinkReportsBanner/Linkreports.module.css"
import { Xmark } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { handleErrors } from "utils/helpers"

import GradientButton from "../components/GradientButton"
import SuccessfullyLinkedDialog from "./SuccessfullyLinkedDialog.tsx"
import VerifyIdentity from "./VerifyIdentity"

enum Step {
  none,
  participationNumber,
  verification,
  success,
}

const LinkReportsBanner = () => {
  const [step, setStep] = useState<Step>(Step.none)

  const [otpVerificationData, setOtpVerificationData] =
    useState<VerifiedParticipationHistory>()

  const [showBanner, setShowBanner] = useState(
    localStorage.getItem(BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner) !==
      "true"
  )

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<{
    participationHistoryNumber: string
  }>()

  const participationHistory = useQuery({
    queryFn: () => api.ito.registrations.getUserParticipationHistory(),
  })

  useEffect(() => {
    if (participationHistory.data?.participationHistoryExists === false) {
      setShowBanner(true)
    }
    if (participationHistory.data?.participationHistoryExists === true) {
      setShowBanner(false)
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner,
        "true"
      )
    }
  }, [participationHistory.data])

  const onSubmit = handleSubmit(async data => {
    try {
      const res = await api.ito.reports.verifyParticipationHistory({
        data: {
          participationHistoryNumber: data.participationHistoryNumber,
        },
      })
      setOtpVerificationData(res)
      setStep(Step.verification)
    } catch (e) {
      if (e instanceof APIError) handleErrors(e, { setter: setError })
    }
  })

  const updateParticipationHistory = async (token: string) => {
    try {
      await api.ito.reports.updateParticipationHistory({
        data: {
          encryptedToken: token,
          participationHistoryNumber: getValues("participationHistoryNumber"),
        },
      })
      setStep(Step.success)
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner,
        "true"
      )
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e)
        console.error(e)
      }
    }
  }

  if (!showBanner) return null

  return (
    <>
      <div className="relative bg-highlight-500">
        <IconButton
          className="absolute right-2 top-2"
          color="white"
          onClick={() => {
            setShowBanner(false)
            localStorage.setItem(
              BROWSER_STORAGE_KEYS.dismissITOinkReportsBanner,
              "true"
            )
          }}
        >
          <Xmark />
        </IconButton>
        <Container>
          <div className="flex flex-col items-start justify-between py-2 md:flex-row md:items-center md:py-1">
            <Typography className="w-[90%] text-surface-500 sm:w-full">
              Did you participate last year? Link your last year reports for a
              more detailed report analysis.
            </Typography>
            <Button
              rounded
              color="white"
              className="mt-1 !text-highlight-500 md:mt-0"
              onClick={() => setStep(Step.participationNumber)}
            >
              Link Reports
            </Button>
          </div>
        </Container>
      </div>

      <Dialog
        open={step === Step.participationNumber}
        onRequestClose={() => setStep(Step.none)}
      >
        <DialogContent className={styles.dialogContent}>
          <div className={clsx(styles.whiteGradient, "absolute size-full")} />
          <form className="px-2" onSubmit={onSubmit}>
            <div className="flex items-center justify-between">
              <img
                src="https://assets.suraasa.com/ito/logos/ito-original-two-line.svg"
                alt="smallLogo"
                className="pt-3"
                width="40%"
              />
              <IconButton className="mt-1.5" onClick={() => setStep(Step.none)}>
                <Xmark />
              </IconButton>
            </div>
            <Typography variant="title2" className="mb-0.5 mt-3">
              Link your last year reports
            </Typography>
            <Typography className="mb-3 text-onSurface-500">
              This will help us create more detailed reports for you.
            </Typography>
            <TextField
              rounded
              error={Boolean(errors.participationHistoryNumber)}
              helperText={errors.participationHistoryNumber?.message}
              label="Enter your Registration/Certificate Number"
              placeholder="Ex: ITO-XXXXXXX"
              fullWidth
              {...register("participationHistoryNumber", {
                required: { value: true, message: "Required" },
              })}
            />
            <Typography variant="smallBody" className="mt-2 text-onSurface-500">
              You can find the ITO 2022 registration number on your registered
              email, WhatsApp or previous year’s Reports. And you can find your
              certificate number on any of your ITO Certificates from last year.
            </Typography>

            <GradientButton fullWidth className="mb-[26px] mt-10" type="submit">
              Link Reports
            </GradientButton>
          </form>
        </DialogContent>
      </Dialog>

      {otpVerificationData && (
        <VerifyIdentity
          title="Verify Identity"
          onBack={() => {
            setStep(Step.none)
          }}
          close={() => {
            setOtpVerificationData(undefined)
            setStep(Step.none)
          }}
          otpData={otpVerificationData.otp}
          phoneNumber={otpVerificationData.phoneNumber}
          open={step === Step.verification}
          email={otpVerificationData.email}
          onVerify={(token: string) => {
            updateParticipationHistory(token)
          }}
        />
      )}

      <SuccessfullyLinkedDialog
        open={step === Step.success}
        onClose={() => {
          setStep(Step.none)
          setShowBanner(false)
        }}
      />
    </>
  )
}

export default LinkReportsBanner
