import {
  Button,
  IconButton,
  SheetBody,
  SheetClose,
  Typography,
} from "@suraasa/placebo-ui"
import Laptop from "assets/UploadVideo/laptop.png"
import { Xmark } from "iconoir-react"

type Props = {
  onContinue: () => void
}

const Introduction = ({ onContinue }: Props) => {
  return (
    <>
      <SheetClose className="ml-auto pr-1 pt-1">
        <IconButton color="black">
          <Xmark className="!size-3" />
        </IconButton>
      </SheetClose>

      <SheetBody className="flex grow flex-col">
        <img
          src={Laptop}
          alt="laptop"
          width="100%"
          className="mb-4 max-h-[40vh] rounded-t-xl bg-[#F4F4F4] object-contain"
        />
        <div className="mx-auto mb-4 px-2 sm:px-0">
          <Typography className="mb-1 text-center text-title3 sm:text-title2">
            Video Portfolio
          </Typography>
          <Typography className="mx-auto mb-1 max-w-lg text-center text-body sm:text-largeBody">
            Video Portfolio is the quickest way to showcase your skills and
            experience to employers across the world. Teachers with video
            portfolio are hired much faster.
          </Typography>
        </div>

        <div className="grow sm:grow-0"></div>
        <Button className="mx-auto block w-full sm:w-fit" onClick={onContinue}>
          Continue
        </Button>
      </SheetBody>
    </>
  )
}

export default Introduction
