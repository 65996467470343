import { Typography } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { HandoutWithLessonPlannerReturnType } from "features/AItools/components/Generator/LessonPlanGenerator/useHandoutWithLessonPlanner"
import { getToolName } from "features/AItools/helper"
import { Notes } from "iconoir-react"
import React, { useState } from "react"

import GenerateButton from "../../../GenerateButton"
import OutputToolbar from "../../../OutputToolbar"
import ToolOutput from "../../../ToolOutput"
import { generateUuidV4 } from "./helper"

const toolType = ToolType.handout

const HandoutContent = ({
  handoutIds: handoutList,
  finalizedOutputs,
  isLoading,
  generateToolWithLessonPlan,
  lessonPlanId,
  output,
  userVote,
  regenerateResponse,
  ...props
}: {
  handoutIds: { id: number; responseIds: number[] }[]
  lessonPlanId: number | undefined
  onBack: () => void
} & Pick<
  HandoutWithLessonPlannerReturnType,
  | "isLoading"
  | "finalizedOutputs"
  | "generateToolWithLessonPlan"
  | "output"
  | "userVote"
  | "regenerateResponse"
  | "setCurrentResponseId"
>) => {
  const handout = handoutList.length === 1 ? handoutList[0] : undefined
  const [currentResponseId, setState] = useState<number | null>(
    handout && handout.responseIds.length > 0 ? handout.responseIds[0] : null
  )

  const setCurrentResponseId = (id: number, t?: { skipCall: boolean }) => {
    setState(id)
    if (!t?.skipCall) {
      props.setCurrentResponseId(id)
    }
  }

  const generate = () => {
    generateToolWithLessonPlan.mutate({
      data: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lessonPlanResponse: lessonPlanId!,
        generatedWithLessonPlan: true,
        groupId: generateUuidV4(),
      },
      onSuccess(id) {
        setCurrentResponseId(id, { skipCall: true })
      },
    })
  }

  if (!handout && !currentResponseId) {
    return (
      <div className="grid place-content-center py-5">
        <div className="flex flex-col items-center gap-2">
          <div className="grid size-15 place-items-center rounded-full bg-interactive-50 text-interactive-500">
            <Notes className="size-10" strokeWidth={1} />
          </div>
          <Typography variant="title3" className="max-w-[400px] text-center">
            Looks like you haven’t generated any {getToolName(toolType)} for
            this Lesson Plan.
          </Typography>
          <GenerateButton
            onClick={() => {
              generate()
            }}
            loading={isLoading}
            label="Generate"
          />
        </div>
      </div>
    )
  }

  const currentData = finalizedOutputs.find(
    item => item.id === currentResponseId
  )
  const currentOutput = currentData?.output || output
  return (
    <div>
      <div>
        <ToolOutput
          showTitle
          isLoading={isLoading}
          onClick={() => {
            if (!handout) {
              setState(null)
            } else if (handout?.responseIds) {
              setCurrentResponseId(
                handout?.responseIds?.length > 1
                  ? handout?.responseIds[handout?.responseIds?.length - 1]
                  : handout?.responseIds[0]
              )
            }
          }}
          className="px-4 py-3"
        >
          {currentOutput}
        </ToolOutput>
      </div>
      {!isLoading && (
        <OutputToolbar
          toolType={ToolType.handout}
          responseIds={handout?.responseIds || []}
          currentResponseId={currentResponseId}
          copyText={currentOutput}
          isPositiveResponse={currentData?.isPositiveResponse}
          onResponseIdChange={responseId => {
            setCurrentResponseId(responseId)
          }}
          onUserReaction={reaction => {
            userVote.mutate({ reaction, id: currentResponseId })
          }}
          onRegenerate={reason => {
            regenerateResponse.mutate({
              reason,
              id: currentResponseId,
              onSuccess(id) {
                setCurrentResponseId(id, { skipCall: true })
              },
            })
          }}
        />
      )}
    </div>
  )
}

export default React.memo(HandoutContent)
