import { Button, ButtonComponentProps } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"

type Props = {
  className?: string
  buttonProps: ButtonComponentProps<any>
}

const FullWidthButtonCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  buttonProps,
}) => {
  return (
    <div
      className={clsx(
        "overflow-hidden rounded-2xl border-2 border-onSurface-200 bg-white",
        className
      )}
    >
      <div className="p-2">{children}</div>
      <Button
        style={{
          borderRadius: 0,
        }}
        fullWidth
        {...buttonProps}
      />
    </div>
  )
}

export default FullWidthButtonCard
