import { AssignmentList } from "api/resources/assignments/types"
import { getActionConfig } from "features/Assignments/utils"

const getNotStartedAssignments = (assignments: AssignmentList[]) => {
  return assignments.every(assignment => {
    const config = getActionConfig(assignment)
    return config.canAttempt && !config.isDraft
  })
}

export { getNotStartedAssignments as checkAssignmentsNotStarted }
