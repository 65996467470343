import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import clsx from "clsx"
import Card from "components/Card"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import CourseFeedbackCard from "features/CourseFeedbackCard"
import NextUp from "features/LearningItems/Qualification/QualificationOverview/NextUp"
import CourseInfoAndProgress from "features/Library/CourseInfoAndProgress"
import { Link, useNavigate, useParams } from "react-router-dom"
import routes from "routes"
import { getAuthInfo, getLearningItemURL, pluralize } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import { useCourseDetails } from ".."
import CompletedCourseCard from "../CompletedCourseCard"
import Curriculum from "../Curriculum"

// const overviewDetails: OverviewType = {
//   professors: [
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//   ],
//   description:
//     "Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms.",
// }

type Props = {
  className?: string
}

const NoticeCard = ({
  isRestricted,
  className,
  note,
  cta: { label, action },
}: PropsWithClassName<{
  isRestricted: boolean
  note: string
  cta: { label: string; action: () => void }
}>) => {
  return (
    <div
      className={clsx(
        "flex flex-wrap items-center justify-between rounded-2xl border border-surface-200 bg-warning-50 p-2 backdrop:blur",
        className
      )}
    >
      <Typography variant="strong">{note}</Typography>
      <Button
        onClick={() => action()}
        className="mt-1 sm:mt-0"
        disabled={isRestricted}
      >
        {label}
      </Button>
    </div>
  )
}

// const Feedback = ({
//   className,
//   note,
//   cta: { label, action },
// }: PropsWithClassName<{
//   note: string
//   cta: { label: string; action: () => void }
// }>) => {
//   return (
//     <div
//       className={clsx(
//         "flex flex-wrap items-center justify-between rounded-2xl border border-primary-200 bg-primary-50 p-2 backdrop:blur",
//         className
//       )}
//     >
//       <Typography variant="strong">{note}</Typography>
//       <Button variant="text" onClick={() => action()} className="mt-1 sm:mt-0">
//         {label}
//       </Button>
//     </div>
//   )
// }

const CourseOverview = ({ className }: Props) => {
  const navigate = useNavigate()
  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug?: string
  }
  const learningItemType = "course"

  const userName = getAuthInfo()?.user.username
  const { data } = useQuery({
    queryKey: queries.profile.certificateExistence(
      userName,
      learningItemType,
      slug
    ).queryKey,
    queryFn: () =>
      api.profile.credentials.checkExistence({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          username: userName!,
          itemType: learningItemType,
          itemSlug: slug,
        },
      }),
  })

  const { course, accessStatus } = useCourseDetails()

  const { data: overview } = useQuery({
    queryFn: () =>
      api.learningItems.overview({
        urlParams: {
          itemType: "course",
          itemId: course.id,
        },
      }),
    enabled: Boolean(course.id),
    queryKey: queries.learningItems.overview("course", course.id).queryKey,
  })

  const courseIsCompleted =
    overview?.progress &&
    overview.progress.completedUserPlannerItems ===
      overview.progress.totalUserPlannerItems

  return (
    <div className={clsx("pb-6", className)}>
      {/* This is a container for banners */}
      <div className="mb-3 flex flex-col gap-2">
        {data?.serialNumber && (
          <CompletedCourseCard serialNumber={data.serialNumber} />
        )}

        {/* {courseIsCompleted && (
          <Feedback
            note="We would love to know what you thought about this course."
            cta={{
              label: "Give Feedback",
              action: () => {
                // Open course feedback popup here
              },
            }}
          />
        )} */}

        {courseIsCompleted && (
          <CourseFeedbackCard
            itemId={course.id}
            courseName={course.name}
            onClose={() => {
              // sessionStorage.setItem("hasDismissedRateCoursePopup", "true")
            }}
          />
        )}

        {overview && (
          <>
            {(overview.progress == null || !courseIsCompleted) && (
              <Card
                padding={2}
                className="gap-1 rounded-2xl border-2 border-onSurface-300"
                infoSlot={
                  <CourseInfoAndProgress
                    showCount
                    barColor="black"
                    title={
                      overview.progress == null ||
                      overview.progress.completedUserPlannerItems === 0
                        ? "Start your course"
                        : "Continue where you left off"
                    }
                    currentItemNumber={
                      overview.progress?.completedUserPlannerItems || 0
                    }
                    numberOfItems={
                      overview.progress?.totalUserPlannerItems || 0
                    }
                  />
                }
                actionSlot={
                  course.id ? (
                    <CheckRestrictions
                      accessStatus={accessStatus}
                      render={({ isRestricted }) => (
                        <Button
                          disabled={isRestricted}
                          component={Link}
                          to={getLearningItemURL({
                            route: parentSlug
                              ? routes.learningModuleWithParent
                              : routes.learningModule,
                            itemSlug: slug,
                            parentSlug: parentSlug,
                            parentType: learningItemType,
                            queryParams: { plannerId: course.id.toString() },
                          })}
                        >
                          {overview.progress == null ||
                          overview.progress.completedUserPlannerItems === 0
                            ? "Start Learning"
                            : "Continue Learning"}
                        </Button>
                      )}
                    />
                  ) : undefined
                }
              />
            )}

            {overview.dueAssignments > 0 && (
              <CheckRestrictions
                accessStatus={accessStatus}
                render={({ isRestricted }) => (
                  <NoticeCard
                    isRestricted={isRestricted}
                    note={`You have ${pluralize(
                      "assignment",
                      overview.dueAssignments
                    )} due`}
                    cta={{
                      label: `Finish ${pluralize(
                        "Skill Evidence (Assignment)",
                        overview.dueAssignments,
                        { skipCount: true }
                      )}`,
                      action: () =>
                        navigate(
                          `${routes.course.replace(":slug", slug)}/assignments`
                        ),
                    }}
                  />
                )}
              />
            )}

            {overview.dueAssessments > 0 && (
              <CheckRestrictions
                accessStatus={accessStatus}
                render={({ isRestricted }) => (
                  <NoticeCard
                    isRestricted={isRestricted}
                    note={`You have ${pluralize(
                      "assessment",
                      overview.dueAssessments
                    )} due`}
                    cta={{
                      label: `Finish ${pluralize(
                        "Assessment",
                        overview.dueAssessments,
                        { skipCount: true }
                      )}`,
                      action: () =>
                        navigate(
                          `${routes.course.replace(":slug", slug)}/assessments`
                        ),
                    }}
                  />
                )}
              />
            )}
          </>
        )}

        <NextUp learningItemType={course.type} learningItemId={course.id} />
      </div>

      {course && <Curriculum plannerId={course.id} />}
    </div>
  )
}

export default CourseOverview
