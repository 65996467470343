import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Certification } from "api/resources/profile/types"
import { format } from "date-fns"
import twitterVerifiedBadge from "features/Profile/assets/twitter-verified-badge.png"
import { Edit } from "iconoir-react"

import PreviewEvidenceList from "../EvidenceList"

const CertificationItem = ({
  certification,
  isPublic,
  onEdit,
}: {
  certification: Certification
  isPublic?: boolean
  onEdit?: () => void
}) => {
  return (
    <div className="group relative pb-2">
      {!isPublic && !certification.isVerified && onEdit && (
        <IconButton
          className="absolute right-0"
          color="black"
          onClick={() => onEdit()}
        >
          <Edit className="!size-3" />
        </IconButton>
      )}

      <div className="flex items-center gap-0.5">
        <Typography variant="strongSmallBody">{certification.name}</Typography>

        {certification.isVerified && (
          <img alt="✅" height="24px" src={twitterVerifiedBadge} width="24px" />
        )}
      </div>

      <Typography className="mb-0.25" color="onSurface.600" variant="smallBody">
        {certification.organisationName}
      </Typography>

      {certification.completionDate && (
        <Typography
          className="mb-0.5"
          color="onSurface.600"
          variant="smallBody"
        >
          {format(new Date(certification.completionDate), "MMMM yyyy")}
        </Typography>
      )}

      {certification.evidences && certification.evidences.length > 0 && (
        <PreviewEvidenceList data={certification.evidences} />
      )}
    </div>
  )
}

export default CertificationItem
