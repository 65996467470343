import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import api from "api"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"

import styles from "./CourseFeedbackCard.module.css"
import Step1 from "./Step1"
import Step2 from "./Step2"

const duration = 300

type Props = {
  itemId: number | string
  courseName: string
  onClose: (hasSubmitted: boolean) => void
}

const CourseFeedbackCard = ({ itemId, courseName, ...props }: Props) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [show, setShow] = useState(false)

  const [cardAnimation, setCardAnimation] = useState(false)
  const [step, setStep] = useState<"step1" | "step2" | null>("step1")

  const toggleCardAnimation = (enabled: boolean) => {
    setCardAnimation(enabled)
    setStep(null)
  }

  const onClose = (hasSubmitted: boolean) => {
    setShow(false)
    props.onClose(hasSubmitted)
  }

  useEffect(() => {
    const checkIfFeedbackAlreadyExists = async () => {
      try {
        const res = await api.feedback.retrieve({
          urlParams: {
            itemType: 1, // "course"
            itemId,
          },
        })

        if (!res) {
          setShow(true)
          return
        }

        // This ensures that if a feedback already exists in the backend, we clear our local queue.
        props.onClose(true)
      } catch (e) {
        setShow(true)
      }
    }

    checkIfFeedbackAlreadyExists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])

  if (!show) return null

  if (isXsDown) {
    return (
      <SlidingSheet
        className={clsx(styles.slidingSheetContainer, {
          [styles.step1]: step === "step1",
          [styles.step2]: step === "step2",
        })}
        open
        onClose={() => onClose(false)}
      >
        <>
          {step === "step1" && (
            <Step1
              onClickRateCourse={() => setStep("step2")}
              onClickNotNow={() => onClose(false)}
              courseName={courseName}
            />
          )}
          {step === "step2" && <Step2 onClose={onClose} itemId={itemId} />}
        </>
      </SlidingSheet>
    )
  }
  return (
    <div className="fixed bottom-8 right-1 z-10 md:bottom-2 md:right-2">
      <div className="relative">
        <CSSTransition
          in={cardAnimation}
          timeout={duration}
          classNames={{
            enterActive: styles.opacityEnter,
            enterDone: styles.opacityEnterActive,
            exitDone: styles.opacityExit,
            exitActive: styles.opacityExitActive,
          }}
          onEntered={() => setStep("step2")}
          onExited={() => setStep("step1")}
        >
          <div
            className={clsx("absolute bottom-0 right-0", styles.verticalCard, {
              [styles.horizontalCard]: cardAnimation,
            })}
          />
        </CSSTransition>

        {step === "step1" && (
          <Step1
            onClickRateCourse={() => toggleCardAnimation(true)}
            onClickNotNow={() => onClose(false)}
            courseName={courseName}
          />
        )}
        {step === "step2" && <Step2 onClose={onClose} itemId={itemId} />}
      </div>
    </div>
  )
}

export default CourseFeedbackCard
