import { Button, TextArea, TextField, Typography } from "@suraasa/placebo-ui"
import {
  ListPromptHistory,
  SubjectiveAssessmentFormType,
  ToolType,
} from "api/resources/aiTools/types"
import ErrorMessage from "components/ErrorMessage"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { Edit } from "iconoir-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"

import SelectLessonPlanSheet from "../../../helpers/SelectLessonPlanSheet"
import AssessmentStructure, {
  AssessmentStructureRow,
} from "../../AssessmentStructure"
import { SubjectiveAssessmentReturnType } from "../../useSubjectiveAssessment"

type Form = SubjectiveAssessmentFormType
type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

const toolType = ToolType.subjectiveAssessment

type Props = { openLessonPlanSelector?: boolean } & Pick<
  SubjectiveAssessmentReturnType,
  "generateTool" | "formData" | "preferredPromptData"
>

const BasicForm = ({
  openLessonPlanSelector,
  generateTool,
  formData,
  preferredPromptData,
}: Props) => {
  const [structure, setStructure] = useState<AssessmentStructureRow[] | null>(
    null
  )
  const [showErrors, setShowErrors] = useState<{
    selectedLessonPlan?: string
    structure?: string
  } | null>(null)

  const [searchParams] = useSearchParams()
  const mode = searchParams.get("mode") // "new" | "template"
  const isTemplateMode = mode === "template"

  const [openSidebar, setOpenSidebar] = useState(false)

  const [selectedLessonPlan, setSelectedLessonPlan] =
    useState<ListPromptHistory | null>(null)

  const [lessonPlanResponse, setLessonPlanResponse] = useState<number>(-1)

  const {
    register,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: formData?.basicForm || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
      country: preferredPromptData?.country || "",
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
      "country",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData?.basicForm || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
        country: preferredPromptData?.country || "",
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredPromptData])

  useEffect(() => {
    if (formData?.basicForm && formData.basicForm.questionDetails) {
      setStructure(formData.basicForm.questionDetails)
    }
  }, [formData?.basicForm])

  const onSubmit = handleSubmit(async formData => {
    if (isTemplateMode) {
      let hasError = false

      if (!selectedLessonPlan) {
        setShowErrors(prev => ({
          ...prev,
          selectedLessonPlan: "Lesson Plan is required",
        }))
        hasError = true
      }

      if (!structure || structure.length === 0) {
        setShowErrors(prev => ({
          ...prev,
          structure: "Assessment structure is required",
        }))
        hasError = true
      }

      if (hasError) return
      setShowErrors(null)

      await generateTool.mutateAsync({
        data: {
          // lessonPlanResponse:
          //   selectedLessonPlan &&
          //   selectedLessonPlan.responseIds &&
          //   selectedLessonPlan.responseIds.length > 1
          //     ? lessonPlanResponse
          //     : selectedLessonPlan?.promptDataId,
          lessonPlanResponse,
          question_details: structure,
        },
        setError,
      })
      return
    }
    let hasError = false
    if (!structure || structure.length === 0) {
      setShowErrors(prev => ({
        ...prev,
        structure: "Assessment structure is required",
      }))
      hasError = true
    }

    if (hasError) return
    setShowErrors(null)

    await generateTool.mutateAsync({
      data: {
        ...formData,
        question_details: structure,
      },
      setError,
    })
  })

  const [isEditing, setEditing] = useState(true)

  const subject = watch("subject")
  const country = watch("country")
  const grade = watch("grade")
  const curriculum = watch("curriculum")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="space-y-2">
        {!isEditing && !isTemplateMode && (
          <CreatingFor
            country={country}
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {!isTemplateMode ? (
          <div className="flex flex-col gap-2">
            {(!grade || !country || isEditing) && (
              <div className="flex flex-wrap gap-2 md:flex-nowrap">
                {(!grade || isEditing) && (
                  <TextField
                    required
                    label="Class/Grade"
                    placeholder="Ex: 6th Grade"
                    errors={errors.grade?.message}
                    {...register("grade", {
                      required: { value: true, message: "Required" },
                    })}
                  />
                )}
                {(!country || isEditing) && (
                  <TextField
                    {...register("country", {
                      required: { value: true, message: "Required" },
                    })}
                    required
                    label="Country"
                    placeholder="Ex: India"
                    errors={errors.country?.message}
                    helperText={
                      "We use this to tailor the lesson plan to your region"
                    }
                  />
                )}
              </div>
            )}
            {(!subject || !curriculum || isEditing) && (
              <div className="flex flex-wrap gap-2 md:flex-nowrap">
                {(!subject || isEditing) && (
                  <TextField
                    required
                    label="Subject"
                    placeholder="Ex: Social Science"
                    errors={errors.subject?.message}
                    {...register("subject", {
                      required: { value: true, message: "Required" },
                    })}
                  />
                )}
                {(!curriculum || isEditing) && (
                  <TextField
                    {...register("curriculum", {
                      required: { value: true, message: "Required" },
                    })}
                    required
                    label="Curriculum"
                    placeholder="Ex: CBSE"
                    errors={errors.curriculum?.message}
                  />
                )}
              </div>
            )}

            <TextField
              required
              label="topic"
              placeholder="Ex: Ashoka, the emperor who gave up war."
              errors={errors.topic?.message}
              {...register("topic", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextArea
              required
              rows={6}
              label="Objective"
              placeholder={`Ex: Summarize the rule of Ashoka, the renowned emperor of India.\nHighlight the significant contributions made by Ashoka during his reign.\nAnalyze the reasons behind Ashoka's transformation from a ruthless warrior to a pacifist ruler.`}
              errors={errors.assessmentObjective?.message}
              {...register("assessmentObjective", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <div>
                <Typography variant="strong">Lesson Plan</Typography>
                {selectedLessonPlan && (
                  <Typography variant="body">
                    {selectedLessonPlan.title}
                  </Typography>
                )}
              </div>
              <Button
                variant="text"
                onClick={() => {
                  setOpenSidebar(true)
                }}
                startAdornment={selectedLessonPlan ? <Edit /> : undefined}
              >
                {selectedLessonPlan ? "Edit" : "Select Lesson Plan"}
              </Button>
            </div>
            {showErrors?.selectedLessonPlan && (
              <ErrorMessage title={showErrors?.selectedLessonPlan} />
            )}
            <SelectLessonPlanSheet
              open={openSidebar}
              onOpenChange={() => setOpenSidebar(false)}
              onSelect={(subjectiveAssessment, lessonPlanResponseId) => {
                setSelectedLessonPlan(subjectiveAssessment)
                setLessonPlanResponse(lessonPlanResponseId)
                setOpenSidebar(false)
              }}
            />
          </div>
        )}

        <AssessmentStructure
          setStructure={setStructure}
          structure={structure}
          openLessonPlanSelector={openLessonPlanSelector}
        />
        {showErrors?.structure && (
          <ErrorMessage title={showErrors?.structure} />
        )}

        <Button
          variant="filled"
          type="submit"
          loading={isSubmitting}
          className="w-full"
        >
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default BasicForm
