import { Typography } from "@suraasa/placebo-ui"
import { Button, CircularProgress, Divider } from "@suraasa/placebo-ui-legacy"
import NotificationBell from "assets/Fallback/notification-bell.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import React from "react"
import routes from "routes"

import TabBar from "../../../components/TabBar"
import { useNotifications } from "../hooks/useNotifications"
import styles from "../Notifications.module.css"
import NotificationsList from "../NotificationsList"

const NotificationsPopup = () => {
  const {
    tabs,
    notifications,
    markAllAsRead,
    changeTab,
    activeTab,
    getDescription,
    markAllAsReadStatus,
    pageOverview,
  } = useNotifications({ useSearchParams: false, popup: true })
  const { isLoading: pageLoading, isError: pageError } = pageOverview
  const {
    data: notificationList,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
  } = notifications
  const { isLoading: isBtnLoading } = markAllAsReadStatus

  if (pageLoading || pageError) {
    return (
      <Fallback
        data={{
          image: NotificationBell,
          title: "No Notifications!",
        }}
        isLoading={pageLoading}
        hasError={pageError}
        className="my-6"
      />
    )
  }
  return (
    <div className="relative">
      <div className="px-2">
        <div className="my-2 flex items-end justify-between">
          <Typography variant="title3">Notifications</Typography>
          <Button
            variant="text"
            loading={isBtnLoading}
            color="black"
            onClick={markAllAsRead}
          >
            Mark all as read
          </Button>
        </div>
      </div>
      <div className="px-2">
        <TabBar
          className="pt-1"
          activeTab={activeTab}
          tabs={tabs}
          onChange={changeTab}
        />
      </div>

      <Divider className={styles.divider} />

      <div className={clsx(styles.popup)}>
        {(isError ||
          notificationList?.pages[0].data.notifications.length == 0) && (
          <Fallback
            data={{
              image: NotificationBell,
              title: "No Notifications!",
              description: getDescription(),
            }}
            className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 px-2 text-center sm:w-fit sm:px-0"
            hasError={isError}
          />
        )}
        {notificationList && (
          <NotificationsList
            data={notificationList}
            loading={isFetching}
            hasNextPage={hasNextPage}
            actionFunc={fetchNextPage}
            activeTabName={activeTab.name}
            mini
          />
        )}

        {isFetching && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
      </div>

      <div className="w-full border-t border-onSurface-300 bg-white py-1 text-center">
        <Button component="a" href={routes.notifications} variant="text">
          View All Notifications
        </Button>
      </div>
    </div>
  )
}

export default NotificationsPopup
