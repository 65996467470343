import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { ArrowSeparateVertical, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { BROWSER_STORAGE_KEYS, UpdateProfileEnum } from "utils/constants"
import { handleErrors } from "utils/helpers"

import RemoveDialog from "@/common/RemoveDialog"

import ProfileContext from "../../context"
import DraggableItem from "../ReOrderingFeatureOnboarding/DraggableItem"
import useDraggable from "../ReOrderingFeatureOnboarding/DraggableItem/useDraggable"
import Instructions from "../ReOrderingFeatureOnboarding/Instructions"
import AddDialog from "./AddDialog"

const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const SubjectsDialog = ({
  open,
  onRequestClose,
  customAction,
  source,
}: { onRequestClose: () => void } & Pick<DialogProps, "open"> & {
    customAction?: JSX.Element
    source?: string
  }) => {
  const [changePriority, setChangePriority] = useState(false)
  const {
    subjects: { data, refetch },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const subjectList = useDraggable({ data })

  const [openAddSubjectDialog, setOpenAddSubjectDialog] = useState(false)
  const [subjectToDelete, setSubjectToDelete] = useState<number | null>(null)
  const [removeDialog, setRemoveDialog] = useState(false)

  const { mutateAsync: handleRemove } = useMutation({
    mutationFn: () =>
      api.profile.subjects.delete({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { id: subjectToDelete! },
      }),
    onSuccess: () => {
      if (subjectToDelete) {
        refetch()
        setRemoveDialog(false)
        setSubjectToDelete(null)
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const { mutate: handleUpdateSequence, isLoading: loadingSequence } =
    useMutation({
      mutationFn: (data: { id: number; sequence: number }[]) =>
        api.profile.subjects.updateSequence({
          data: { subjectInterests: data },
        }),
      onSuccess: () => {
        // const newData = subjectList.processData(false)
        refetch()
      },
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
        }
      },
    })

  useEffect(() => {
    if (open) setOpenAddSubjectDialog(hasNoItems)

    if (!open) {
      setOpenAddSubjectDialog(false)
      setChangePriority(false)
      subjectList.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, hasNoItems])

  const changePriorityFnc = () => {
    setChangePriority(true)

    const updateStatus: string[] = JSON.parse(
      localStorage.getItem(updateProfileDialog) ?? "[]"
    )

    if (!updateStatus.includes(UpdateProfileEnum.SUBJECTS)) {
      updateStatus.push(UpdateProfileEnum.SUBJECTS)
    }

    localStorage.setItem(updateProfileDialog, JSON.stringify(updateStatus))
  }

  return (
    <Dialog open={open} onOpenChange={onRequestClose}>
      {openAddSubjectDialog || hasNoItems ? (
        <AddDialog
          source={source}
          open={openAddSubjectDialog}
          onAdd={() => refetch()}
          onBack={hasNoItems ? undefined : () => setOpenAddSubjectDialog(false)}
          onRequestClose={onRequestClose}
        />
      ) : (
        <DialogContent className="flex flex-col !p-0">
          <>
            <RemoveDialog
              title="Remove Subject"
              onRemove={async () => {
                await handleRemove()
              }}
              isOpen={removeDialog}
              setIsOpen={setRemoveDialog}
            >
              <Typography variant="smallBody">
                Are you sure you want to remove{" "}
                <b>
                  {data.find(item => item.id === subjectToDelete)?.subject.name}
                </b>{" "}
                subject from your profile?
              </Typography>
            </RemoveDialog>
            <DialogHeader>
              <DialogTitle className="text-strong">Subjects</DialogTitle>
            </DialogHeader>
            <DialogBody className="p-0">
              <div className="relative flex items-center justify-between border-b border-onSurface-200 px-1.5 py-2">
                <Button
                  startAdornment={<Plus className="!size-2.5" />}
                  variant="text"
                  onClick={() => setOpenAddSubjectDialog(true)}
                >
                  Add New Subject
                </Button>

                <IconButton
                  onClick={changePriorityFnc}
                  variant={changePriority ? "filled" : "plain"}
                  size="xs"
                  className="!grid sm:!hidden [&>svg]:size-2.5"
                >
                  <ArrowSeparateVertical />
                </IconButton>

                <Button
                  startAdornment={
                    <ArrowSeparateVertical className="!size-2.5" />
                  }
                  variant="text"
                  className="!hidden sm:!flex"
                  onClick={changePriorityFnc}
                >
                  Change Priority
                </Button>
              </div>
              {changePriority && (
                <Instructions
                  type="subjects"
                  title="Change Subject Priority"
                  description="Drag your main preferred subject to the top to make it your primary subject interest. Similarly, your second location will be marked as your secondary subject interest."
                  disclaimer="We match your profile with the right schools based on your primary and secondary subject interests."
                />
              )}
              <div>
                {subjectList.data.length > 0 &&
                  subjectList.data.map((item, index) => (
                    <DraggableItem
                      showTags
                      onDelete={() => {
                        setSubjectToDelete(item.id)
                        setRemoveDialog(true)
                      }}
                      className={clsx(
                        "[&>div]:mx-1.5 [&>div]:py-1.5 sm:[&>div]:mx-3",
                        {
                          "[&>div]:border-b [&>div]:border-onSurface-200":
                            subjectList.data.length - 1 !== index,
                        }
                      )}
                      isDraggable={changePriority}
                      key={item.id}
                      index={index}
                      onDragStart={subjectList.onDragStart}
                      onDrop={subjectList.onDrop}
                      onDragOver={subjectList.onDragOver}
                    >
                      <Typography variant="strong" className="mr-2">
                        {item.subject.name}
                      </Typography>
                    </DraggableItem>
                  ))}
              </div>
            </DialogBody>
            <DialogFooter>
              {changePriority && (
                <div className="flex justify-end">
                  <Button
                    variant="outlined"
                    color="critical"
                    size="sm"
                    onClick={() => {
                      subjectList.reset()
                      setChangePriority(false)
                    }}
                    className="mr-1"
                  >
                    Discard
                  </Button>
                  <Button
                    loading={loadingSequence}
                    onClick={() => {
                      const newData = subjectList.processData(true)
                      handleUpdateSequence(newData)
                      setChangePriority(false)
                    }}
                    size="sm"
                  >
                    Save
                  </Button>
                </div>
              )}
              {!changePriority && !customAction && (
                <Button
                  onClick={onRequestClose}
                  className="ml-auto !block border-onSurface-500 text-onSurface-500"
                  size="sm"
                  variant="outlined"
                >
                  Close
                </Button>
              )}
              {!changePriority && customAction}
            </DialogFooter>
          </>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default SubjectsDialog
