import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, Checkbox, Container } from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  ApplicationStatus,
  AttachmentType,
  Profiles,
} from "api/resources/applicationForm/types"
import { APIError } from "api/utils"
import { useConfirmationDialog } from "components/AsyncDialog/ConfirmDialog"
import FileInput from "components/FileInput"
import LoadingOverlay from "components/LoadingOverlay"
import { Xmark } from "iconoir-react"
import { useEffect, useRef, useState } from "react"
import routes from "routes"
import { applicationFormSlugs } from "utils/constants"
import useArray from "utils/hooks/useArray"

type Qualification = Profiles["qualifications"][number]
type Certification = Profiles["certifications"][number]

const RemoveFile = ({ name }: { name: string }) => (
  <Typography variant="body">
    Are you sure you want to remove <strong>{name}</strong>
  </Typography>
)

const QualificationRow = ({
  qualification,
  onUpdateTranscript,
  onUpdateDegree,
  onRemoveDegree,
  onRemoveTranscript,
  onDeleteQualificationEvidence,
}: {
  qualification: Qualification
  onUpdateTranscript: (id: number, item: any) => void
  onUpdateDegree: (id: number, item: any) => void
  onRemoveDegree: (id: number) => void
  onRemoveTranscript: (id: number) => void
  onDeleteQualificationEvidence: (id: number, evidence: number) => Promise<void>
}) => {
  const newDegree = useArray<File>([])

  const newTranscript = useArray<File>([])

  const getCertifications = (certificates: Qualification["certificates"]) => {
    if (certificates.length === 2) {
      return certificates
    }
    const newCertificates: Qualification["certificates"] = [...certificates]
    if (
      !certificates.some(c => c.attachmentType === AttachmentType.TRANSCRIPT)
    ) {
      newCertificates.push({
        attachment: null,
        attachmentName: null,
        attachmentType: AttachmentType.TRANSCRIPT,
        id: undefined,
        url: null,
      })
    }
    if (!certificates.some(c => c.attachmentType === AttachmentType.DEGREE)) {
      newCertificates.push({
        attachment: null,
        attachmentName: null,
        attachmentType: AttachmentType.DEGREE,
        id: undefined,
        url: null,
      })
    }
    return newCertificates
  }
  const transcriptFileInputRef = useRef<HTMLInputElement>(null)
  const degreeFileInputRef = useRef<HTMLInputElement>(null)
  const [errors, setErrors] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const { getConfirmation } = useConfirmationDialog()

  const getDeleteConfirmation = async (name: string) => {
    const confirmed = await getConfirmation(<RemoveFile name={name} />, {
      title: "Remove File",
      color: "critical",
      actionLabel: "Remove",
    })
    return confirmed
  }

  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-1 py-2">
      {loading && <LoadingOverlay />}
      <div className="flex flex-col">
        <Typography variant="strong" className="text-onSurface-800">
          {qualification.name}
        </Typography>
        <Typography variant="body" className="text-onSurface-500">
          {qualification.instituteName}
        </Typography>
      </div>
      <div className="overflow-hidden">
        {getCertifications(qualification.certificates)
          .sort(a => (a.attachmentType === AttachmentType.TRANSCRIPT ? -1 : 1))
          .map((a, i) => {
            const hasAttachment = a.attachmentName
            const isTranscript = a.attachmentType === AttachmentType.TRANSCRIPT
            const isDegree = a.attachmentType === AttachmentType.DEGREE

            const onClick = async () => {
              if (hasAttachment && a.id) {
                // delete uploaded file
                if (!(await getDeleteConfirmation(a.attachmentName || ""))) {
                  return
                }

                if (transcriptFileInputRef.current) {
                  transcriptFileInputRef.current.value = ""
                }
                if (degreeFileInputRef.current) {
                  degreeFileInputRef.current.value = ""
                }
                setLoading(true)
                if (isTranscript) {
                  onRemoveTranscript(qualification.id)
                } else {
                  onRemoveDegree(qualification.id)
                }

                onDeleteQualificationEvidence(qualification.id, a.id).finally(
                  () => {
                    setLoading(false)
                  }
                )
              } else {
                if (isTranscript) {
                  if (newTranscript.array.length !== 0) {
                    newTranscript.clear()
                    if (transcriptFileInputRef.current) {
                      transcriptFileInputRef.current.value = ""
                    }
                    onRemoveTranscript(qualification.id)

                    return
                  }
                  transcriptFileInputRef.current?.click()
                } else {
                  if (newDegree.array.length !== 0) {
                    newDegree.clear()
                    if (degreeFileInputRef.current) {
                      degreeFileInputRef.current.value = ""
                    }
                    onRemoveDegree(qualification.id)
                    return
                  }
                  degreeFileInputRef.current?.click()
                }
              }
            }
            const newTranscriptName =
              isTranscript && newTranscript.array[0]?.name

            const newDegreeName = isDegree && newDegree.array[0]?.name

            return (
              <>
                <Button
                  key={i}
                  endAdornment={
                    hasAttachment || newTranscriptName || newDegreeName ? (
                      <Xmark />
                    ) : null
                  }
                  variant="text"
                  onClick={onClick}
                  color={
                    hasAttachment || newTranscriptName || newDegreeName
                      ? "black"
                      : "primary"
                  }
                  className="-translate-x-1 sm:translate-x-0"
                >
                  {a.attachmentName}

                  {isTranscript && newTranscriptName}
                  {isDegree && newDegreeName}

                  {!hasAttachment &&
                    !newTranscriptName &&
                    isTranscript &&
                    "Upload Transcript"}

                  {!hasAttachment &&
                    !newDegreeName &&
                    isDegree &&
                    "Upload Degree"}
                </Button>
                {!hasAttachment && isTranscript && !newTranscriptName && (
                  <FileInput
                    allowedExtensions={[
                      ".jpg",
                      ".jpeg",
                      ".png",
                      ".pdf",
                      ".doc",
                      ".docx",
                    ]}
                    limit={1}
                    maxSize={5}
                    ref={transcriptFileInputRef}
                    onChange={files => {
                      files = files.slice(0, 1 - newTranscript.array.length)
                      files.forEach(file => newTranscript.push(file))
                      onUpdateTranscript(qualification.id, files[0])
                    }}
                    onError={setErrors}
                  />
                )}
                {!hasAttachment && isDegree && !newDegreeName && (
                  <FileInput
                    allowedExtensions={[
                      ".jpg",
                      ".jpeg",
                      ".png",
                      ".pdf",
                      ".doc",
                      ".docx",
                    ]}
                    limit={1}
                    maxSize={5}
                    ref={degreeFileInputRef}
                    onChange={files => {
                      files = files.slice(0, 1 - newDegree.array.length)
                      files.forEach(file => newDegree.push(file))
                      onUpdateDegree(qualification.id, files[0])
                    }}
                    onError={setErrors}
                  />
                )}
              </>
            )
          })}
        {errors.map((error, i) => (
          <div className="mb-2 mt-1.5 pl-2 text-critical-500" key={i}>
            <Typography variant="smallBody">{error}</Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

const CertificationRow = ({
  certification,
  onUpdateCertificate,
  onRemoveCertificate,
  onDeleteCertificate,
}: {
  certification: Certification
  onUpdateCertificate: (id: number, item: any) => void
  onRemoveCertificate: (id: number) => void
  onDeleteCertificate: (id: number, certificate: number) => Promise<void>
}) => {
  const newCertificate = useArray<File>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [errors, setErrors] = useState<string[]>([])

  const [loading, setLoading] = useState(false)
  const { getConfirmation } = useConfirmationDialog()

  const getDeleteConfirmation = async (name: string) => {
    const confirmed = await getConfirmation(<RemoveFile name={name} />, {
      color: "critical",
      actionLabel: "Remove",
      title: "Remove File",
    })
    return confirmed
  }

  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-1 py-2">
      <div className="flex flex-col">
        <Typography variant="strong" className="text-onSurface-800">
          {certification.name}
        </Typography>
        <Typography variant="body" className="text-onSurface-500">
          {certification.instituteName}
        </Typography>
      </div>
      <div className="overflow-hidden">
        {(() => {
          const hasAttachment = certification.certificates.length > 0
          const newCertificateName = newCertificate.array[0]?.name
          const certificateName = hasAttachment
            ? certification.certificates[0].attachmentName ?? ""
            : ""
          return (
            <>
              <Button
                endAdornment={
                  hasAttachment || newCertificateName ? <Xmark /> : null
                }
                className="-translate-x-1 sm:translate-x-0"
                variant="text"
                loading={loading}
                onClick={async () => {
                  if (hasAttachment && certification.certificates[0].id) {
                    // delete
                    if (!(await getDeleteConfirmation(certificateName))) {
                      return
                    }
                    setLoading(true)
                    onDeleteCertificate(
                      certification.id,
                      certification.certificates[0].id
                    ).finally(() => {
                      setLoading(false)
                    })
                  } else {
                    if (newCertificate.array.length !== 0) {
                      newCertificate.clear()
                      onRemoveCertificate(certification.id)
                      return
                    }
                    fileInputRef.current?.click()
                  }
                }}
                color={
                  hasAttachment || newCertificateName ? "black" : "primary"
                }
              >
                {hasAttachment && certificateName}
                {newCertificateName}
                {!hasAttachment && !newCertificateName && "Upload Certificate"}
              </Button>
              {!hasAttachment && !newCertificateName && (
                <FileInput
                  allowedExtensions={[
                    ".jpg",
                    ".jpeg",
                    ".png",
                    ".pdf",
                    ".doc",
                    ".docx",
                  ]}
                  limit={1}
                  maxSize={5}
                  ref={fileInputRef}
                  onChange={files => {
                    files = files.slice(0, 1 - newCertificate.array.length)
                    files.forEach(file => newCertificate.push(file))
                    onUpdateCertificate(certification.id, files[0])
                  }}
                  onError={setErrors}
                />
              )}

              {errors.map((error, i) => (
                <div className="mb-2 mt-1.5 pl-2 text-critical-500" key={i}>
                  <Typography variant="smallBody">{error}</Typography>
                </div>
              ))}
            </>
          )
        })()}
      </div>
    </div>
  )
}

const getHeaders = (productId: number, centreId: number) => ({
  "product-id": productId.toString(),
  "centre-id": centreId.toString(),
})

const UploadDocuments = () => {
  const [canUploadDocuments, setCanUploadDocuments] = useState(false)

  const [loading, setLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [profile, setProfile] = useState<Profiles | null>(null)

  const [productId, setProductId] = useState<number | null>(null)
  const [centreId, setCentreId] = useState<number | null>(null)
  const [isChecked, setIsChecked] = useState(false)

  const degreeToUpload = useArray<{ id: number; file: File }>([])
  const transcriptToUpload = useArray<{ id: number; file: File }>([])
  const certificateToUpload = useArray<{ id: number; file: File }>([])

  const onUpdateDegree = (id: number, file: File) => {
    degreeToUpload.removeByKey(id, "id")
    degreeToUpload.push({ id, file })
  }
  const onRemoveDegree = (id: number) => {
    degreeToUpload.removeByKey(id, "id")
  }

  const onUpdateTranscript = (id: number, file: File) => {
    transcriptToUpload.removeByKey(id, "id")
    transcriptToUpload.push({ id, file })
  }
  const onRemoveTranscript = (id: number) => {
    transcriptToUpload.removeByKey(id, "id")
  }
  const onUpdateCertificate = (id: number, file: File) => {
    certificateToUpload.removeByKey(id, "id")
    certificateToUpload.push({ id, file })
  }
  const onRemoveCertificate = (id: number) => {
    certificateToUpload.removeByKey(id, "id")
  }

  const refreshProfile = async () => {
    if (!productId || !centreId) {
      return
    }
    setProfile(null)
    return getProfile(getHeaders(productId, centreId))
  }

  const getProfile = async (headers: ReturnType<typeof getHeaders>) => {
    try {
      const res = await api.applicationForm.pgctl.getProfiles({
        headers,
        params: {
          fields: ["qualifications", "certifications"],
        },
      })

      setProfile(res)
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const getProductDetails = async (slug: string) => {
    try {
      const res = await api.applicationForm.questionnaireProduct.get({
        urlParams: {
          slug,
        },
      })

      const { centreId, productId } = res[0]
      setProductId(productId)
      setCentreId(centreId)

      getProfile(getHeaders(productId, centreId))
    } catch (error) {
      console.error(error)
      throw new Error("Product not found")
    }
  }

  const getOverview = async () => {
    try {
      const res = await api.applicationForm.overview()
      const pgctlOverview = res.find(
        o => o.productSlug === applicationFormSlugs.pgctl
      )

      if (
        pgctlOverview &&
        pgctlOverview.status !== ApplicationStatus.IN_PROGRESS &&
        pgctlOverview.status !== ApplicationStatus.NOT_STARTED &&
        pgctlOverview.evidenceUploaded === false
      ) {
        setCanUploadDocuments(true)
      } else {
        toast.success("You have already uploaded the documents")
      }
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    setLoading(true)
    getOverview().finally(() => {
      setLoading(false)
      setCanUploadDocuments(true)
    })
  }, [])

  useEffect(() => {
    if (canUploadDocuments) {
      getProductDetails(applicationFormSlugs.pgctl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUploadDocuments])

  if (!canUploadDocuments) {
    return <LoadingOverlay />
  }
  const onDeleteCertificate = async (id: number, certificate: number) => {
    if (!productId || !centreId) {
      return
    }
    try {
      await api.applicationForm.profile.deleteEvidence({
        data: {
          itemId: id,
          certificates: [certificate],
          itemType: "academiccertification",
        },
        headers: getHeaders(productId, centreId),
      })
      await refreshProfile()
    } catch (error) {
      console.error("Error removing certificate")
    }
  }
  const onDeleteQualificationEvidence = async (
    id: number,
    evidence: number
  ) => {
    if (!productId || !centreId) {
      return
    }
    try {
      await api.applicationForm.profile.deleteEvidence({
        data: {
          itemId: id,
          certificates: [evidence],
          itemType: "academicqualification",
        },
        headers: getHeaders(productId, centreId),
      })
      await refreshProfile()
    } catch (error) {
      if (error instanceof APIError) {
        if (error.errors.message) {
          toast.error(error.errors.message)
        }
      }
    }
  }

  const uploadQualification = async (
    id: number | string,
    degree: any,
    transcript: any
  ) => {
    if (!productId || !centreId) {
      return
    }
    const formData = new FormData()
    formData.append("item_type", "academicqualification")
    formData.append("item_id", id.toString())
    let count = 0
    if (degree) {
      formData.append(`evidences[${count}]attachment`, degree)
      formData.append(
        `evidences[${count}]attachment_type`,
        AttachmentType.DEGREE.toString()
      )
      count += 1
    }
    if (transcript) {
      formData.append(`evidences[${count}]attachment`, transcript)
      formData.append(
        `evidences[${count}]attachment_type`,
        AttachmentType.TRANSCRIPT.toString()
      )
      count += 1
    }
    try {
      await api.applicationForm.profile.createQualificationEvidence({
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...getHeaders(productId, centreId),
        },
      })
    } catch (error) {
      if (error instanceof APIError) {
        if (error.errors.fieldErrors?.evidences) {
          // @ts-expect-error tscript issue in APIError
          if (error.errors.fieldErrors?.evidences[1]?.attachment) {
            toast.error(
              // @ts-expect-error tscript issue in APIError
              `Qualification: ${error.errors.fieldErrors?.evidences[1]?.attachment[0]} (${transcript.name})`
            )
          }
          // @ts-expect-error tscript issue in APIError
          if (error.errors.fieldErrors?.evidences[0]?.attachment) {
            toast.error(
              // @ts-expect-error tscript issue in APIError
              `Qualification: ${error.errors.fieldErrors?.evidences[0]?.attachment[0]} (${degree.name})`
            )
          }
        } else {
          toast.error("Failed to upload qualification documents")
        }
      }
    }
  }
  const uploadCertifications = async (id: number | string, file: any) => {
    if (!productId || !centreId) {
      return
    }
    const formData = new FormData()
    formData.append("item_id", id.toString())
    formData.append("item_type", "academiccertification")

    formData.append(`evidences[0]attachment`, file)

    try {
      await api.applicationForm.profile.createCertificationEvidence({
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          ...getHeaders(productId, centreId),
        },
      })
    } catch (error) {
      if (error instanceof APIError) {
        if (error.errors.fieldErrors?.evidences) {
          // @ts-expect-error tscript issue in APIError
          if (error.errors.fieldErrors?.evidences[0]?.attachment) {
            toast.error(
              // @ts-expect-error tscript issue in APIError
              `Certification: ${error.errors.fieldErrors?.evidences[0]?.attachment[0]} (${file.name})`
            )
          }
        } else {
          toast.error("Failed to upload qualification documents")
        }
      }
    }
  }

  const uploadFiles = async () => {
    if (!productId || !centreId) {
      return
    }
    setUploadLoading(true)
    const qualificationData: {
      [id: number]: {
        degree?: any
        transcript?: any
      }
    } = {}

    degreeToUpload.array.forEach(item => {
      if (!qualificationData[item.id]) {
        qualificationData[item.id] = {
          degree: item.file,
        }
      } else {
        qualificationData[item.id].degree = item.file
      }
    })

    transcriptToUpload.array.forEach(item => {
      if (!qualificationData[item.id]) {
        qualificationData[item.id] = {
          transcript: item.file,
        }
      } else {
        qualificationData[item.id].transcript = item.file
      }
    })

    const qualificationsPromise: Promise<void>[] = []
    Object.entries(qualificationData).forEach(([id, object]) => {
      qualificationsPromise.push(
        uploadQualification(id, object.degree, object.transcript)
      )
    })
    const certificateData: {
      [id: number]: {
        file?: any
      }
    } = {}
    certificateToUpload.array.forEach(item => {
      if (!certificateData[item.id]) {
        certificateData[item.id] = {
          file: item.file,
        }
      } else {
        certificateData[item.id].file = item.file
      }
    })
    const certificationPromise: Promise<void>[] = []
    Object.entries(certificateData).forEach(([id, object]) => {
      certificationPromise.push(uploadCertifications(id, object.file))
    })

    await Promise.all([...qualificationsPromise, ...certificationPromise])
    const updatedProfile = await refreshProfile()
    if (updatedProfile) {
      const { certifications, qualifications } = updatedProfile
      const allCertificationUploaded =
        certifications.length > 0
          ? certifications.every(c => c.certificates.length === 1)
          : true

      const allQualificationsUploaded =
        qualifications.length > 0
          ? qualifications.every(c => c.certificates.length === 2)
          : true

      if (allCertificationUploaded && allQualificationsUploaded) {
        window.location.href = routes.home
        return
      }
    }
    window.location.reload()

    // const updatedProfile = await refreshProfile()
    // if (updatedProfile) {
    //   toast.success("Files uploaded successfully.")
    // }
    // degreeToUpload.clear()
    // transcriptToUpload.clear()
    // certificateToUpload.clear()
    // setIsChecked(false)
    // await getOverview()
    // setUploadLoading(false)
  }

  const hasFilesToUpload =
    degreeToUpload.array.length > 0 ||
    transcriptToUpload.array.length > 0 ||
    certificateToUpload.array.length > 0

  return (
    <div className="relative h-full min-h-screen">
      {uploadLoading && <LoadingOverlay />}
      {loading && <LoadingOverlay />}

      {!loading && profile && (
        <div className="border-b border-[#e2e8f0] bg-surface-500 shadow-[0px_6px_15px_rgba(0,0,0,0.05)]">
          <Container>
            <div className="flex flex-wrap items-center justify-between gap-1 py-2">
              <div>
                <Typography variant="preTitle" className="text-onSurface-600">
                  PgCTL Application Form
                </Typography>
                <Typography variant="title2" className="text-onSurface-900">
                  Upload Degrees and Transcripts
                </Typography>
                <Typography variant="smallBody" className="text-onSurface-500">
                  <ul className="list-inside list-disc">
                    <li>File size should not exceed 5MB</li>
                    <li>Accepted Formats - PDF, DOC, DOCX, JPEG, PNG</li>
                  </ul>
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      )}
      <Container>
        {profile && (
          <div className="py-4">
            <div className="flex flex-col gap-3">
              {profile.qualifications.length > 0 && (
                <div>
                  <Typography variant="title3">Qualifications</Typography>
                  <div className="divide-y">
                    {profile.qualifications.map(v => (
                      <QualificationRow
                        qualification={v}
                        key={v.id}
                        onUpdateTranscript={onUpdateTranscript}
                        onRemoveTranscript={onRemoveTranscript}
                        onUpdateDegree={onUpdateDegree}
                        onRemoveDegree={onRemoveDegree}
                        onDeleteQualificationEvidence={
                          onDeleteQualificationEvidence
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              {profile.certifications.length > 0 && (
                <div className="mt-2">
                  <Typography variant="title3">Certifications</Typography>
                  <div className="divide-y">
                    {profile.certifications.map(v => (
                      <CertificationRow
                        certification={v}
                        key={v.id}
                        onUpdateCertificate={onUpdateCertificate}
                        onRemoveCertificate={onRemoveCertificate}
                        onDeleteCertificate={onDeleteCertificate}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-10 [&>button]:h-auto">
              <Checkbox
                disabled={!hasFilesToUpload}
                checked={isChecked}
                onChange={e => setIsChecked(e.target.checked)}
                label="I hereby declare that the submitted documents are true copies of original documents. I give permission to Suraasa to share information or request information on my behalf for purposes of assessment, review, and verification, and contact my references for verification or background check. I understand that if any certificates, qualifications or documents submitted by me are found fraudulent during the admission process or after enrollment, Suraasa shall have the right to terminate my candidature and all fees paid shall be non-refundable."
              />
              <Button
                className="mt-2"
                fullWidth
                disabled={!isChecked || loading || !hasFilesToUpload}
                onClick={uploadFiles}
              >
                Upload Files
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default UploadDocuments
