import { Typography } from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import { Edit } from "iconoir-react"

import { TitleAndSub } from "../Generator/helpers/InputDisplay"

const CreatingFor = ({
  grade,
  subject,
  curriculum,
  country,
  onEditClick,
}: {
  grade?: string | null
  subject?: string | null
  curriculum?: string | null
  country?: string | null
  onEditClick: () => void
}) => {
  if (!grade && !subject && !curriculum && !country) return null

  return (
    <div className="my-0 rounded-lg bg-surface-100 p-2">
      <div className="transition-all duration-500">
        <div className="flex items-center justify-between gap-2 overflow-hidden">
          <div>
            <Typography
              variant="preTitle"
              className="mb-0.5 text-onSurface-500"
            >
              Creating for
            </Typography>
            <div className="flex max-w-[500px] flex-wrap items-center gap-4 xl:justify-start">
              {[
                {
                  name: "Country",
                  value: country,
                },
                {
                  name: "Class/Grade",
                  value: grade,
                },
                {
                  name: "Curriculum",
                  value: curriculum,
                },
                {
                  name: "Subject",
                  value: subject,
                },
              ].map((item, index) =>
                item.value ? (
                  <TitleAndSub
                    key={index}
                    title={item.name}
                    subTitle={item.value}
                  />
                ) : null
              )}
            </div>
          </div>
          <IconButton onClick={() => onEditClick()}>
            <Edit />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default CreatingFor

/*
<div
        className={clsx("grid grid-rows-[0fr] transition-all", {
          "grid-rows-[1fr]": editFields,
        })}
      >
        <div className="grid grid-cols-1 gap-2 overflow-hidden md:grid-cols-2">
          <TextField
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.grade)}
            label="Grade"
            fullWidth
            placeholder="Ex: UKG"
            helperText={
              errors.grade && typeof errors.grade?.message === "string"
                ? errors.grade?.message
                : undefined
            }
          />
          <TextField
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.subject)}
            label="Subject"
            fullWidth
            placeholder="Ex: Maths"
            helperText={
              errors.subject && typeof errors.subject?.message === "string"
                ? errors.subject?.message
                : undefined
            }
          />
          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.curriculum)}
            label="Curriculum"
            fullWidth
            placeholder="Ex: CBSE"
            helperText={
              errors.curriculum &&
              typeof errors.curriculum?.message === "string"
                ? errors.curriculum?.message
                : undefined
            }
          />
          <TextField
            {...register("country", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.country)}
            label="Country"
            fullWidth
            placeholder="Ex: India"
            helperText={
              errors.country && typeof errors.country?.message === "string"
                ? errors.country?.message
                : undefined
            }
          />
        </div>
      </div>

      */
