import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Award } from "api/resources/profile/types"
import { format } from "date-fns"
import { Edit } from "iconoir-react"

const AwardsItem = ({
  onEdit,
  data,
  isPublic,
}: {
  onEdit?: () => void
  data: Award
  isPublic?: boolean
}) => {
  return (
    <div className="group relative ml-2 pr-3">
      {!isPublic && onEdit && (
        <IconButton
          className="absolute right-0"
          color="black"
          onClick={() => onEdit()}
        >
          <Edit className="!size-3" />
        </IconButton>
      )}

      <Typography variant="strongSmallBody">{data.title}</Typography>

      <Typography className="mb-0.25 text-onSurface-600" variant="smallBody">
        {data.issuer}
      </Typography>

      {data.issuedOn && (
        <Typography className="mb-1 text-onSurface-600" variant="smallBody">
          {format(new Date(data.issuedOn), "MMMM yyyy")}
        </Typography>
      )}

      <Typography
        className="mb-1"
        style={{ whiteSpace: "pre-wrap" }}
        variant="smallBody"
      >
        {data.description}
      </Typography>

      {data.certificate && (
        <a
          href={data.certificate}
          target="_blank"
          rel="noreferrer"
          className="text-interactive-500 hover:underline"
        >
          View Certificate
        </a>
      )}
    </div>
  )
}

export default AwardsItem
