import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Publication } from "api/resources/profile/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

import RemoveDialog from "@/common/RemoveDialog"

import ProfileContext from "../../context"

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id?: string
  open: boolean
}

const PublicationDialog = ({ open, id, setOpen }: Props) => {
  const [data, setData] = useState<Publication>()

  const {
    achievements: { publications },
  } = useContext(ProfileContext)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Publication>()

  const isEditing = Boolean(id)

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.publications.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        reset(res.data)
      }
    }
    if (id) fetchData()
  }, [id, reset])

  const onSubmit = handleSubmit(
    async ({ title, publishedOn, publisher, description, url }) => {
      const apiData = {
        title,
        publisher,
        publishedOn: publishedOn || null,
        description: description || null,
        url: url || null,
      }

      if (id) {
        const res = await api.profile.publications.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          publications.refetch()
          setOpen(false)
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.publications.create({ data: apiData })
        if (res.isSuccessful) {
          publications.refetch()
          setOpen(false)
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    if (id) {
      const res = await api.profile.publications.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        publications.refetch()
        setOpen(false)
      }
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="strong">
                {isEditing ? "Edit" : "Add New"} Publication
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            {id && !data && <LoadingOverlay />}
            <form onSubmit={onSubmit}>
              <TextField
                autoFocus
                className="mb-3"
                errors={errors.title?.message}
                required
                label="Title"
                placeholder="Ex: Light detection nears its quantum limit"
                {...register("title", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                className="mb-3"
                errors={errors.publisher?.message}
                required
                label="Publisher"
                placeholder="Ex: Scientific Reports"
                {...register("publisher", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                className="mb-3"
                errors={errors.publishedOn?.message}
                label="Publication Date"
                placeholder="Ex: 21 Jan 2021"
                type="date"
                {...register("publishedOn")}
              />

              <TextField
                className="mb-3"
                errors={errors.url?.message}
                label="Publication URL"
                placeholder="Ex: Harvard University"
                {...register("url")}
              />
              <TextArea
                errors={errors.description?.message}
                label="Description"
                placeholder="Talk about your role and experience etc..."
                rows={5}
                {...register("description", {
                  maxLength: {
                    value: 300,
                    message: "Try to keep it short",
                  },
                })}
              />
            </form>
          </DialogBody>

          <DialogFooter
            className={clsx("flex ", {
              "!justify-between": id,
            })}
          >
            {id && (
              <RemoveDialog
                trigger={
                  <Button
                    variant="text"
                    color="critical"
                    loading={isSubmitting}
                  >
                    Remove
                  </Button>
                }
                title="Remove Publication?"
                onRemove={handleRemove}
              >
                <Typography variant="smallBody">
                  Are you sure you want to remove&nbsp;
                  <Typography className="inline" variant="strongSmallBody">
                    <strong>{data?.title}</strong>
                  </Typography>
                  &nbsp;from your profile?
                </Typography>
              </RemoveDialog>
            )}

            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpen(false)
                }}
                className="mr-1.25 border-muted text-muted"
                size="sm"
                color="black"
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="sm"
                loading={isSubmitting}
                className="py-1.25"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PublicationDialog
