import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  Button,
  Container,
  Divider,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import React, { useCallback, useEffect, useRef, useState } from "react"

import CashPrize from "./CashPrize"
import ContestVideoUpload from "./ContestVideoUpload"
import GuidanceResources from "./GuidanceResources"
import Header from "./Header"
import HowToParticipate from "./HowToParticipate"
import Introduction from "./Introduction"
// import PreviousEditionEvent from "./PreviousEditionEvent"
import StickySubmittedBar from "./StickySubmittedBar"
import StickyTIABar from "./StickyTIABar"
// import WinnerTestimonial from "./WinnerTestimonial"

// const Footer = () => (
//   <div className="flex justify-center bg-primary-100 py-1.5">
//     <Typography variant="smallBody" className="text-primary-900">
//       Keep checking this page for latest updates.
//     </Typography>
//   </div>
// )

const TIA = () => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const uploadVideoRef = useRef<null | HTMLDivElement>(null)

  const [isVideoSubmitted, setIsVideoSubmitted] = useState(false)
  const [hasVisited, setHasVisited] = useState<boolean>(false)
  const divRef = useRef<HTMLDivElement | null>(null)

  const { data: canAccessTIA, isLoading: isLoadingAccess } = useQuery({
    queryKey: queries.tia.eligibility().queryKey,
    queryFn: () => api.tia.checkEligibility(),
  })
  const { data: submission, isLoading: isLoadingSubmission } = useQuery({
    queryKey: queries.tia.submissionDetails().queryKey,
    queryFn: () => api.tia.getSubmission(),
  })
  const onSuccessfulVideoUpload = () => {
    setIsVideoSubmitted(true)
  }

  const handleUploadClick = () => {
    document.getElementById("file-input")?.click()
    setTimeout(() => {
      if (uploadVideoRef && uploadVideoRef.current)
        uploadVideoRef.current.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }

  const checkDivVisiting = useCallback(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect()
      if (!submission && !isVideoSubmitted) {
        if (!hasVisited && rect.top <= 0) {
          setHasVisited(true)
        }

        if (hasVisited && rect.top > 0) {
          setHasVisited(false)
        }
      }
    }
  }, [hasVisited, submission, isVideoSubmitted])

  useEffect(() => {
    window.addEventListener("scroll", checkDivVisiting)
    return () => {
      window.removeEventListener("scroll", checkDivVisiting)
    }
  }, [checkDivVisiting])

  if (!canAccessTIA) {
    return (
      <Fallback
        data={{
          image: Monitor,
          title: "Something went wrong.",
          description: "Please contact care@suraasa.com",
        }}
        isLoading={isLoadingAccess || isLoadingSubmission}
        className="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 px-2 text-center sm:w-fit sm:px-0"
      />
    )
  }

  return (
    <>
      <div className="relative">
        {(submission || isVideoSubmitted) && (
          <>
            <StickySubmittedBar color={theme.colors.success[500]} />
          </>
        )}
        <StickyTIABar
          show={hasVisited && !isVideoSubmitted}
          slotEnd={
            submission ? (
              <Typography className="w-full text-center text-surface-500 sm:w-2/5">
                Your video has been uploaded. We’ll let you know when the
                results are out. Stay tuned!
              </Typography>
            ) : (
              <Button
                size="sm"
                onClick={handleUploadClick}
                fullWidth={isXsDown}
                color="white"
                className="mt-0.5 !text-primary-500 sm:mt-0"
              >
                Upload My Submission
              </Button>
            )
          }
        />

        <Container>
          <Header
            showButton={!submission && !isVideoSubmitted}
            onClick={handleUploadClick}
          />
          <Divider color="onSurface.200" weight="medium" />
        </Container>
        <div ref={divRef}>
          {!submission && !isVideoSubmitted ? (
            <>
              <Container>
                <Introduction />
                <CashPrize />
                <Divider color="onSurface.200" className="mt-4" />
                <HowToParticipate />
              </Container>

              <Container fullWidth={isXsDown}>
                <GuidanceResources />
              </Container>

              {/* <Container>
                <WinnerTestimonial />
              </Container> */}
            </>
          ) : (
            <>
              <Container>
                <Introduction />
              </Container>

              <Container fullWidth={isSmDown}>
                <div>
                  <Accordion className="mt-2 p-0">
                    <AccordionHeader className="mt-2">
                      <Typography
                        className="!text-xl !leading-[25px]"
                        style={{ fontFamily: "Space Grotesk Bold" }}
                      >
                        How to Participate? (Guidelines)
                      </Typography>
                    </AccordionHeader>
                    <AccordionDetails className="!p-0">
                      <div className="px-2 sm:px-5 md:px-0">
                        <HowToParticipate showTitle={false} />
                      </div>
                      <GuidanceResources />
                    </AccordionDetails>
                  </Accordion>

                  <Divider
                    color="onSurface.200"
                    className="my-2"
                    weight="medium"
                  />
                </div>
              </Container>

              {/* <Container>
                <WinnerTestimonial />
              </Container> */}
            </>
          )}

          <div ref={uploadVideoRef} id="upload-video">
            <ContestVideoUpload
              videoUploaded={() => onSuccessfulVideoUpload()}
              submissionVideo={submission || null}
            />
          </div>
          {/* <PreviousEditionEvent /> */}
          {/* <Footer /> */}
        </div>
      </div>
    </>
  )
}

export default TIA
