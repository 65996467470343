import { Typography } from "@suraasa/placebo-ui"
import { Button, Divider } from "@suraasa/placebo-ui-legacy"
import { Assignment } from "api/resources/assignments/types"
import Attachment from "features/Assignments/Attachment"
import { useState } from "react"

import { buildAssignmentTitle } from "../utils"
import styles from "./Instructions.module.css"
import LearningOutcomesSheet from "./LearningOutcomesSheet"
import MarkingCriteriaSheet from "./MarkingCriteriaSheet"

const Instructions = ({
  attachments,
  instructions,
  assignmentAssessmentCriteria,
  ...assignment
}: Assignment) => {
  const [viewLearningOutcomes, setViewLearningOutcomes] = useState(false)
  const [viewMarkingCriteria, setViewMarkingCriteria] = useState(false)

  return (
    <div>
      <div className="flex items-center justify-between">
        <Typography variant="title3">Instructions</Typography>
        <Button variant="text" onClick={() => setViewLearningOutcomes(true)}>
          Learning Outcomes
        </Button>
      </div>

      <div className="mb-3 mt-2 rounded-xl border border-surface-200 bg-common-white-500 p-2">
        <Typography variant="subtitle2" className="mb-0.5">
          {buildAssignmentTitle(assignment)}
        </Typography>
        <Typography>
          <span
            className={styles.instructions}
            dangerouslySetInnerHTML={{ __html: instructions }}
          />
        </Typography>
        <Divider weight="light" color="onSurface.200" className="my-2" />

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {attachments.map((attachment, index) => (
              <Attachment
                key={index}
                file={{ ...attachment, name: `Attachment ${index + 1}` }}
              />
            ))}
          </div>
          <Button variant="text" onClick={() => setViewMarkingCriteria(true)}>
            Marking Criteria
          </Button>
        </div>
      </div>

      <LearningOutcomesSheet
        open={viewLearningOutcomes}
        onClose={() => setViewLearningOutcomes(false)}
        learningOutcomes={assignmentAssessmentCriteria}
      />
      <MarkingCriteriaSheet
        assignmentId={assignment.uuid}
        open={viewMarkingCriteria}
        onClose={() => setViewMarkingCriteria(false)}
      />
    </div>
  )
}

export default Instructions
