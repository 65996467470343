import { Typography } from "@suraasa/placebo-ui"
import Arrow from "assets/tia/GuidanceResources/arrow.svg"
import CreateVideo from "assets/tia/GuidanceResources/create-video.webp"
import Guidelines from "assets/tia/GuidanceResources/participation-guidelines.webp"
import WhyParticipate from "assets/tia/GuidanceResources/why-participate.webp"
import clsx from "clsx"
import React from "react"

import styles from "./GuidanceResources.module.css"

const GuidanceResources = () => {
  return (
    <div className="my-4 rounded-lg bg-onSurface-800 px-2 py-4 md:px-4">
      <Typography
        style={{
          fontFamily: "Space Grotesk Bold",
        }}
        className="!text-2xl !font-bold text-white"
      >
        Guidance Resources
      </Typography>
      <Typography variant="subtitle2" className="mt-1 text-surface-500">
        Follow these curated guidance resources to showcase your impact
        effectively and learn more about the entire process!
      </Typography>

      <div className="mb-2 mt-3 flex flex-wrap items-center justify-center gap-3">
        {[
          {
            title: "Participation Guidelines",
            bg: Guidelines,
            link: "https://suraasa.co/tia-2024-guidelines",
          },
          {
            title: "Steps to Create your Video",
            bg: CreateVideo,
            link: "https://suraasa.co/tia-2024-video-guide",
          },
          {
            title: "Previous Edition's Winner Entries",
            bg: WhyParticipate,
            link: "https://suraasa.co/KSc1",
          },
        ].map((data, i) => (
          <a key={i} href={data.link} target="_blank" rel="noreferrer">
            <div
              className={clsx(
                styles.cardContainer,
                "relative h-[192px] w-[286px] rounded-lg bg-cover sm:w-[350px]"
              )}
              style={{ backgroundImage: `url(${data.bg})` }}
            >
              <div
                className={clsx(
                  "absolute right-2 top-2 grid size-[26.67px] cursor-pointer items-center justify-center rounded-[50%] border-[3px] border-solid border-[black] text-[black] transition-[background-color] duration-500 ease-out sm:right-[19px] sm:top-2.25",
                  styles.circleArrow
                )}
              >
                <img src={Arrow} alt="arrow" className={styles.arrow} />
              </div>
              <div
                className={clsx(
                  styles.viewButton,
                  "flex-col justify-between p-1 md:flex-row md:p-2"
                )}
              >
                <div className="text-[13px] sm:text-[auto]">{data.title}</div>
                <Typography variant="strongSmallBody">View</Typography>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default GuidanceResources
